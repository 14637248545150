import { Fragment } from 'react';
import { useGetSignRequestAttachments } from 'app/domains/esigning/hooks/useGetSignRequestAttachments';
import { ESignActivityTab } from '../ESignActivityTab';
import { PendingParties } from '../ESignPendingState/PendingParties';
import { ESignTabs } from '../ESignTabs';
import { SectionContent } from '../components';
import { OverlayLoader } from '../components/OverlayLoader';
export function ESignSignedState() {
    const { loading: loadingAttachments } = useGetSignRequestAttachments();
    return (<Fragment>
      {loadingAttachments && <OverlayLoader overlay/>}
      <SectionContent>
        <ESignTabs parties={<PendingParties />} activity={<ESignActivityTab />}/>
      </SectionContent>
    </Fragment>);
}
