import { Children, useState } from 'react';
import { t } from '@lingui/macro';
import { Box, Chip, Tooltip, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Container, ExpandContainer, IconContainer, MessageContainer } from './styled';
export function Party(props) {
    const { name, email, message, children, endElement, order } = props;
    const [expanded, setExpanded] = useState(false);
    const isExpandable = Boolean(message) || Children.count(children) > 0;
    const signingOrder = order !== undefined && order > -1 ? order + 1 : undefined;
    const showSigningOrder = signingOrder !== undefined;
    const handleToggleExpanded = () => setExpanded(!expanded);
    return (<Container disabled={!isExpandable} onClick={handleToggleExpanded}>
      <Box alignItems="center" justifyContent="space-between" px="lg" py="md" width="100%">
        <Box alignItems="center" gap="lg">
          {showSigningOrder && (<Tooltip title={t({ comment: 'Tooltip for signing order', message: 'Signing order' })}>
              <Chip appearance="secondary" label={signingOrder}/>
            </Tooltip>)}
          <Box flexDirection="column" alignItems="flex-start">
            <Typography $fontSize="small" $appearance="300" $fontWeight="semibold">
              {name}
            </Typography>
            <Typography $fontSize="tiny" $appearance="100">
              {email}
            </Typography>
          </Box>
        </Box>
        <Box alignItems="center" gap="sm">
          {endElement}
          {isExpandable && (<IconContainer>
              {expanded ? <Falcon icon="chevron-up"/> : <Falcon icon="chevron-down"/>}
            </IconContainer>)}
        </Box>
      </Box>
      {expanded && (<ExpandContainer>
          <Box flexDirection="column" gap="md" px="lg" py="md">
            {children}
            {message && (<MessageContainer>
                <Typography $fontSize="small" $appearance="300">
                  {message}
                </Typography>
              </MessageContainer>)}
          </Box>
        </ExpandContainer>)}
    </Container>);
}
