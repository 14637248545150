var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useDraggable, useDroppable, TableRow } from '@pocketlaw/tetris';
import { useACLCan } from 'app/domains/filesystem';
import { ContextMenuCell, DateCell, NameCell } from './TableCells';
function FolderTableRow(props) {
    const { id, type, name, date, acl, teamAcls, onRename, onDelete, onEditAccess, onQuickMove, onMove } = props, restProps = __rest(props, ["id", "type", "name", "date", "acl", "teamAcls", "onRename", "onDelete", "onEditAccess", "onQuickMove", "onMove"]);
    const { canWrite, canDelete } = useACLCan({ userAcls: acl, teamAcls });
    const openUrl = `/files/${id}`;
    const { droppableProps, isDraggingOver } = useDroppable(onQuickMove);
    const { draggableProps, isDragging } = useDraggable(`${type}:${id}`);
    const tableRowProps = Object.assign(Object.assign(Object.assign({ $isDraggable: canWrite(), $isDragging: isDragging, $isDraggingOver: !isDragging && isDraggingOver }, (canWrite() ? draggableProps : {})), (canWrite() ? droppableProps : {})), restProps);
    const folderType = canWrite() ? 'folder' : 'folder:locked';
    return (<TableRow {...tableRowProps}>
      <NameCell headers="filesystemtable-name" to={openUrl} title={name} type={folderType}/>
      <DateCell headers="filesystemtable-updatedAt" date={date}/>
      <ContextMenuCell download={false} openUrl={openUrl} onRename={canWrite() ? onRename : undefined} onDelete={canDelete() ? onDelete : undefined} onEditAccess={canWrite() ? onEditAccess : undefined} onMove={canWrite() ? onMove : undefined}/>
    </TableRow>);
}
FolderTableRow.displayName = 'FolderTableRow';
FolderTableRow.defaultProps = {
    acl: [],
    user: undefined,
};
export default FolderTableRow;
