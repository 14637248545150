import { useFileBrowserContext } from 'app/domains/filesystem';
import { SelectedEntries } from './SelectedEntries';
import { PickerBreadcrumbs } from '../PickerBreadcrumbs';
import { PickerDialog } from '../PickerDialog';
import { PickerFooter } from '../PickerFooter';
import { PickerHeader } from '../PickerHeader';
import { PickerTableFilesystem } from '../PickerTableFilesystem';
export function FileDialog(props) {
    const { dialogTitle, dialogSubtitle, submitLabel, selectedEntryAlert, multiselect = false, onClose, onSubmit, } = props;
    const { fileBrowserSearch, selected } = useFileBrowserContext();
    const { search, searchQuery, clearSearch } = fileBrowserSearch;
    const hasSelection = Boolean(selected.length);
    return (<PickerDialog>
      <PickerHeader title={dialogTitle} subtitle={dialogSubtitle} onClose={onClose} search={search} searchQuery={searchQuery} clearSearch={clearSearch}/>
      <PickerBreadcrumbs />
      <PickerTableFilesystem selectedEntryAlert={selectedEntryAlert} multiselect={multiselect}/>
      {multiselect && <SelectedEntries />}
      <PickerFooter showPagination hasSelection={hasSelection} submitLabel={submitLabel} disabled={!hasSelection} onSubmit={onSubmit}/>
    </PickerDialog>);
}
