import { Box } from '@pocketlaw/tetris';
import { TeamAvatarWithLabel } from 'app/domains/teams';
import { UserAvatarWithLabel } from 'app/domains/users';
import { Container } from './styled';
export function Approver(props) {
    const { name, subtitle, endElement, type = 'user' } = props;
    return (<Container>
      <Box alignItems="center" justifyContent="space-between" width="100%">
        {type === 'user' ? (<UserAvatarWithLabel size="sm" title={name} subtitle={subtitle}/>) : (<TeamAvatarWithLabel size="sm" title={name}/>)}
        {endElement}
      </Box>
    </Container>);
}
