import { useState } from 'react';
import { t } from '@lingui/macro';
import { Avatar, Box, Checkbox, Radio, Typography } from '@pocketlaw/tetris';
import { MaybeLink } from './MaybeLink';
import { getPropsForType } from './componentUtils';
export function TypeAvatarWithLabel(props) {
    const { title, truncate, type, subtitle, link, onSelect, selected = false, multiselect = true, } = props;
    const [hovering, setHovering] = useState(false);
    const { icon, appearance, shape, solid, tooltip } = getPropsForType(type);
    const handleOnMouseEnter = () => {
        if (!onSelect) {
            return;
        }
        setHovering(true);
    };
    const handleOnMouseLeave = () => {
        if (!onSelect) {
            return;
        }
        setHovering(false);
    };
    const showCheckbox = hovering || selected;
    const deselectTooltip = t({ message: 'Deselect', comment: 'Deselect tooltip in type avatar' });
    const selectTooltip = t({ message: 'Select', comment: 'Select tooltip in type avatar' });
    const selectableTooltip = selected ? deselectTooltip : selectTooltip;
    const tooltipText = showCheckbox ? selectableTooltip : tooltip;
    const CheckboxComponent = multiselect ? Checkbox : Radio;
    return (<Box onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave} gap="md">
      {showCheckbox ? (<Box p="md">
          <CheckboxComponent $size="small" checked={selected} onChange={onSelect}/>
        </Box>) : (<Avatar tooltip={{ title: tooltipText, disabled: !tooltip, side: 'left' }} appearance={showCheckbox ? 'ghost' : appearance} size="md" shape={shape} solid={solid}>
          {icon}
        </Avatar>)}
      <MaybeLink to={link}>
        <Box flexDirection="column" alignItems="flex-start" justifyContent="center" style={{ minWidth: '0', overflow: 'hidden' }}>
          {title && (<Typography $truncate={truncate} $appearance="300" $lineHeight="neutralized" $fontWeight="medium" style={{ maxWidth: '100%' }}>
              {title}
            </Typography>)}
          {subtitle && (<Typography $truncate={truncate} $appearance="100" $lineHeight="neutralized" $fontWeight="regular" $fontSize="tiny" style={{ maxWidth: '100%' }}>
              {subtitle}
            </Typography>)}
        </Box>
      </MaybeLink>
    </Box>);
}
