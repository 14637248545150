export var AclAction;
(function (AclAction) {
    AclAction["Delete"] = "DELETE";
    AclAction["Read"] = "READ";
    AclAction["Traverse"] = "TRAVERSE";
    AclAction["Write"] = "WRITE";
})(AclAction || (AclAction = {}));
export var ActivityActorType;
(function (ActivityActorType) {
    ActivityActorType["Signatory"] = "Signatory";
    ActivityActorType["System"] = "System";
    ActivityActorType["Unknown"] = "Unknown";
    ActivityActorType["User"] = "User";
})(ActivityActorType || (ActivityActorType = {}));
/** select columns of table "pl.activity" */
export var ActivitySelectColumn;
(function (ActivitySelectColumn) {
    /** column name */
    ActivitySelectColumn["CategoryId"] = "category_id";
    /** column name */
    ActivitySelectColumn["CompanyId"] = "company_id";
    /** column name */
    ActivitySelectColumn["ComposerId"] = "composer_id";
    /** column name */
    ActivitySelectColumn["CreatedAt"] = "created_at";
    /** column name */
    ActivitySelectColumn["GuideId"] = "guide_id";
    /** column name */
    ActivitySelectColumn["Id"] = "id";
    /** column name */
    ActivitySelectColumn["Kind"] = "kind";
    /** column name */
    ActivitySelectColumn["Status"] = "status";
    /** column name */
    ActivitySelectColumn["TopicId"] = "topic_id";
    /** column name */
    ActivitySelectColumn["UpdatedAt"] = "updated_at";
    /** column name */
    ActivitySelectColumn["UserId"] = "user_id";
})(ActivitySelectColumn || (ActivitySelectColumn = {}));
export var ActivityTargetType;
(function (ActivityTargetType) {
    ActivityTargetType["Document"] = "Document";
    ActivityTargetType["Dummy"] = "Dummy";
})(ActivityTargetType || (ActivityTargetType = {}));
export var ActivityType;
(function (ActivityType) {
    ActivityType["ApprovalRequestApproved"] = "ApprovalRequestApproved";
    ActivityType["ApprovalRequestApproverApproved"] = "ApprovalRequestApproverApproved";
    ActivityType["ApprovalRequestApproverRejected"] = "ApprovalRequestApproverRejected";
    ActivityType["ApprovalRequestCancelled"] = "ApprovalRequestCancelled";
    ActivityType["ApprovalRequestCreated"] = "ApprovalRequestCreated";
    ActivityType["ApprovalRequestRejected"] = "ApprovalRequestRejected";
    ActivityType["SignRequestCancelled"] = "SignRequestCancelled";
    ActivityType["SignRequestCreated"] = "SignRequestCreated";
    ActivityType["SignRequestExpired"] = "SignRequestExpired";
    ActivityType["SignRequestFailed"] = "SignRequestFailed";
    ActivityType["SignRequestRejected"] = "SignRequestRejected";
    ActivityType["SignRequestReminded"] = "SignRequestReminded";
    ActivityType["SignRequestSignatoryChanged"] = "SignRequestSignatoryChanged";
    ActivityType["SignRequestSignatoryOpened"] = "SignRequestSignatoryOpened";
    ActivityType["SignRequestSignatoryRejected"] = "SignRequestSignatoryRejected";
    ActivityType["SignRequestSignatoryRemoved"] = "SignRequestSignatoryRemoved";
    ActivityType["SignRequestSignatorySigned"] = "SignRequestSignatorySigned";
    ActivityType["SignRequestSigned"] = "SignRequestSigned";
})(ActivityType || (ActivityType = {}));
export var ApprovalRequestApproverStatus;
(function (ApprovalRequestApproverStatus) {
    ApprovalRequestApproverStatus["Approved"] = "Approved";
    ApprovalRequestApproverStatus["Pending"] = "Pending";
    ApprovalRequestApproverStatus["Queued"] = "Queued";
    ApprovalRequestApproverStatus["Rejected"] = "Rejected";
})(ApprovalRequestApproverStatus || (ApprovalRequestApproverStatus = {}));
export var ApprovalRequestStatus;
(function (ApprovalRequestStatus) {
    ApprovalRequestStatus["Approved"] = "Approved";
    ApprovalRequestStatus["Cancelled"] = "Cancelled";
    ApprovalRequestStatus["Pending"] = "Pending";
    ApprovalRequestStatus["Rejected"] = "Rejected";
})(ApprovalRequestStatus || (ApprovalRequestStatus = {}));
export var ApprovalType;
(function (ApprovalType) {
    ApprovalType["All"] = "All";
    ApprovalType["OneOf"] = "OneOf";
})(ApprovalType || (ApprovalType = {}));
export var AuthUserPermissionRoleEnum;
(function (AuthUserPermissionRoleEnum) {
    AuthUserPermissionRoleEnum["Member"] = "MEMBER";
    AuthUserPermissionRoleEnum["Owner"] = "OWNER";
})(AuthUserPermissionRoleEnum || (AuthUserPermissionRoleEnum = {}));
export var AuthenticatedVar;
(function (AuthenticatedVar) {
    AuthenticatedVar["Authenticated"] = "AUTHENTICATED";
    AuthenticatedVar["NotAuthenticated"] = "NOT_AUTHENTICATED";
    AuthenticatedVar["NoUser"] = "NO_USER";
})(AuthenticatedVar || (AuthenticatedVar = {}));
/** unique or primary key constraints on table "pl.bundle_composer" */
export var BundleComposerConstraint;
(function (BundleComposerConstraint) {
    /** unique or primary key constraint on columns "id" */
    BundleComposerConstraint["BundleComposerPkey"] = "bundle_composer_pkey";
})(BundleComposerConstraint || (BundleComposerConstraint = {}));
/** select columns of table "pl.bundle_composer" */
export var BundleComposerSelectColumn;
(function (BundleComposerSelectColumn) {
    /** column name */
    BundleComposerSelectColumn["BundleId"] = "bundle_id";
    /** column name */
    BundleComposerSelectColumn["ComposerId"] = "composer_id";
    /** column name */
    BundleComposerSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    BundleComposerSelectColumn["Id"] = "id";
    /** column name */
    BundleComposerSelectColumn["UpdatedAt"] = "updated_at";
})(BundleComposerSelectColumn || (BundleComposerSelectColumn = {}));
/** update columns of table "pl.bundle_composer" */
export var BundleComposerUpdateColumn;
(function (BundleComposerUpdateColumn) {
    /** column name */
    BundleComposerUpdateColumn["BundleId"] = "bundle_id";
    /** column name */
    BundleComposerUpdateColumn["ComposerId"] = "composer_id";
    /** column name */
    BundleComposerUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    BundleComposerUpdateColumn["Id"] = "id";
    /** column name */
    BundleComposerUpdateColumn["UpdatedAt"] = "updated_at";
})(BundleComposerUpdateColumn || (BundleComposerUpdateColumn = {}));
/** unique or primary key constraints on table "pl.bundle" */
export var BundleConstraint;
(function (BundleConstraint) {
    /** unique or primary key constraint on columns "id" */
    BundleConstraint["BundlePkey"] = "bundle_pkey";
})(BundleConstraint || (BundleConstraint = {}));
/** select columns of table "pl.bundle" */
export var BundleSelectColumn;
(function (BundleSelectColumn) {
    /** column name */
    BundleSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    BundleSelectColumn["Enabled"] = "enabled";
    /** column name */
    BundleSelectColumn["Id"] = "id";
    /** column name */
    BundleSelectColumn["Name"] = "name";
    /** column name */
    BundleSelectColumn["StripeProductId"] = "stripe_product_id";
    /** column name */
    BundleSelectColumn["UpdatedAt"] = "updated_at";
})(BundleSelectColumn || (BundleSelectColumn = {}));
/** update columns of table "pl.bundle" */
export var BundleUpdateColumn;
(function (BundleUpdateColumn) {
    /** column name */
    BundleUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    BundleUpdateColumn["Enabled"] = "enabled";
    /** column name */
    BundleUpdateColumn["Id"] = "id";
    /** column name */
    BundleUpdateColumn["Name"] = "name";
    /** column name */
    BundleUpdateColumn["StripeProductId"] = "stripe_product_id";
    /** column name */
    BundleUpdateColumn["UpdatedAt"] = "updated_at";
})(BundleUpdateColumn || (BundleUpdateColumn = {}));
/** https://stripe.com/docs/api/cards/object#card_object-brand */
export var CardPaymentMethodBrand;
(function (CardPaymentMethodBrand) {
    CardPaymentMethodBrand["AmericanExpress"] = "AMERICAN_EXPRESS";
    CardPaymentMethodBrand["DinersClub"] = "DINERS_CLUB";
    CardPaymentMethodBrand["Discover"] = "DISCOVER";
    CardPaymentMethodBrand["Jcb"] = "JCB";
    CardPaymentMethodBrand["MasterCard"] = "MASTER_CARD";
    CardPaymentMethodBrand["UnionPay"] = "UNION_PAY";
    CardPaymentMethodBrand["Unknown"] = "UNKNOWN";
    CardPaymentMethodBrand["Visa"] = "VISA";
})(CardPaymentMethodBrand || (CardPaymentMethodBrand = {}));
/** unique or primary key constraints on table "pl.category" */
export var CategoryConstraint;
(function (CategoryConstraint) {
    /** unique or primary key constraint on columns "id" */
    CategoryConstraint["CategoryPkey"] = "category_pkey";
})(CategoryConstraint || (CategoryConstraint = {}));
/** unique or primary key constraints on table "pl.category_metadata" */
export var CategoryMetadataConstraint;
(function (CategoryMetadataConstraint) {
    /** unique or primary key constraint on columns "category_id", "market_locale_code" */
    CategoryMetadataConstraint["CategoryMetadataCategoryIdMarketLocaleCodeKey"] = "category_metadata_category_id_market_locale_code_key";
    /** unique or primary key constraint on columns "id" */
    CategoryMetadataConstraint["CategoryMetadataPkey"] = "category_metadata_pkey";
})(CategoryMetadataConstraint || (CategoryMetadataConstraint = {}));
/** select columns of table "pl.category_metadata" */
export var CategoryMetadataSelectColumn;
(function (CategoryMetadataSelectColumn) {
    /** column name */
    CategoryMetadataSelectColumn["CategoryId"] = "category_id";
    /** column name */
    CategoryMetadataSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    CategoryMetadataSelectColumn["Description"] = "description";
    /** column name */
    CategoryMetadataSelectColumn["Id"] = "id";
    /** column name */
    CategoryMetadataSelectColumn["MarketLocaleCode"] = "market_locale_code";
    /** column name */
    CategoryMetadataSelectColumn["Title"] = "title";
    /** column name */
    CategoryMetadataSelectColumn["UpdatedAt"] = "updated_at";
})(CategoryMetadataSelectColumn || (CategoryMetadataSelectColumn = {}));
/** update columns of table "pl.category_metadata" */
export var CategoryMetadataUpdateColumn;
(function (CategoryMetadataUpdateColumn) {
    /** column name */
    CategoryMetadataUpdateColumn["CategoryId"] = "category_id";
    /** column name */
    CategoryMetadataUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    CategoryMetadataUpdateColumn["Description"] = "description";
    /** column name */
    CategoryMetadataUpdateColumn["Id"] = "id";
    /** column name */
    CategoryMetadataUpdateColumn["MarketLocaleCode"] = "market_locale_code";
    /** column name */
    CategoryMetadataUpdateColumn["Title"] = "title";
    /** column name */
    CategoryMetadataUpdateColumn["UpdatedAt"] = "updated_at";
})(CategoryMetadataUpdateColumn || (CategoryMetadataUpdateColumn = {}));
/** select columns of table "pl.category" */
export var CategorySelectColumn;
(function (CategorySelectColumn) {
    /** column name */
    CategorySelectColumn["CreatedAt"] = "created_at";
    /** column name */
    CategorySelectColumn["Id"] = "id";
    /** column name */
    CategorySelectColumn["Name"] = "name";
    /** column name */
    CategorySelectColumn["Order"] = "order";
    /** column name */
    CategorySelectColumn["ThemeId"] = "theme_id";
    /** column name */
    CategorySelectColumn["UpdatedAt"] = "updated_at";
})(CategorySelectColumn || (CategorySelectColumn = {}));
/** update columns of table "pl.category" */
export var CategoryUpdateColumn;
(function (CategoryUpdateColumn) {
    /** column name */
    CategoryUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    CategoryUpdateColumn["Id"] = "id";
    /** column name */
    CategoryUpdateColumn["Name"] = "name";
    /** column name */
    CategoryUpdateColumn["Order"] = "order";
    /** column name */
    CategoryUpdateColumn["ThemeId"] = "theme_id";
    /** column name */
    CategoryUpdateColumn["UpdatedAt"] = "updated_at";
})(CategoryUpdateColumn || (CategoryUpdateColumn = {}));
export var CcsDocumentFlushReason;
(function (CcsDocumentFlushReason) {
    CcsDocumentFlushReason["Converted"] = "CONVERTED";
    CcsDocumentFlushReason["Deleted"] = "DELETED";
    CcsDocumentFlushReason["EditingWithTemplate"] = "EDITING_WITH_TEMPLATE";
    CcsDocumentFlushReason["Locked"] = "LOCKED";
    CcsDocumentFlushReason["NewBundleVersion"] = "NEW_BUNDLE_VERSION";
    CcsDocumentFlushReason["SigningStatusCancelled"] = "SIGNING_STATUS_CANCELLED";
    CcsDocumentFlushReason["SigningStatusPending"] = "SIGNING_STATUS_PENDING";
    CcsDocumentFlushReason["SigningStatusRejected"] = "SIGNING_STATUS_REJECTED";
    CcsDocumentFlushReason["Unlocked"] = "UNLOCKED";
    CcsDocumentFlushReason["UserForced"] = "USER_FORCED";
})(CcsDocumentFlushReason || (CcsDocumentFlushReason = {}));
export var ChatMessageRole;
(function (ChatMessageRole) {
    ChatMessageRole["Assistant"] = "ASSISTANT";
    ChatMessageRole["System"] = "SYSTEM";
    ChatMessageRole["User"] = "USER";
})(ChatMessageRole || (ChatMessageRole = {}));
export var ChatMessageStatus;
(function (ChatMessageStatus) {
    ChatMessageStatus["Failed"] = "FAILED";
    ChatMessageStatus["Pending"] = "PENDING";
    ChatMessageStatus["Resolved"] = "RESOLVED";
})(ChatMessageStatus || (ChatMessageStatus = {}));
export var ChatMessageType;
(function (ChatMessageType) {
    ChatMessageType["DocumentAsk"] = "DOCUMENT_ASK";
    ChatMessageType["DocumentAskWithQuotes"] = "DOCUMENT_ASK_WITH_QUOTES";
    ChatMessageType["DocumentCompare"] = "DOCUMENT_COMPARE";
    ChatMessageType["DocumentSuggest"] = "DOCUMENT_SUGGEST";
    ChatMessageType["DocumentSummarize"] = "DOCUMENT_SUMMARIZE";
    ChatMessageType["DocumentSummarizeWithQuotes"] = "DOCUMENT_SUMMARIZE_WITH_QUOTES";
    ChatMessageType["SelectionAsk"] = "SELECTION_ASK";
    ChatMessageType["SelectionAskWithQuotes"] = "SELECTION_ASK_WITH_QUOTES";
    ChatMessageType["SelectionExplain"] = "SELECTION_EXPLAIN";
    ChatMessageType["SelectionRewrite"] = "SELECTION_REWRITE";
    ChatMessageType["SelectionSuggest"] = "SELECTION_SUGGEST";
    ChatMessageType["SelectionSummarize"] = "SELECTION_SUMMARIZE";
    ChatMessageType["SelectionSummarizeWithQuotes"] = "SELECTION_SUMMARIZE_WITH_QUOTES";
    ChatMessageType["SelectionTranslate"] = "SELECTION_TRANSLATE";
})(ChatMessageType || (ChatMessageType = {}));
export var ChatResolutionStatus;
(function (ChatResolutionStatus) {
    ChatResolutionStatus["ChatNotSupported"] = "CHAT_NOT_SUPPORTED";
    ChatResolutionStatus["ContentLengthExceeded"] = "CONTENT_LENGTH_EXCEEDED";
    ChatResolutionStatus["MissingContentOrUnsupportedFileFormat"] = "MISSING_CONTENT_OR_UNSUPPORTED_FILE_FORMAT";
    ChatResolutionStatus["Unknown"] = "UNKNOWN";
})(ChatResolutionStatus || (ChatResolutionStatus = {}));
/** unique or primary key constraints on table "pl.company_activity" */
export var CompanyActivityConstraint;
(function (CompanyActivityConstraint) {
    /** unique or primary key constraint on columns "id" */
    CompanyActivityConstraint["CompanyActivityPkey"] = "company_activity_pkey";
})(CompanyActivityConstraint || (CompanyActivityConstraint = {}));
/** select columns of table "pl.company_activity" */
export var CompanyActivitySelectColumn;
(function (CompanyActivitySelectColumn) {
    /** column name */
    CompanyActivitySelectColumn["CompanyId"] = "company_id";
    /** column name */
    CompanyActivitySelectColumn["CreatedAt"] = "created_at";
    /** column name */
    CompanyActivitySelectColumn["Id"] = "id";
    /** column name */
    CompanyActivitySelectColumn["UserId"] = "user_id";
})(CompanyActivitySelectColumn || (CompanyActivitySelectColumn = {}));
/** update columns of table "pl.company_activity" */
export var CompanyActivityUpdateColumn;
(function (CompanyActivityUpdateColumn) {
    /** column name */
    CompanyActivityUpdateColumn["CompanyId"] = "company_id";
    /** column name */
    CompanyActivityUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    CompanyActivityUpdateColumn["Id"] = "id";
    /** column name */
    CompanyActivityUpdateColumn["UserId"] = "user_id";
})(CompanyActivityUpdateColumn || (CompanyActivityUpdateColumn = {}));
/** unique or primary key constraints on table "pl.company_annual_revenue_range_enum" */
export var CompanyAnnualRevenueRangeEnumConstraint;
(function (CompanyAnnualRevenueRangeEnumConstraint) {
    /** unique or primary key constraint on columns "name" */
    CompanyAnnualRevenueRangeEnumConstraint["CompanyAnnualRevenueRangeEnumPkey"] = "company_annual_revenue_range_enum_pkey";
})(CompanyAnnualRevenueRangeEnumConstraint || (CompanyAnnualRevenueRangeEnumConstraint = {}));
export var CompanyAnnualRevenueRangeEnumEnum;
(function (CompanyAnnualRevenueRangeEnumEnum) {
    /** between 1,000,000 and 5,000,000 euros */
    CompanyAnnualRevenueRangeEnumEnum["Large"] = "large";
    /** between 200,000 and 1,000,000 euros */
    CompanyAnnualRevenueRangeEnumEnum["Medium"] = "medium";
    /** between 0 and 200,000 euros */
    CompanyAnnualRevenueRangeEnumEnum["Small"] = "small";
    /** above 5,000,000 euros */
    CompanyAnnualRevenueRangeEnumEnum["VeryLarge"] = "very_large";
})(CompanyAnnualRevenueRangeEnumEnum || (CompanyAnnualRevenueRangeEnumEnum = {}));
/** select columns of table "pl.company_annual_revenue_range_enum" */
export var CompanyAnnualRevenueRangeEnumSelectColumn;
(function (CompanyAnnualRevenueRangeEnumSelectColumn) {
    /** column name */
    CompanyAnnualRevenueRangeEnumSelectColumn["Description"] = "description";
    /** column name */
    CompanyAnnualRevenueRangeEnumSelectColumn["Name"] = "name";
})(CompanyAnnualRevenueRangeEnumSelectColumn || (CompanyAnnualRevenueRangeEnumSelectColumn = {}));
/** update columns of table "pl.company_annual_revenue_range_enum" */
export var CompanyAnnualRevenueRangeEnumUpdateColumn;
(function (CompanyAnnualRevenueRangeEnumUpdateColumn) {
    /** column name */
    CompanyAnnualRevenueRangeEnumUpdateColumn["Description"] = "description";
    /** column name */
    CompanyAnnualRevenueRangeEnumUpdateColumn["Name"] = "name";
})(CompanyAnnualRevenueRangeEnumUpdateColumn || (CompanyAnnualRevenueRangeEnumUpdateColumn = {}));
/** unique or primary key constraints on table "pl.company_bundle" */
export var CompanyBundleConstraint;
(function (CompanyBundleConstraint) {
    /** unique or primary key constraint on columns "id" */
    CompanyBundleConstraint["CompanyBundlePkey"] = "company_bundle_pkey";
})(CompanyBundleConstraint || (CompanyBundleConstraint = {}));
/** select columns of table "pl.company_bundle" */
export var CompanyBundleSelectColumn;
(function (CompanyBundleSelectColumn) {
    /** column name */
    CompanyBundleSelectColumn["BundleId"] = "bundle_id";
    /** column name */
    CompanyBundleSelectColumn["CompanyId"] = "company_id";
    /** column name */
    CompanyBundleSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    CompanyBundleSelectColumn["Id"] = "id";
    /** column name */
    CompanyBundleSelectColumn["UpdatedAt"] = "updated_at";
})(CompanyBundleSelectColumn || (CompanyBundleSelectColumn = {}));
/** update columns of table "pl.company_bundle" */
export var CompanyBundleUpdateColumn;
(function (CompanyBundleUpdateColumn) {
    /** column name */
    CompanyBundleUpdateColumn["BundleId"] = "bundle_id";
    /** column name */
    CompanyBundleUpdateColumn["CompanyId"] = "company_id";
    /** column name */
    CompanyBundleUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    CompanyBundleUpdateColumn["Id"] = "id";
    /** column name */
    CompanyBundleUpdateColumn["UpdatedAt"] = "updated_at";
})(CompanyBundleUpdateColumn || (CompanyBundleUpdateColumn = {}));
/** unique or primary key constraints on table "pl.company_business_type_enum" */
export var CompanyBusinessTypeEnumConstraint;
(function (CompanyBusinessTypeEnumConstraint) {
    /** unique or primary key constraint on columns "name" */
    CompanyBusinessTypeEnumConstraint["CompanyBusinessTypeEnumPkey"] = "company_business_type_enum_pkey";
})(CompanyBusinessTypeEnumConstraint || (CompanyBusinessTypeEnumConstraint = {}));
export var CompanyBusinessTypeEnumEnum;
(function (CompanyBusinessTypeEnumEnum) {
    /** Business to Business */
    CompanyBusinessTypeEnumEnum["B2b"] = "b2b";
    /** Business to Business & Business to Consumers */
    CompanyBusinessTypeEnumEnum["B2bB2c"] = "b2b_b2c";
    /** Business to Consumers */
    CompanyBusinessTypeEnumEnum["B2c"] = "b2c";
})(CompanyBusinessTypeEnumEnum || (CompanyBusinessTypeEnumEnum = {}));
/** select columns of table "pl.company_business_type_enum" */
export var CompanyBusinessTypeEnumSelectColumn;
(function (CompanyBusinessTypeEnumSelectColumn) {
    /** column name */
    CompanyBusinessTypeEnumSelectColumn["Description"] = "description";
    /** column name */
    CompanyBusinessTypeEnumSelectColumn["Name"] = "name";
})(CompanyBusinessTypeEnumSelectColumn || (CompanyBusinessTypeEnumSelectColumn = {}));
/** update columns of table "pl.company_business_type_enum" */
export var CompanyBusinessTypeEnumUpdateColumn;
(function (CompanyBusinessTypeEnumUpdateColumn) {
    /** column name */
    CompanyBusinessTypeEnumUpdateColumn["Description"] = "description";
    /** column name */
    CompanyBusinessTypeEnumUpdateColumn["Name"] = "name";
})(CompanyBusinessTypeEnumUpdateColumn || (CompanyBusinessTypeEnumUpdateColumn = {}));
/** unique or primary key constraints on table "pl.company_composer" */
export var CompanyComposerConstraint;
(function (CompanyComposerConstraint) {
    /** unique or primary key constraint on columns "composer_id", "company_id" */
    CompanyComposerConstraint["CompanyComposerCompanyIdComposerIdKey"] = "company_composer_company_id_composer_id_key";
    /** unique or primary key constraint on columns "id" */
    CompanyComposerConstraint["CompanyComposerPkey"] = "company_composer_pkey";
})(CompanyComposerConstraint || (CompanyComposerConstraint = {}));
/** select columns of table "pl.company_composer" */
export var CompanyComposerSelectColumn;
(function (CompanyComposerSelectColumn) {
    /** column name */
    CompanyComposerSelectColumn["CompanyId"] = "company_id";
    /** column name */
    CompanyComposerSelectColumn["ComposerId"] = "composer_id";
    /** column name */
    CompanyComposerSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    CompanyComposerSelectColumn["Id"] = "id";
    /** column name */
    CompanyComposerSelectColumn["UpdateAt"] = "update_at";
})(CompanyComposerSelectColumn || (CompanyComposerSelectColumn = {}));
/** update columns of table "pl.company_composer" */
export var CompanyComposerUpdateColumn;
(function (CompanyComposerUpdateColumn) {
    /** column name */
    CompanyComposerUpdateColumn["CompanyId"] = "company_id";
    /** column name */
    CompanyComposerUpdateColumn["ComposerId"] = "composer_id";
    /** column name */
    CompanyComposerUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    CompanyComposerUpdateColumn["Id"] = "id";
    /** column name */
    CompanyComposerUpdateColumn["UpdateAt"] = "update_at";
})(CompanyComposerUpdateColumn || (CompanyComposerUpdateColumn = {}));
/** unique or primary key constraints on table "pl.company" */
export var CompanyConstraint;
(function (CompanyConstraint) {
    /** unique or primary key constraint on columns "id", "stripe_customer_id" */
    CompanyConstraint["CompanyIdStripeCustomerIdKey"] = "company_id_stripe_customer_id_key";
    /** unique or primary key constraint on columns "id" */
    CompanyConstraint["CompanyPkey"] = "company_pkey";
    /** unique or primary key constraint on columns "stripe_customer_id" */
    CompanyConstraint["CompanyStripeCustomerIdKey"] = "company_stripe_customer_id_key";
})(CompanyConstraint || (CompanyConstraint = {}));
/** unique or primary key constraints on table "pl.company_employee_count_enum" */
export var CompanyEmployeeCountEnumConstraint;
(function (CompanyEmployeeCountEnumConstraint) {
    /** unique or primary key constraint on columns "name" */
    CompanyEmployeeCountEnumConstraint["CompanyEmployeeCountEnumPkey"] = "company_employee_count_enum_pkey";
})(CompanyEmployeeCountEnumConstraint || (CompanyEmployeeCountEnumConstraint = {}));
export var CompanyEmployeeCountEnumEnum;
(function (CompanyEmployeeCountEnumEnum) {
    /** 501-1,000 employees */
    CompanyEmployeeCountEnumEnum["Large"] = "large";
    /** 251-500 employees */
    CompanyEmployeeCountEnumEnum["Medium"] = "medium";
    /** 51-250 employees */
    CompanyEmployeeCountEnumEnum["Small"] = "small";
    /** 11-50 employees */
    CompanyEmployeeCountEnumEnum["Smalltiny"] = "smalltiny";
    /** up to 10 employees */
    CompanyEmployeeCountEnumEnum["Tiny"] = "tiny";
    /** 1,001-5,000 employees */
    CompanyEmployeeCountEnumEnum["Xlarge"] = "xlarge";
    /** 5,001-10,000 employees */
    CompanyEmployeeCountEnumEnum["Xxlarge"] = "xxlarge";
    /** More than 10,000 employees */
    CompanyEmployeeCountEnumEnum["Xxxlarge"] = "xxxlarge";
})(CompanyEmployeeCountEnumEnum || (CompanyEmployeeCountEnumEnum = {}));
/** select columns of table "pl.company_employee_count_enum" */
export var CompanyEmployeeCountEnumSelectColumn;
(function (CompanyEmployeeCountEnumSelectColumn) {
    /** column name */
    CompanyEmployeeCountEnumSelectColumn["Description"] = "description";
    /** column name */
    CompanyEmployeeCountEnumSelectColumn["Name"] = "name";
})(CompanyEmployeeCountEnumSelectColumn || (CompanyEmployeeCountEnumSelectColumn = {}));
/** update columns of table "pl.company_employee_count_enum" */
export var CompanyEmployeeCountEnumUpdateColumn;
(function (CompanyEmployeeCountEnumUpdateColumn) {
    /** column name */
    CompanyEmployeeCountEnumUpdateColumn["Description"] = "description";
    /** column name */
    CompanyEmployeeCountEnumUpdateColumn["Name"] = "name";
})(CompanyEmployeeCountEnumUpdateColumn || (CompanyEmployeeCountEnumUpdateColumn = {}));
/** select columns of table "pl.company_esigning_provider" */
export var CompanyEsigningProviderSelectColumn;
(function (CompanyEsigningProviderSelectColumn) {
    /** column name */
    CompanyEsigningProviderSelectColumn["CompanyId"] = "company_id";
    /** column name */
    CompanyEsigningProviderSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    CompanyEsigningProviderSelectColumn["Credentials"] = "credentials";
    /** column name */
    CompanyEsigningProviderSelectColumn["EsigningProviderId"] = "esigning_provider_id";
    /** column name */
    CompanyEsigningProviderSelectColumn["ExpiresAt"] = "expires_at";
    /** column name */
    CompanyEsigningProviderSelectColumn["Id"] = "id";
    /** column name */
    CompanyEsigningProviderSelectColumn["UpdatedAt"] = "updated_at";
})(CompanyEsigningProviderSelectColumn || (CompanyEsigningProviderSelectColumn = {}));
/** unique or primary key constraints on table "pl.company_industry_enum" */
export var CompanyIndustryEnumConstraint;
(function (CompanyIndustryEnumConstraint) {
    /** unique or primary key constraint on columns "name" */
    CompanyIndustryEnumConstraint["CompanyIndustryEnumPkey"] = "company_industry_enum_pkey";
})(CompanyIndustryEnumConstraint || (CompanyIndustryEnumConstraint = {}));
export var CompanyIndustryEnumEnum;
(function (CompanyIndustryEnumEnum) {
    /** Other */
    CompanyIndustryEnumEnum["Other"] = "_other";
    /** Animal health */
    CompanyIndustryEnumEnum["AnimalHealth"] = "animal_health";
    /** Banking/Financial Services */
    CompanyIndustryEnumEnum["BankingFinancialServices"] = "banking_financial_services";
    /** Computer Games */
    CompanyIndustryEnumEnum["ComputerGames"] = "computer_games";
    /** Computer software */
    CompanyIndustryEnumEnum["ComputerSoftware"] = "computer_software";
    /** Construction */
    CompanyIndustryEnumEnum["Construction"] = "construction";
    /** Consultancy Services */
    CompanyIndustryEnumEnum["ConsultancyServices"] = "consultancy_services";
    /** Consumers goods */
    CompanyIndustryEnumEnum["ConsumersGoods"] = "consumers_goods";
    /** E-commerce */
    CompanyIndustryEnumEnum["Ecommerce"] = "ecommerce";
    /** Education */
    CompanyIndustryEnumEnum["Education"] = "education";
    /** Food & Beverages */
    CompanyIndustryEnumEnum["FoodBeverages"] = "food_beverages";
    /** Health Care */
    CompanyIndustryEnumEnum["HealthCare"] = "health_care";
    /** Hospitality */
    CompanyIndustryEnumEnum["Hospitality"] = "hospitality";
    /** Insurance */
    CompanyIndustryEnumEnum["Insurance"] = "insurance";
    /** Information Technology & Services */
    CompanyIndustryEnumEnum["ItServices"] = "it_services";
    /** Logistics & Supply Chain */
    CompanyIndustryEnumEnum["LogisticsSupplyChain"] = "logistics_supply_chain";
    /** Marketing & Advertising */
    CompanyIndustryEnumEnum["MarketingAdvertising"] = "marketing_advertising";
    /** Music & Entertainment */
    CompanyIndustryEnumEnum["MusicEntertainment"] = "music_entertainment";
    /** Real estate */
    CompanyIndustryEnumEnum["RealEstate"] = "real_estate";
    /** Retail */
    CompanyIndustryEnumEnum["Retail"] = "retail";
    /** Telecommunication */
    CompanyIndustryEnumEnum["Telecommunication"] = "telecommunication";
})(CompanyIndustryEnumEnum || (CompanyIndustryEnumEnum = {}));
/** select columns of table "pl.company_industry_enum" */
export var CompanyIndustryEnumSelectColumn;
(function (CompanyIndustryEnumSelectColumn) {
    /** column name */
    CompanyIndustryEnumSelectColumn["Description"] = "description";
    /** column name */
    CompanyIndustryEnumSelectColumn["Name"] = "name";
})(CompanyIndustryEnumSelectColumn || (CompanyIndustryEnumSelectColumn = {}));
/** update columns of table "pl.company_industry_enum" */
export var CompanyIndustryEnumUpdateColumn;
(function (CompanyIndustryEnumUpdateColumn) {
    /** column name */
    CompanyIndustryEnumUpdateColumn["Description"] = "description";
    /** column name */
    CompanyIndustryEnumUpdateColumn["Name"] = "name";
})(CompanyIndustryEnumUpdateColumn || (CompanyIndustryEnumUpdateColumn = {}));
/** unique or primary key constraints on table "pl.company_plan" */
export var CompanyPlanConstraint;
(function (CompanyPlanConstraint) {
    /** unique or primary key constraint on columns "company_id" */
    CompanyPlanConstraint["CompanyPlanCompanyIdKey"] = "company_plan_company_id_key";
    /** unique or primary key constraint on columns "id" */
    CompanyPlanConstraint["CompanyPlanPkey"] = "company_plan_pkey";
})(CompanyPlanConstraint || (CompanyPlanConstraint = {}));
/** select columns of table "pl.company_plan" */
export var CompanyPlanSelectColumn;
(function (CompanyPlanSelectColumn) {
    /** column name */
    CompanyPlanSelectColumn["CompanyId"] = "company_id";
    /** column name */
    CompanyPlanSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    CompanyPlanSelectColumn["Id"] = "id";
    /** column name */
    CompanyPlanSelectColumn["PlanId"] = "plan_id";
    /** column name */
    CompanyPlanSelectColumn["StripeSubscriptionId"] = "stripe_subscription_id";
    /** column name */
    CompanyPlanSelectColumn["UpdatedAt"] = "updated_at";
})(CompanyPlanSelectColumn || (CompanyPlanSelectColumn = {}));
/** update columns of table "pl.company_plan" */
export var CompanyPlanUpdateColumn;
(function (CompanyPlanUpdateColumn) {
    /** column name */
    CompanyPlanUpdateColumn["CompanyId"] = "company_id";
    /** column name */
    CompanyPlanUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    CompanyPlanUpdateColumn["Id"] = "id";
    /** column name */
    CompanyPlanUpdateColumn["PlanId"] = "plan_id";
    /** column name */
    CompanyPlanUpdateColumn["StripeSubscriptionId"] = "stripe_subscription_id";
    /** column name */
    CompanyPlanUpdateColumn["UpdatedAt"] = "updated_at";
})(CompanyPlanUpdateColumn || (CompanyPlanUpdateColumn = {}));
/** unique or primary key constraints on table "pl.company_preferred_usage" */
export var CompanyPreferredUsageConstraint;
(function (CompanyPreferredUsageConstraint) {
    /** unique or primary key constraint on columns "id" */
    CompanyPreferredUsageConstraint["CompanyPreferredUsagePkey"] = "company_preferred_usage_pkey";
})(CompanyPreferredUsageConstraint || (CompanyPreferredUsageConstraint = {}));
/** unique or primary key constraints on table "pl.company_preferred_usage_enum" */
export var CompanyPreferredUsageEnumConstraint;
(function (CompanyPreferredUsageEnumConstraint) {
    /** unique or primary key constraint on columns "name" */
    CompanyPreferredUsageEnumConstraint["CompanyPreferredUsageEnumPkey"] = "company_preferred_usage_enum_pkey";
})(CompanyPreferredUsageEnumConstraint || (CompanyPreferredUsageEnumConstraint = {}));
export var CompanyPreferredUsageEnumEnum;
(function (CompanyPreferredUsageEnumEnum) {
    /** Other */
    CompanyPreferredUsageEnumEnum["Other"] = "_other";
    /** All of the above */
    CompanyPreferredUsageEnumEnum["AllOfTheAbove"] = "all_of_the_above";
    /** E-signing */
    CompanyPreferredUsageEnumEnum["ESigning"] = "e_signing";
    /** Need for all in one platform */
    CompanyPreferredUsageEnumEnum["NeedForAllInOnePlatform"] = "need_for_all_in_one_platform";
    /** Need for contract automation */
    CompanyPreferredUsageEnumEnum["NeedForContractAutomation"] = "need_for_contract_automation";
    /** Need for contract management */
    CompanyPreferredUsageEnumEnum["NeedForContractManagement"] = "need_for_contract_management";
    /** Need for e-signing */
    CompanyPreferredUsageEnumEnum["NeedForESigning"] = "need_for_e_signing";
    /** Need for one agreement */
    CompanyPreferredUsageEnumEnum["NeedForOneAgreement"] = "need_for_one_agreement";
    /** Need for legal template */
    CompanyPreferredUsageEnumEnum["NeedLegalTemplate"] = "need_legal_template";
    /** Work smarter with legal */
    CompanyPreferredUsageEnumEnum["WorkSmarterWithLegal"] = "work_smarter_with_legal";
})(CompanyPreferredUsageEnumEnum || (CompanyPreferredUsageEnumEnum = {}));
/** select columns of table "pl.company_preferred_usage_enum" */
export var CompanyPreferredUsageEnumSelectColumn;
(function (CompanyPreferredUsageEnumSelectColumn) {
    /** column name */
    CompanyPreferredUsageEnumSelectColumn["Description"] = "description";
    /** column name */
    CompanyPreferredUsageEnumSelectColumn["Name"] = "name";
})(CompanyPreferredUsageEnumSelectColumn || (CompanyPreferredUsageEnumSelectColumn = {}));
/** update columns of table "pl.company_preferred_usage_enum" */
export var CompanyPreferredUsageEnumUpdateColumn;
(function (CompanyPreferredUsageEnumUpdateColumn) {
    /** column name */
    CompanyPreferredUsageEnumUpdateColumn["Description"] = "description";
    /** column name */
    CompanyPreferredUsageEnumUpdateColumn["Name"] = "name";
})(CompanyPreferredUsageEnumUpdateColumn || (CompanyPreferredUsageEnumUpdateColumn = {}));
/** select columns of table "pl.company_preferred_usage" */
export var CompanyPreferredUsageSelectColumn;
(function (CompanyPreferredUsageSelectColumn) {
    /** column name */
    CompanyPreferredUsageSelectColumn["CompanyId"] = "company_id";
    /** column name */
    CompanyPreferredUsageSelectColumn["Id"] = "id";
    /** column name */
    CompanyPreferredUsageSelectColumn["PreferredUsage"] = "preferred_usage";
})(CompanyPreferredUsageSelectColumn || (CompanyPreferredUsageSelectColumn = {}));
/** update columns of table "pl.company_preferred_usage" */
export var CompanyPreferredUsageUpdateColumn;
(function (CompanyPreferredUsageUpdateColumn) {
    /** column name */
    CompanyPreferredUsageUpdateColumn["CompanyId"] = "company_id";
    /** column name */
    CompanyPreferredUsageUpdateColumn["Id"] = "id";
    /** column name */
    CompanyPreferredUsageUpdateColumn["PreferredUsage"] = "preferred_usage";
})(CompanyPreferredUsageUpdateColumn || (CompanyPreferredUsageUpdateColumn = {}));
/** unique or primary key constraints on table "pl.company_referral_coupon" */
export var CompanyReferralCouponConstraint;
(function (CompanyReferralCouponConstraint) {
    /** unique or primary key constraint on columns "stripe_coupon_id", "company_id" */
    CompanyReferralCouponConstraint["CompanyReferralCouponCompanyIdStripeCouponIdKey"] = "company_referral_coupon_company_id_stripe_coupon_id_key";
    /** unique or primary key constraint on columns "id" */
    CompanyReferralCouponConstraint["CompanyReferralCouponPkey"] = "company_referral_coupon_pkey";
})(CompanyReferralCouponConstraint || (CompanyReferralCouponConstraint = {}));
/** select columns of table "pl.company_referral_coupon" */
export var CompanyReferralCouponSelectColumn;
(function (CompanyReferralCouponSelectColumn) {
    /** column name */
    CompanyReferralCouponSelectColumn["CompanyId"] = "company_id";
    /** column name */
    CompanyReferralCouponSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    CompanyReferralCouponSelectColumn["Id"] = "id";
    /** column name */
    CompanyReferralCouponSelectColumn["StripeCouponId"] = "stripe_coupon_id";
})(CompanyReferralCouponSelectColumn || (CompanyReferralCouponSelectColumn = {}));
/** update columns of table "pl.company_referral_coupon" */
export var CompanyReferralCouponUpdateColumn;
(function (CompanyReferralCouponUpdateColumn) {
    /** column name */
    CompanyReferralCouponUpdateColumn["CompanyId"] = "company_id";
    /** column name */
    CompanyReferralCouponUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    CompanyReferralCouponUpdateColumn["Id"] = "id";
    /** column name */
    CompanyReferralCouponUpdateColumn["StripeCouponId"] = "stripe_coupon_id";
})(CompanyReferralCouponUpdateColumn || (CompanyReferralCouponUpdateColumn = {}));
/** unique or primary key constraints on table "pl.company_role" */
export var CompanyRoleConstraint;
(function (CompanyRoleConstraint) {
    /** unique or primary key constraint on columns "name" */
    CompanyRoleConstraint["CompanyRolePkey"] = "company_role_pkey";
})(CompanyRoleConstraint || (CompanyRoleConstraint = {}));
export var CompanyRoleEnum;
(function (CompanyRoleEnum) {
    CompanyRoleEnum["Member"] = "member";
    CompanyRoleEnum["Owner"] = "owner";
})(CompanyRoleEnum || (CompanyRoleEnum = {}));
/** select columns of table "pl.company_role" */
export var CompanyRoleSelectColumn;
(function (CompanyRoleSelectColumn) {
    /** column name */
    CompanyRoleSelectColumn["Name"] = "name";
})(CompanyRoleSelectColumn || (CompanyRoleSelectColumn = {}));
/** update columns of table "pl.company_role" */
export var CompanyRoleUpdateColumn;
(function (CompanyRoleUpdateColumn) {
    /** column name */
    CompanyRoleUpdateColumn["Name"] = "name";
})(CompanyRoleUpdateColumn || (CompanyRoleUpdateColumn = {}));
/** select columns of table "pl.company" */
export var CompanySelectColumn;
(function (CompanySelectColumn) {
    /** column name */
    CompanySelectColumn["AddressCity"] = "address_city";
    /** column name */
    CompanySelectColumn["AddressCountry"] = "address_country";
    /** column name */
    CompanySelectColumn["AddressStreet"] = "address_street";
    /** column name */
    CompanySelectColumn["AddressZip"] = "address_zip";
    /** column name */
    CompanySelectColumn["AnnualRevenueRange"] = "annual_revenue_range";
    /** column name */
    CompanySelectColumn["BusinessType"] = "business_type";
    /** column name */
    CompanySelectColumn["CreatedAt"] = "created_at";
    /** column name */
    CompanySelectColumn["EmployeeCount"] = "employee_count";
    /** column name */
    CompanySelectColumn["Id"] = "id";
    /** column name */
    CompanySelectColumn["Industry"] = "industry";
    /** column name */
    CompanySelectColumn["IndustryOther"] = "industry_other";
    /** column name */
    CompanySelectColumn["LogoPath"] = "logo_path";
    /** column name */
    CompanySelectColumn["LogoUploaded"] = "logo_uploaded";
    /** column name */
    CompanySelectColumn["MarketCode"] = "market_code";
    /** column name */
    CompanySelectColumn["Name"] = "name";
    /** column name */
    CompanySelectColumn["OrganizationNumber"] = "organization_number";
    /** column name */
    CompanySelectColumn["PreferredUsage"] = "preferred_usage";
    /** column name */
    CompanySelectColumn["ReferrerId"] = "referrer_id";
    /** column name */
    CompanySelectColumn["SsoEnterprise"] = "sso_enterprise";
    /** column name */
    CompanySelectColumn["SsoEnterpriseSamlpConnectionId"] = "sso_enterprise_samlp_connection_id";
    /** column name */
    CompanySelectColumn["StripeCustomerId"] = "stripe_customer_id";
    /** column name */
    CompanySelectColumn["UpdatedAt"] = "updated_at";
    /** column name */
    CompanySelectColumn["WebDomain"] = "web_domain";
    /** column name */
    CompanySelectColumn["YearFounded"] = "year_founded";
})(CompanySelectColumn || (CompanySelectColumn = {}));
/** select "pl_company_aggregate_bool_exp_bool_and_arguments_columns" columns of table "pl.company" */
export var CompanySelectColumnPlCompanyAggregateBoolExpBoolAndArgumentsColumns;
(function (CompanySelectColumnPlCompanyAggregateBoolExpBoolAndArgumentsColumns) {
    /** column name */
    CompanySelectColumnPlCompanyAggregateBoolExpBoolAndArgumentsColumns["LogoUploaded"] = "logo_uploaded";
    /** column name */
    CompanySelectColumnPlCompanyAggregateBoolExpBoolAndArgumentsColumns["SsoEnterprise"] = "sso_enterprise";
})(CompanySelectColumnPlCompanyAggregateBoolExpBoolAndArgumentsColumns || (CompanySelectColumnPlCompanyAggregateBoolExpBoolAndArgumentsColumns = {}));
/** select "pl_company_aggregate_bool_exp_bool_or_arguments_columns" columns of table "pl.company" */
export var CompanySelectColumnPlCompanyAggregateBoolExpBoolOrArgumentsColumns;
(function (CompanySelectColumnPlCompanyAggregateBoolExpBoolOrArgumentsColumns) {
    /** column name */
    CompanySelectColumnPlCompanyAggregateBoolExpBoolOrArgumentsColumns["LogoUploaded"] = "logo_uploaded";
    /** column name */
    CompanySelectColumnPlCompanyAggregateBoolExpBoolOrArgumentsColumns["SsoEnterprise"] = "sso_enterprise";
})(CompanySelectColumnPlCompanyAggregateBoolExpBoolOrArgumentsColumns || (CompanySelectColumnPlCompanyAggregateBoolExpBoolOrArgumentsColumns = {}));
/** update columns of table "pl.company" */
export var CompanyUpdateColumn;
(function (CompanyUpdateColumn) {
    /** column name */
    CompanyUpdateColumn["AddressCity"] = "address_city";
    /** column name */
    CompanyUpdateColumn["AddressCountry"] = "address_country";
    /** column name */
    CompanyUpdateColumn["AddressStreet"] = "address_street";
    /** column name */
    CompanyUpdateColumn["AddressZip"] = "address_zip";
    /** column name */
    CompanyUpdateColumn["AnnualRevenueRange"] = "annual_revenue_range";
    /** column name */
    CompanyUpdateColumn["BusinessType"] = "business_type";
    /** column name */
    CompanyUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    CompanyUpdateColumn["EmployeeCount"] = "employee_count";
    /** column name */
    CompanyUpdateColumn["Id"] = "id";
    /** column name */
    CompanyUpdateColumn["Industry"] = "industry";
    /** column name */
    CompanyUpdateColumn["IndustryOther"] = "industry_other";
    /** column name */
    CompanyUpdateColumn["LogoPath"] = "logo_path";
    /** column name */
    CompanyUpdateColumn["LogoUploaded"] = "logo_uploaded";
    /** column name */
    CompanyUpdateColumn["MarketCode"] = "market_code";
    /** column name */
    CompanyUpdateColumn["Name"] = "name";
    /** column name */
    CompanyUpdateColumn["OrganizationNumber"] = "organization_number";
    /** column name */
    CompanyUpdateColumn["PreferredUsage"] = "preferred_usage";
    /** column name */
    CompanyUpdateColumn["ReferrerId"] = "referrer_id";
    /** column name */
    CompanyUpdateColumn["SsoEnterprise"] = "sso_enterprise";
    /** column name */
    CompanyUpdateColumn["SsoEnterpriseSamlpConnectionId"] = "sso_enterprise_samlp_connection_id";
    /** column name */
    CompanyUpdateColumn["StripeCustomerId"] = "stripe_customer_id";
    /** column name */
    CompanyUpdateColumn["UpdatedAt"] = "updated_at";
    /** column name */
    CompanyUpdateColumn["WebDomain"] = "web_domain";
    /** column name */
    CompanyUpdateColumn["YearFounded"] = "year_founded";
})(CompanyUpdateColumn || (CompanyUpdateColumn = {}));
/** unique or primary key constraints on table "pl.company_user" */
export var CompanyUserConstraint;
(function (CompanyUserConstraint) {
    /** unique or primary key constraint on columns "user_id", "company_id" */
    CompanyUserConstraint["CompanyUserCompanyIdUserIdKey"] = "company_user_company_id_user_id_key";
    /** unique or primary key constraint on columns "id" */
    CompanyUserConstraint["CompanyUserPkey"] = "company_user_pkey";
})(CompanyUserConstraint || (CompanyUserConstraint = {}));
/** unique or primary key constraints on table "pl.company_user_job_title_enum" */
export var CompanyUserJobTitleEnumConstraint;
(function (CompanyUserJobTitleEnumConstraint) {
    /** unique or primary key constraint on columns "name" */
    CompanyUserJobTitleEnumConstraint["CompanyUserJobTitleEnumPkey"] = "company_user_job_title_enum_pkey";
})(CompanyUserJobTitleEnumConstraint || (CompanyUserJobTitleEnumConstraint = {}));
export var CompanyUserJobTitleEnumEnum;
(function (CompanyUserJobTitleEnumEnum) {
    /** Other */
    CompanyUserJobTitleEnumEnum["Other"] = "_other";
    /** CEO */
    CompanyUserJobTitleEnumEnum["Ceo"] = "ceo";
    /** Finance */
    CompanyUserJobTitleEnumEnum["Finance"] = "finance";
    /** Founder */
    CompanyUserJobTitleEnumEnum["Founder"] = "founder";
    /** General Management */
    CompanyUserJobTitleEnumEnum["GeneralManagement"] = "general_management";
    /** Human Resources */
    CompanyUserJobTitleEnumEnum["HumanResources"] = "human_resources";
    /** Legal and Compliance */
    CompanyUserJobTitleEnumEnum["LegalCompliance"] = "legal_compliance";
    /** Marketing */
    CompanyUserJobTitleEnumEnum["Marketing"] = "marketing";
    /** Operations */
    CompanyUserJobTitleEnumEnum["Operations"] = "operations";
    /** Product/Tech/R&D */
    CompanyUserJobTitleEnumEnum["ProductTechRnd"] = "product_tech_rnd";
    /** Sales */
    CompanyUserJobTitleEnumEnum["Sales"] = "sales";
})(CompanyUserJobTitleEnumEnum || (CompanyUserJobTitleEnumEnum = {}));
/** select columns of table "pl.company_user_job_title_enum" */
export var CompanyUserJobTitleEnumSelectColumn;
(function (CompanyUserJobTitleEnumSelectColumn) {
    /** column name */
    CompanyUserJobTitleEnumSelectColumn["Description"] = "description";
    /** column name */
    CompanyUserJobTitleEnumSelectColumn["Name"] = "name";
})(CompanyUserJobTitleEnumSelectColumn || (CompanyUserJobTitleEnumSelectColumn = {}));
/** update columns of table "pl.company_user_job_title_enum" */
export var CompanyUserJobTitleEnumUpdateColumn;
(function (CompanyUserJobTitleEnumUpdateColumn) {
    /** column name */
    CompanyUserJobTitleEnumUpdateColumn["Description"] = "description";
    /** column name */
    CompanyUserJobTitleEnumUpdateColumn["Name"] = "name";
})(CompanyUserJobTitleEnumUpdateColumn || (CompanyUserJobTitleEnumUpdateColumn = {}));
/** select columns of table "pl.company_user" */
export var CompanyUserSelectColumn;
(function (CompanyUserSelectColumn) {
    /** column name */
    CompanyUserSelectColumn["CanManageMember"] = "can_manage_member";
    /** column name */
    CompanyUserSelectColumn["CompanyId"] = "company_id";
    /** column name */
    CompanyUserSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    CompanyUserSelectColumn["Id"] = "id";
    /** column name */
    CompanyUserSelectColumn["JobTitle"] = "job_title";
    /** column name */
    CompanyUserSelectColumn["JobTitleOther"] = "job_title_other";
    /** column name */
    CompanyUserSelectColumn["Role"] = "role";
    /** column name */
    CompanyUserSelectColumn["UpdatedAt"] = "updated_at";
    /** column name */
    CompanyUserSelectColumn["UserId"] = "user_id";
})(CompanyUserSelectColumn || (CompanyUserSelectColumn = {}));
/** select "pl_company_user_aggregate_bool_exp_bool_and_arguments_columns" columns of table "pl.company_user" */
export var CompanyUserSelectColumnPlCompanyUserAggregateBoolExpBoolAndArgumentsColumns;
(function (CompanyUserSelectColumnPlCompanyUserAggregateBoolExpBoolAndArgumentsColumns) {
    /** column name */
    CompanyUserSelectColumnPlCompanyUserAggregateBoolExpBoolAndArgumentsColumns["CanManageMember"] = "can_manage_member";
})(CompanyUserSelectColumnPlCompanyUserAggregateBoolExpBoolAndArgumentsColumns || (CompanyUserSelectColumnPlCompanyUserAggregateBoolExpBoolAndArgumentsColumns = {}));
/** select "pl_company_user_aggregate_bool_exp_bool_or_arguments_columns" columns of table "pl.company_user" */
export var CompanyUserSelectColumnPlCompanyUserAggregateBoolExpBoolOrArgumentsColumns;
(function (CompanyUserSelectColumnPlCompanyUserAggregateBoolExpBoolOrArgumentsColumns) {
    /** column name */
    CompanyUserSelectColumnPlCompanyUserAggregateBoolExpBoolOrArgumentsColumns["CanManageMember"] = "can_manage_member";
})(CompanyUserSelectColumnPlCompanyUserAggregateBoolExpBoolOrArgumentsColumns || (CompanyUserSelectColumnPlCompanyUserAggregateBoolExpBoolOrArgumentsColumns = {}));
/** update columns of table "pl.company_user" */
export var CompanyUserUpdateColumn;
(function (CompanyUserUpdateColumn) {
    /** column name */
    CompanyUserUpdateColumn["CanManageMember"] = "can_manage_member";
    /** column name */
    CompanyUserUpdateColumn["CompanyId"] = "company_id";
    /** column name */
    CompanyUserUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    CompanyUserUpdateColumn["Id"] = "id";
    /** column name */
    CompanyUserUpdateColumn["JobTitle"] = "job_title";
    /** column name */
    CompanyUserUpdateColumn["JobTitleOther"] = "job_title_other";
    /** column name */
    CompanyUserUpdateColumn["Role"] = "role";
    /** column name */
    CompanyUserUpdateColumn["UpdatedAt"] = "updated_at";
    /** column name */
    CompanyUserUpdateColumn["UserId"] = "user_id";
})(CompanyUserUpdateColumn || (CompanyUserUpdateColumn = {}));
/** select columns of table "pl.company_users_view" */
export var CompanyUsersViewSelectColumn;
(function (CompanyUsersViewSelectColumn) {
    /** column name */
    CompanyUsersViewSelectColumn["CompanyId"] = "company_id";
    /** column name */
    CompanyUsersViewSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    CompanyUsersViewSelectColumn["Email"] = "email";
    /** column name */
    CompanyUsersViewSelectColumn["Id"] = "id";
    /** column name */
    CompanyUsersViewSelectColumn["Name"] = "name";
    /** column name */
    CompanyUsersViewSelectColumn["UpdatedAt"] = "updated_at";
})(CompanyUsersViewSelectColumn || (CompanyUsersViewSelectColumn = {}));
/** unique or primary key constraints on table "pl.composer" */
export var ComposerConstraint;
(function (ComposerConstraint) {
    /** unique or primary key constraint on columns "id" */
    ComposerConstraint["ComposerPkey"] = "composer_pkey";
    /** unique or primary key constraint on columns "typeform_id" */
    ComposerConstraint["ComposerTypeformIdKey"] = "composer_typeform_id_key";
})(ComposerConstraint || (ComposerConstraint = {}));
/** unique or primary key constraints on table "pl.composer_metadata" */
export var ComposerMetadataConstraint;
(function (ComposerMetadataConstraint) {
    /** unique or primary key constraint on columns "composer_id", "market_locale_code" */
    ComposerMetadataConstraint["ComposerMetadataComposerIdMarketLocaleCodeKey"] = "composer_metadata_composer_id_market_locale_code_key";
    /** unique or primary key constraint on columns "id" */
    ComposerMetadataConstraint["ComposerMetadataPkey"] = "composer_metadata_pkey";
})(ComposerMetadataConstraint || (ComposerMetadataConstraint = {}));
/** select columns of table "pl.composer_metadata" */
export var ComposerMetadataSelectColumn;
(function (ComposerMetadataSelectColumn) {
    /** column name */
    ComposerMetadataSelectColumn["ComposerId"] = "composer_id";
    /** column name */
    ComposerMetadataSelectColumn["Description"] = "description";
    /** column name */
    ComposerMetadataSelectColumn["Id"] = "id";
    /** column name */
    ComposerMetadataSelectColumn["MarketCode"] = "market_code";
    /** column name */
    ComposerMetadataSelectColumn["MarketLocaleCode"] = "market_locale_code";
    /** column name */
    ComposerMetadataSelectColumn["Name"] = "name";
})(ComposerMetadataSelectColumn || (ComposerMetadataSelectColumn = {}));
/** update columns of table "pl.composer_metadata" */
export var ComposerMetadataUpdateColumn;
(function (ComposerMetadataUpdateColumn) {
    /** column name */
    ComposerMetadataUpdateColumn["ComposerId"] = "composer_id";
    /** column name */
    ComposerMetadataUpdateColumn["Description"] = "description";
    /** column name */
    ComposerMetadataUpdateColumn["Id"] = "id";
    /** column name */
    ComposerMetadataUpdateColumn["MarketCode"] = "market_code";
    /** column name */
    ComposerMetadataUpdateColumn["MarketLocaleCode"] = "market_locale_code";
    /** column name */
    ComposerMetadataUpdateColumn["Name"] = "name";
})(ComposerMetadataUpdateColumn || (ComposerMetadataUpdateColumn = {}));
/** unique or primary key constraints on table "pl.composer_plan" */
export var ComposerPlanConstraint;
(function (ComposerPlanConstraint) {
    /** unique or primary key constraint on columns "id" */
    ComposerPlanConstraint["ComposerPlanPkey"] = "composer_plan_pkey";
    /** unique or primary key constraint on columns "composer_id", "plan_id" */
    ComposerPlanConstraint["ComposerPlanPlanIdComposerIdKey"] = "composer_plan_plan_id_composer_id_key";
    /** unique or primary key constraint on columns "id" */
    ComposerPlanConstraint["ComposerPlanUdKey"] = "composer_plan_ud_key";
})(ComposerPlanConstraint || (ComposerPlanConstraint = {}));
/** select columns of table "pl.composer_plan" */
export var ComposerPlanSelectColumn;
(function (ComposerPlanSelectColumn) {
    /** column name */
    ComposerPlanSelectColumn["ComposerId"] = "composer_id";
    /** column name */
    ComposerPlanSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    ComposerPlanSelectColumn["Id"] = "id";
    /** column name */
    ComposerPlanSelectColumn["PlanId"] = "plan_id";
    /** column name */
    ComposerPlanSelectColumn["UpdatedAt"] = "updated_at";
})(ComposerPlanSelectColumn || (ComposerPlanSelectColumn = {}));
/** update columns of table "pl.composer_plan" */
export var ComposerPlanUpdateColumn;
(function (ComposerPlanUpdateColumn) {
    /** column name */
    ComposerPlanUpdateColumn["ComposerId"] = "composer_id";
    /** column name */
    ComposerPlanUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    ComposerPlanUpdateColumn["Id"] = "id";
    /** column name */
    ComposerPlanUpdateColumn["PlanId"] = "plan_id";
    /** column name */
    ComposerPlanUpdateColumn["UpdatedAt"] = "updated_at";
})(ComposerPlanUpdateColumn || (ComposerPlanUpdateColumn = {}));
/** select columns of table "pl.composer" */
export var ComposerSelectColumn;
(function (ComposerSelectColumn) {
    /** column name */
    ComposerSelectColumn["CategoryId"] = "category_id";
    /** column name */
    ComposerSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    ComposerSelectColumn["Enabled"] = "enabled";
    /** column name */
    ComposerSelectColumn["EstimatedTime"] = "estimated_time";
    /** column name */
    ComposerSelectColumn["Id"] = "id";
    /** column name */
    ComposerSelectColumn["IsTemplateKeyExcludeListEnabled"] = "is_template_key_exclude_list_enabled";
    /** column name */
    ComposerSelectColumn["MarketCode"] = "market_code";
    /** column name */
    ComposerSelectColumn["MarketLocaleCode"] = "market_locale_code";
    /** column name */
    ComposerSelectColumn["MarketLocaleId"] = "market_locale_id";
    /** column name */
    ComposerSelectColumn["StripeSkuId"] = "stripe_sku_id";
    /** column name */
    ComposerSelectColumn["TopicId"] = "topic_id";
    /** column name */
    ComposerSelectColumn["TypeformId"] = "typeform_id";
    /** column name */
    ComposerSelectColumn["UpdatedAt"] = "updated_at";
})(ComposerSelectColumn || (ComposerSelectColumn = {}));
/** select "pl_composer_aggregate_bool_exp_bool_and_arguments_columns" columns of table "pl.composer" */
export var ComposerSelectColumnPlComposerAggregateBoolExpBoolAndArgumentsColumns;
(function (ComposerSelectColumnPlComposerAggregateBoolExpBoolAndArgumentsColumns) {
    /** column name */
    ComposerSelectColumnPlComposerAggregateBoolExpBoolAndArgumentsColumns["Enabled"] = "enabled";
    /** column name */
    ComposerSelectColumnPlComposerAggregateBoolExpBoolAndArgumentsColumns["IsTemplateKeyExcludeListEnabled"] = "is_template_key_exclude_list_enabled";
})(ComposerSelectColumnPlComposerAggregateBoolExpBoolAndArgumentsColumns || (ComposerSelectColumnPlComposerAggregateBoolExpBoolAndArgumentsColumns = {}));
/** select "pl_composer_aggregate_bool_exp_bool_or_arguments_columns" columns of table "pl.composer" */
export var ComposerSelectColumnPlComposerAggregateBoolExpBoolOrArgumentsColumns;
(function (ComposerSelectColumnPlComposerAggregateBoolExpBoolOrArgumentsColumns) {
    /** column name */
    ComposerSelectColumnPlComposerAggregateBoolExpBoolOrArgumentsColumns["Enabled"] = "enabled";
    /** column name */
    ComposerSelectColumnPlComposerAggregateBoolExpBoolOrArgumentsColumns["IsTemplateKeyExcludeListEnabled"] = "is_template_key_exclude_list_enabled";
})(ComposerSelectColumnPlComposerAggregateBoolExpBoolOrArgumentsColumns || (ComposerSelectColumnPlComposerAggregateBoolExpBoolOrArgumentsColumns = {}));
/** unique or primary key constraints on table "pl.composer_template" */
export var ComposerTemplateConstraint;
(function (ComposerTemplateConstraint) {
    /** unique or primary key constraint on columns "composer_id", "template_id" */
    ComposerTemplateConstraint["ComposerTemplateComposerIdTemplateIdKey"] = "composer_template_composer_id_template_id_key";
    /** unique or primary key constraint on columns "id" */
    ComposerTemplateConstraint["ComposerTemplatePkey"] = "composer_template_pkey";
    /** unique or primary key constraint on columns "template_id" */
    ComposerTemplateConstraint["ComposerTemplateTemplateIdKey"] = "composer_template_template_id_key";
})(ComposerTemplateConstraint || (ComposerTemplateConstraint = {}));
/** unique or primary key constraints on table "pl.composer_template_key_exclude_list" */
export var ComposerTemplateKeyExcludeListConstraint;
(function (ComposerTemplateKeyExcludeListConstraint) {
    /** unique or primary key constraint on columns "id" */
    ComposerTemplateKeyExcludeListConstraint["ComposerTemplateKeyExcludeListPkey"] = "composer_template_key_exclude_list_pkey";
})(ComposerTemplateKeyExcludeListConstraint || (ComposerTemplateKeyExcludeListConstraint = {}));
/** select columns of table "pl.composer_template_key_exclude_list" */
export var ComposerTemplateKeyExcludeListSelectColumn;
(function (ComposerTemplateKeyExcludeListSelectColumn) {
    /** column name */
    ComposerTemplateKeyExcludeListSelectColumn["ComposerId"] = "composer_id";
    /** column name */
    ComposerTemplateKeyExcludeListSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    ComposerTemplateKeyExcludeListSelectColumn["ExcludeKey"] = "exclude_key";
    /** column name */
    ComposerTemplateKeyExcludeListSelectColumn["Id"] = "id";
    /** column name */
    ComposerTemplateKeyExcludeListSelectColumn["UpdatedAt"] = "updated_at";
})(ComposerTemplateKeyExcludeListSelectColumn || (ComposerTemplateKeyExcludeListSelectColumn = {}));
/** update columns of table "pl.composer_template_key_exclude_list" */
export var ComposerTemplateKeyExcludeListUpdateColumn;
(function (ComposerTemplateKeyExcludeListUpdateColumn) {
    /** column name */
    ComposerTemplateKeyExcludeListUpdateColumn["ComposerId"] = "composer_id";
    /** column name */
    ComposerTemplateKeyExcludeListUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    ComposerTemplateKeyExcludeListUpdateColumn["ExcludeKey"] = "exclude_key";
    /** column name */
    ComposerTemplateKeyExcludeListUpdateColumn["Id"] = "id";
    /** column name */
    ComposerTemplateKeyExcludeListUpdateColumn["UpdatedAt"] = "updated_at";
})(ComposerTemplateKeyExcludeListUpdateColumn || (ComposerTemplateKeyExcludeListUpdateColumn = {}));
/** select columns of table "pl.composer_template" */
export var ComposerTemplateSelectColumn;
(function (ComposerTemplateSelectColumn) {
    /** column name */
    ComposerTemplateSelectColumn["ComposerId"] = "composer_id";
    /** column name */
    ComposerTemplateSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    ComposerTemplateSelectColumn["Id"] = "id";
    /** column name */
    ComposerTemplateSelectColumn["TemplateId"] = "template_id";
    /** column name */
    ComposerTemplateSelectColumn["UpdatedAt"] = "updated_at";
})(ComposerTemplateSelectColumn || (ComposerTemplateSelectColumn = {}));
/** update columns of table "pl.composer_template" */
export var ComposerTemplateUpdateColumn;
(function (ComposerTemplateUpdateColumn) {
    /** column name */
    ComposerTemplateUpdateColumn["ComposerId"] = "composer_id";
    /** column name */
    ComposerTemplateUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    ComposerTemplateUpdateColumn["Id"] = "id";
    /** column name */
    ComposerTemplateUpdateColumn["TemplateId"] = "template_id";
    /** column name */
    ComposerTemplateUpdateColumn["UpdatedAt"] = "updated_at";
})(ComposerTemplateUpdateColumn || (ComposerTemplateUpdateColumn = {}));
/** update columns of table "pl.composer" */
export var ComposerUpdateColumn;
(function (ComposerUpdateColumn) {
    /** column name */
    ComposerUpdateColumn["CategoryId"] = "category_id";
    /** column name */
    ComposerUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    ComposerUpdateColumn["Enabled"] = "enabled";
    /** column name */
    ComposerUpdateColumn["EstimatedTime"] = "estimated_time";
    /** column name */
    ComposerUpdateColumn["Id"] = "id";
    /** column name */
    ComposerUpdateColumn["IsTemplateKeyExcludeListEnabled"] = "is_template_key_exclude_list_enabled";
    /** column name */
    ComposerUpdateColumn["MarketCode"] = "market_code";
    /** column name */
    ComposerUpdateColumn["MarketLocaleCode"] = "market_locale_code";
    /** column name */
    ComposerUpdateColumn["MarketLocaleId"] = "market_locale_id";
    /** column name */
    ComposerUpdateColumn["StripeSkuId"] = "stripe_sku_id";
    /** column name */
    ComposerUpdateColumn["TopicId"] = "topic_id";
    /** column name */
    ComposerUpdateColumn["TypeformId"] = "typeform_id";
    /** column name */
    ComposerUpdateColumn["UpdatedAt"] = "updated_at";
})(ComposerUpdateColumn || (ComposerUpdateColumn = {}));
/** unique or primary key constraints on table "pl.composer_value" */
export var ComposerValueConstraint;
(function (ComposerValueConstraint) {
    /** unique or primary key constraint on columns "template_key", "value_hash", "composer_id", "user_id", "market_locale_code", "market_code", "company_id" */
    ComposerValueConstraint["ColumnValueUnique"] = "column_value_unique";
    /** unique or primary key constraint on columns "id" */
    ComposerValueConstraint["ComposerValuePkey"] = "composer_value_pkey";
})(ComposerValueConstraint || (ComposerValueConstraint = {}));
/** select columns of table "pl.composer_value" */
export var ComposerValueSelectColumn;
(function (ComposerValueSelectColumn) {
    /** column name */
    ComposerValueSelectColumn["CompanyId"] = "company_id";
    /** column name */
    ComposerValueSelectColumn["ComposerId"] = "composer_id";
    /** column name */
    ComposerValueSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    ComposerValueSelectColumn["Id"] = "id";
    /** column name */
    ComposerValueSelectColumn["MarketCode"] = "market_code";
    /** column name */
    ComposerValueSelectColumn["MarketLocaleCode"] = "market_locale_code";
    /** column name */
    ComposerValueSelectColumn["TemplateKey"] = "template_key";
    /** column name */
    ComposerValueSelectColumn["UpdatedAt"] = "updated_at";
    /** column name */
    ComposerValueSelectColumn["UserId"] = "user_id";
    /** column name */
    ComposerValueSelectColumn["Value"] = "value";
    /** column name */
    ComposerValueSelectColumn["ValueHash"] = "value_hash";
})(ComposerValueSelectColumn || (ComposerValueSelectColumn = {}));
/** update columns of table "pl.composer_value" */
export var ComposerValueUpdateColumn;
(function (ComposerValueUpdateColumn) {
    /** column name */
    ComposerValueUpdateColumn["CompanyId"] = "company_id";
    /** column name */
    ComposerValueUpdateColumn["ComposerId"] = "composer_id";
    /** column name */
    ComposerValueUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    ComposerValueUpdateColumn["Id"] = "id";
    /** column name */
    ComposerValueUpdateColumn["MarketCode"] = "market_code";
    /** column name */
    ComposerValueUpdateColumn["MarketLocaleCode"] = "market_locale_code";
    /** column name */
    ComposerValueUpdateColumn["TemplateKey"] = "template_key";
    /** column name */
    ComposerValueUpdateColumn["UpdatedAt"] = "updated_at";
    /** column name */
    ComposerValueUpdateColumn["UserId"] = "user_id";
    /** column name */
    ComposerValueUpdateColumn["Value"] = "value";
    /** column name */
    ComposerValueUpdateColumn["ValueHash"] = "value_hash";
})(ComposerValueUpdateColumn || (ComposerValueUpdateColumn = {}));
/** https://stripe.com/docs/api/coupons/create#create_coupon-duration */
export var CouponDurationEnum;
(function (CouponDurationEnum) {
    CouponDurationEnum["Forever"] = "FOREVER";
    CouponDurationEnum["Once"] = "ONCE";
    CouponDurationEnum["Repeating"] = "REPEATING";
})(CouponDurationEnum || (CouponDurationEnum = {}));
/** ordering argument of a cursor */
export var CursorOrdering;
(function (CursorOrdering) {
    /** ascending ordering of the cursor */
    CursorOrdering["Asc"] = "ASC";
    /** descending ordering of the cursor */
    CursorOrdering["Desc"] = "DESC";
})(CursorOrdering || (CursorOrdering = {}));
export var DateFormat;
(function (DateFormat) {
    DateFormat["Ddmmyyyy"] = "DDMMYYYY";
    DateFormat["Mmddyyyy"] = "MMDDYYYY";
    DateFormat["Yyyymmdd"] = "YYYYMMDD";
})(DateFormat || (DateFormat = {}));
export var DateSeparator;
(function (DateSeparator) {
    DateSeparator["Dot"] = "DOT";
    DateSeparator["ForwardSlash"] = "FORWARD_SLASH";
    DateSeparator["Hyphen"] = "HYPHEN";
})(DateSeparator || (DateSeparator = {}));
/** unique or primary key constraints on table "pl.decision_tree" */
export var DecisionTreeConstraint;
(function (DecisionTreeConstraint) {
    /** unique or primary key constraint on columns "id" */
    DecisionTreeConstraint["DecisionTreePkey"] = "decision_tree_pkey";
    /** unique or primary key constraint on columns "tree_id" */
    DecisionTreeConstraint["DecisionTreeTreeIdKey"] = "decision_tree_tree_id_key";
})(DecisionTreeConstraint || (DecisionTreeConstraint = {}));
/** select columns of table "pl.decision_tree" */
export var DecisionTreeSelectColumn;
(function (DecisionTreeSelectColumn) {
    /** column name */
    DecisionTreeSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    DecisionTreeSelectColumn["Id"] = "id";
    /** column name */
    DecisionTreeSelectColumn["MarketCode"] = "market_code";
    /** column name */
    DecisionTreeSelectColumn["TreeId"] = "tree_id";
    /** column name */
    DecisionTreeSelectColumn["UpdatedAt"] = "updated_at";
})(DecisionTreeSelectColumn || (DecisionTreeSelectColumn = {}));
/** update columns of table "pl.decision_tree" */
export var DecisionTreeUpdateColumn;
(function (DecisionTreeUpdateColumn) {
    /** column name */
    DecisionTreeUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    DecisionTreeUpdateColumn["Id"] = "id";
    /** column name */
    DecisionTreeUpdateColumn["MarketCode"] = "market_code";
    /** column name */
    DecisionTreeUpdateColumn["TreeId"] = "tree_id";
    /** column name */
    DecisionTreeUpdateColumn["UpdatedAt"] = "updated_at";
})(DecisionTreeUpdateColumn || (DecisionTreeUpdateColumn = {}));
/** unique or primary key constraints on table "pl.decision_tree_version" */
export var DecisionTreeVersionConstraint;
(function (DecisionTreeVersionConstraint) {
    /** unique or primary key constraint on columns "revision", "decision_tree_id" */
    DecisionTreeVersionConstraint["DecisionTreeVersionPkey"] = "decision_tree_version_pkey";
})(DecisionTreeVersionConstraint || (DecisionTreeVersionConstraint = {}));
/** select columns of table "pl.decision_tree_version" */
export var DecisionTreeVersionSelectColumn;
(function (DecisionTreeVersionSelectColumn) {
    /** column name */
    DecisionTreeVersionSelectColumn["Body"] = "body";
    /** column name */
    DecisionTreeVersionSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    DecisionTreeVersionSelectColumn["DecisionTreeId"] = "decision_tree_id";
    /** column name */
    DecisionTreeVersionSelectColumn["Revision"] = "revision";
    /** column name */
    DecisionTreeVersionSelectColumn["UpdatedAt"] = "updated_at";
})(DecisionTreeVersionSelectColumn || (DecisionTreeVersionSelectColumn = {}));
/** unique or primary key constraints on table "pl.decision_tree_version_translation" */
export var DecisionTreeVersionTranslationConstraint;
(function (DecisionTreeVersionTranslationConstraint) {
    /** unique or primary key constraint on columns "decision_tree_version_revision", "decision_tree_id", "market_locale_code", "market_code" */
    DecisionTreeVersionTranslationConstraint["DecisionTreeVersionTranslationDecisionTreeIdDecisionTre"] = "decision_tree_version_translation_decision_tree_id_decision_tre";
    /** unique or primary key constraint on columns "id" */
    DecisionTreeVersionTranslationConstraint["DecisionTreeVersionTranslationPkey"] = "decision_tree_version_translation_pkey";
})(DecisionTreeVersionTranslationConstraint || (DecisionTreeVersionTranslationConstraint = {}));
/** select columns of table "pl.decision_tree_version_translation" */
export var DecisionTreeVersionTranslationSelectColumn;
(function (DecisionTreeVersionTranslationSelectColumn) {
    /** column name */
    DecisionTreeVersionTranslationSelectColumn["Body"] = "body";
    /** column name */
    DecisionTreeVersionTranslationSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    DecisionTreeVersionTranslationSelectColumn["DecisionTreeId"] = "decision_tree_id";
    /** column name */
    DecisionTreeVersionTranslationSelectColumn["DecisionTreeVersionRevision"] = "decision_tree_version_revision";
    /** column name */
    DecisionTreeVersionTranslationSelectColumn["Id"] = "id";
    /** column name */
    DecisionTreeVersionTranslationSelectColumn["MarketCode"] = "market_code";
    /** column name */
    DecisionTreeVersionTranslationSelectColumn["MarketLocaleCode"] = "market_locale_code";
    /** column name */
    DecisionTreeVersionTranslationSelectColumn["UpdatedAt"] = "updated_at";
    /** column name */
    DecisionTreeVersionTranslationSelectColumn["Version"] = "version";
})(DecisionTreeVersionTranslationSelectColumn || (DecisionTreeVersionTranslationSelectColumn = {}));
/** update columns of table "pl.decision_tree_version_translation" */
export var DecisionTreeVersionTranslationUpdateColumn;
(function (DecisionTreeVersionTranslationUpdateColumn) {
    /** column name */
    DecisionTreeVersionTranslationUpdateColumn["Body"] = "body";
    /** column name */
    DecisionTreeVersionTranslationUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    DecisionTreeVersionTranslationUpdateColumn["DecisionTreeId"] = "decision_tree_id";
    /** column name */
    DecisionTreeVersionTranslationUpdateColumn["DecisionTreeVersionRevision"] = "decision_tree_version_revision";
    /** column name */
    DecisionTreeVersionTranslationUpdateColumn["Id"] = "id";
    /** column name */
    DecisionTreeVersionTranslationUpdateColumn["MarketCode"] = "market_code";
    /** column name */
    DecisionTreeVersionTranslationUpdateColumn["MarketLocaleCode"] = "market_locale_code";
    /** column name */
    DecisionTreeVersionTranslationUpdateColumn["UpdatedAt"] = "updated_at";
    /** column name */
    DecisionTreeVersionTranslationUpdateColumn["Version"] = "version";
})(DecisionTreeVersionTranslationUpdateColumn || (DecisionTreeVersionTranslationUpdateColumn = {}));
/** update columns of table "pl.decision_tree_version" */
export var DecisionTreeVersionUpdateColumn;
(function (DecisionTreeVersionUpdateColumn) {
    /** column name */
    DecisionTreeVersionUpdateColumn["Body"] = "body";
    /** column name */
    DecisionTreeVersionUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    DecisionTreeVersionUpdateColumn["DecisionTreeId"] = "decision_tree_id";
    /** column name */
    DecisionTreeVersionUpdateColumn["Revision"] = "revision";
    /** column name */
    DecisionTreeVersionUpdateColumn["UpdatedAt"] = "updated_at";
})(DecisionTreeVersionUpdateColumn || (DecisionTreeVersionUpdateColumn = {}));
/** unique or primary key constraints on table "pl.diagnosis" */
export var DiagnosisConstraint;
(function (DiagnosisConstraint) {
    /** unique or primary key constraint on columns "id" */
    DiagnosisConstraint["DiagnosisPkey"] = "diagnosis_pkey";
})(DiagnosisConstraint || (DiagnosisConstraint = {}));
/** select columns of table "pl.diagnosis" */
export var DiagnosisSelectColumn;
(function (DiagnosisSelectColumn) {
    /** column name */
    DiagnosisSelectColumn["CompanyId"] = "company_id";
    /** column name */
    DiagnosisSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    DiagnosisSelectColumn["DeletedAt"] = "deleted_at";
    /** column name */
    DiagnosisSelectColumn["GuidanceDiagnosisId"] = "guidance_diagnosis_id";
    /** column name */
    DiagnosisSelectColumn["GuideId"] = "guide_id";
    /** column name */
    DiagnosisSelectColumn["Id"] = "id";
    /** column name */
    DiagnosisSelectColumn["Result"] = "result";
    /** column name */
    DiagnosisSelectColumn["Status"] = "status";
    /** column name */
    DiagnosisSelectColumn["UpdatedAt"] = "updated_at";
    /** column name */
    DiagnosisSelectColumn["UserId"] = "user_id";
    /** column name */
    DiagnosisSelectColumn["Version"] = "version";
})(DiagnosisSelectColumn || (DiagnosisSelectColumn = {}));
/** update columns of table "pl.diagnosis" */
export var DiagnosisUpdateColumn;
(function (DiagnosisUpdateColumn) {
    /** column name */
    DiagnosisUpdateColumn["CompanyId"] = "company_id";
    /** column name */
    DiagnosisUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    DiagnosisUpdateColumn["DeletedAt"] = "deleted_at";
    /** column name */
    DiagnosisUpdateColumn["GuidanceDiagnosisId"] = "guidance_diagnosis_id";
    /** column name */
    DiagnosisUpdateColumn["GuideId"] = "guide_id";
    /** column name */
    DiagnosisUpdateColumn["Id"] = "id";
    /** column name */
    DiagnosisUpdateColumn["Result"] = "result";
    /** column name */
    DiagnosisUpdateColumn["Status"] = "status";
    /** column name */
    DiagnosisUpdateColumn["UpdatedAt"] = "updated_at";
    /** column name */
    DiagnosisUpdateColumn["UserId"] = "user_id";
    /** column name */
    DiagnosisUpdateColumn["Version"] = "version";
})(DiagnosisUpdateColumn || (DiagnosisUpdateColumn = {}));
export var DisableEsigningFeatureRejectionReason;
(function (DisableEsigningFeatureRejectionReason) {
    DisableEsigningFeatureRejectionReason["NotAuthorized"] = "NOT_AUTHORIZED";
})(DisableEsigningFeatureRejectionReason || (DisableEsigningFeatureRejectionReason = {}));
export var DocumentAiDisabledReason;
(function (DocumentAiDisabledReason) {
    DocumentAiDisabledReason["TextExtractionFailed"] = "TEXT_EXTRACTION_FAILED";
    DocumentAiDisabledReason["TextExtractionPending"] = "TEXT_EXTRACTION_PENDING";
})(DocumentAiDisabledReason || (DocumentAiDisabledReason = {}));
export var DocumentMetadataAiAutoTaggingQueueEntryStatus;
(function (DocumentMetadataAiAutoTaggingQueueEntryStatus) {
    DocumentMetadataAiAutoTaggingQueueEntryStatus["Canceled"] = "CANCELED";
    DocumentMetadataAiAutoTaggingQueueEntryStatus["Delayed"] = "DELAYED";
    DocumentMetadataAiAutoTaggingQueueEntryStatus["Failed"] = "FAILED";
    DocumentMetadataAiAutoTaggingQueueEntryStatus["InProgress"] = "IN_PROGRESS";
    DocumentMetadataAiAutoTaggingQueueEntryStatus["Pending"] = "PENDING";
    DocumentMetadataAiAutoTaggingQueueEntryStatus["Success"] = "SUCCESS";
})(DocumentMetadataAiAutoTaggingQueueEntryStatus || (DocumentMetadataAiAutoTaggingQueueEntryStatus = {}));
export var DocumentMetadataFeature;
(function (DocumentMetadataFeature) {
    DocumentMetadataFeature["AiAutoTagging"] = "AI_AUTO_TAGGING";
})(DocumentMetadataFeature || (DocumentMetadataFeature = {}));
export var DocumentMetadataPropertyReferenceEnum;
(function (DocumentMetadataPropertyReferenceEnum) {
    DocumentMetadataPropertyReferenceEnum["FileSystemDocument"] = "fileSystemDocument";
    DocumentMetadataPropertyReferenceEnum["FileSystemEntry"] = "fileSystemEntry";
    DocumentMetadataPropertyReferenceEnum["FileSystemFolder"] = "fileSystemFolder";
    DocumentMetadataPropertyReferenceEnum["User"] = "user";
})(DocumentMetadataPropertyReferenceEnum || (DocumentMetadataPropertyReferenceEnum = {}));
export var DocumentMetadataPropertySetterEnum;
(function (DocumentMetadataPropertySetterEnum) {
    DocumentMetadataPropertySetterEnum["Ai"] = "AI";
    DocumentMetadataPropertySetterEnum["System"] = "SYSTEM";
    DocumentMetadataPropertySetterEnum["User"] = "USER";
})(DocumentMetadataPropertySetterEnum || (DocumentMetadataPropertySetterEnum = {}));
export var DocumentMetadataPropertyTypeEnum;
(function (DocumentMetadataPropertyTypeEnum) {
    DocumentMetadataPropertyTypeEnum["Boolean"] = "boolean";
    DocumentMetadataPropertyTypeEnum["Date"] = "date";
    DocumentMetadataPropertyTypeEnum["Datetime"] = "datetime";
    DocumentMetadataPropertyTypeEnum["List"] = "list";
    DocumentMetadataPropertyTypeEnum["MultiLineText"] = "multiLineText";
    DocumentMetadataPropertyTypeEnum["Number"] = "number";
    DocumentMetadataPropertyTypeEnum["Reference"] = "reference";
    DocumentMetadataPropertyTypeEnum["SimpleText"] = "simpleText";
})(DocumentMetadataPropertyTypeEnum || (DocumentMetadataPropertyTypeEnum = {}));
export var DocumentMetadataPropertyUiWidgetHintEnum;
(function (DocumentMetadataPropertyUiWidgetHintEnum) {
    DocumentMetadataPropertyUiWidgetHintEnum["DatePicker"] = "DatePicker";
    DocumentMetadataPropertyUiWidgetHintEnum["DateTimePicker"] = "DateTimePicker";
    DocumentMetadataPropertyUiWidgetHintEnum["InputNumber"] = "InputNumber";
    DocumentMetadataPropertyUiWidgetHintEnum["InputText"] = "InputText";
    DocumentMetadataPropertyUiWidgetHintEnum["SelectMulti"] = "SelectMulti";
    DocumentMetadataPropertyUiWidgetHintEnum["SelectSingle"] = "SelectSingle";
    DocumentMetadataPropertyUiWidgetHintEnum["SelectWithInput"] = "SelectWithInput";
    DocumentMetadataPropertyUiWidgetHintEnum["Switch"] = "Switch";
    DocumentMetadataPropertyUiWidgetHintEnum["TextArea"] = "TextArea";
})(DocumentMetadataPropertyUiWidgetHintEnum || (DocumentMetadataPropertyUiWidgetHintEnum = {}));
export var DocumentType;
(function (DocumentType) {
    DocumentType["Composed"] = "COMPOSED";
    DocumentType["Freetext"] = "FREETEXT";
    DocumentType["Unknown"] = "UNKNOWN";
    DocumentType["Uploaded"] = "UPLOADED";
})(DocumentType || (DocumentType = {}));
export var DocumentUploadJobStatus;
(function (DocumentUploadJobStatus) {
    DocumentUploadJobStatus["Done"] = "DONE";
    DocumentUploadJobStatus["Failed"] = "FAILED";
    DocumentUploadJobStatus["Pending"] = "PENDING";
    DocumentUploadJobStatus["Processing"] = "PROCESSING";
})(DocumentUploadJobStatus || (DocumentUploadJobStatus = {}));
/** unique or primary key constraints on table "pl.draft" */
export var DraftConstraint;
(function (DraftConstraint) {
    /** unique or primary key constraint on columns "id" */
    DraftConstraint["DraftPkey"] = "draft_pkey";
})(DraftConstraint || (DraftConstraint = {}));
/** select columns of table "pl.draft" */
export var DraftSelectColumn;
(function (DraftSelectColumn) {
    /** column name */
    DraftSelectColumn["CompanyId"] = "company_id";
    /** column name */
    DraftSelectColumn["ComposerId"] = "composer_id";
    /** column name */
    DraftSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    DraftSelectColumn["Id"] = "id";
    /** column name */
    DraftSelectColumn["Result"] = "result";
    /** column name */
    DraftSelectColumn["TemplateId"] = "template_id";
    /** column name */
    DraftSelectColumn["TemplateVersionReference"] = "template_version_reference";
    /** column name */
    DraftSelectColumn["TemplateVersionTemplateId"] = "template_version_template_id";
    /** column name */
    DraftSelectColumn["UpdatedAt"] = "updated_at";
    /** column name */
    DraftSelectColumn["UserId"] = "user_id";
    /** column name */
    DraftSelectColumn["Version"] = "version";
})(DraftSelectColumn || (DraftSelectColumn = {}));
/** update columns of table "pl.draft" */
export var DraftUpdateColumn;
(function (DraftUpdateColumn) {
    /** column name */
    DraftUpdateColumn["CompanyId"] = "company_id";
    /** column name */
    DraftUpdateColumn["ComposerId"] = "composer_id";
    /** column name */
    DraftUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    DraftUpdateColumn["Id"] = "id";
    /** column name */
    DraftUpdateColumn["Result"] = "result";
    /** column name */
    DraftUpdateColumn["TemplateId"] = "template_id";
    /** column name */
    DraftUpdateColumn["TemplateVersionReference"] = "template_version_reference";
    /** column name */
    DraftUpdateColumn["TemplateVersionTemplateId"] = "template_version_template_id";
    /** column name */
    DraftUpdateColumn["UpdatedAt"] = "updated_at";
    /** column name */
    DraftUpdateColumn["UserId"] = "user_id";
    /** column name */
    DraftUpdateColumn["Version"] = "version";
})(DraftUpdateColumn || (DraftUpdateColumn = {}));
export var EnableEsigningFeatureRejectionReason;
(function (EnableEsigningFeatureRejectionReason) {
    EnableEsigningFeatureRejectionReason["NotAuthorized"] = "NOT_AUTHORIZED";
})(EnableEsigningFeatureRejectionReason || (EnableEsigningFeatureRejectionReason = {}));
export var EntityType;
(function (EntityType) {
    EntityType["Attachment"] = "ATTACHMENT";
    EntityType["CompanyLogo"] = "COMPANY_LOGO";
    EntityType["Document"] = "DOCUMENT";
})(EntityType || (EntityType = {}));
export var EntryKind;
(function (EntryKind) {
    EntryKind["Document"] = "DOCUMENT";
    EntryKind["Entry"] = "ENTRY";
    EntryKind["Folder"] = "FOLDER";
    EntryKind["Guidance"] = "GUIDANCE";
})(EntryKind || (EntryKind = {}));
export var EsigningActivityCategory;
(function (EsigningActivityCategory) {
    EsigningActivityCategory["ApprovalRequest"] = "ApprovalRequest";
    EsigningActivityCategory["SignRequest"] = "SignRequest";
})(EsigningActivityCategory || (EsigningActivityCategory = {}));
export var EsigningAttachmentKind;
(function (EsigningAttachmentKind) {
    EsigningAttachmentKind["Default"] = "DEFAULT";
    EsigningAttachmentKind["Document"] = "DOCUMENT";
    EsigningAttachmentKind["SignRequest"] = "SIGN_REQUEST";
})(EsigningAttachmentKind || (EsigningAttachmentKind = {}));
export var EsigningFeatureSlug;
(function (EsigningFeatureSlug) {
    EsigningFeatureSlug["Approval"] = "APPROVAL";
    EsigningFeatureSlug["DefaultAttachments"] = "DEFAULT_ATTACHMENTS";
    EsigningFeatureSlug["DefaultSignatories"] = "DEFAULT_SIGNATORIES";
})(EsigningFeatureSlug || (EsigningFeatureSlug = {}));
/** select columns of table "pl.esigning_provider_authentication_method" */
export var EsigningProviderAuthenticationMethodSelectColumn;
(function (EsigningProviderAuthenticationMethodSelectColumn) {
    /** column name */
    EsigningProviderAuthenticationMethodSelectColumn["EsigningProviderId"] = "esigning_provider_id";
    /** column name */
    EsigningProviderAuthenticationMethodSelectColumn["Id"] = "id";
    /** column name */
    EsigningProviderAuthenticationMethodSelectColumn["Slug"] = "slug";
})(EsigningProviderAuthenticationMethodSelectColumn || (EsigningProviderAuthenticationMethodSelectColumn = {}));
export var EsigningProviderAuthenticationModeSlug;
(function (EsigningProviderAuthenticationModeSlug) {
    EsigningProviderAuthenticationModeSlug["Email"] = "EMAIL";
    EsigningProviderAuthenticationModeSlug["FinBankid"] = "FIN_BANKID";
    EsigningProviderAuthenticationModeSlug["Mitid"] = "MITID";
    EsigningProviderAuthenticationModeSlug["NorBankid"] = "NOR_BANKID";
    EsigningProviderAuthenticationModeSlug["SmartId"] = "SMART_ID";
    EsigningProviderAuthenticationModeSlug["SmsOtp"] = "SMS_OTP";
    EsigningProviderAuthenticationModeSlug["SweBankid"] = "SWE_BANKID";
})(EsigningProviderAuthenticationModeSlug || (EsigningProviderAuthenticationModeSlug = {}));
/** unique or primary key constraints on table "pl.esigning_signatory_value" */
export var EsigningSignatoryValueConstraint;
(function (EsigningSignatoryValueConstraint) {
    /** unique or primary key constraint on columns "name", "email", "label", "user_id", "is_signing", "company_id" */
    EsigningSignatoryValueConstraint["EsigningSignatoryValueCompanyIdUserIdNameEmailLabeKey"] = "esigning_signatory_value_company_id_user_id_name_email_labe_key";
    /** unique or primary key constraint on columns "id" */
    EsigningSignatoryValueConstraint["EsigningSignatoryValuePkey"] = "esigning_signatory_value_pkey";
})(EsigningSignatoryValueConstraint || (EsigningSignatoryValueConstraint = {}));
/** select columns of table "pl.esigning_signatory_value" */
export var EsigningSignatoryValueSelectColumn;
(function (EsigningSignatoryValueSelectColumn) {
    /** column name */
    EsigningSignatoryValueSelectColumn["CompanyId"] = "company_id";
    /** column name */
    EsigningSignatoryValueSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    EsigningSignatoryValueSelectColumn["Email"] = "email";
    /** column name */
    EsigningSignatoryValueSelectColumn["FileSystemDocumentId"] = "file_system_document_id";
    /** column name */
    EsigningSignatoryValueSelectColumn["Firstname"] = "firstname";
    /** column name */
    EsigningSignatoryValueSelectColumn["Id"] = "id";
    /** column name */
    EsigningSignatoryValueSelectColumn["IsSigning"] = "is_signing";
    /** column name */
    EsigningSignatoryValueSelectColumn["Label"] = "label";
    /** column name */
    EsigningSignatoryValueSelectColumn["Lastname"] = "lastname";
    /** column name */
    EsigningSignatoryValueSelectColumn["Mobile"] = "mobile";
    /** column name */
    EsigningSignatoryValueSelectColumn["Name"] = "name";
    /** column name */
    EsigningSignatoryValueSelectColumn["Title"] = "title";
    /** column name */
    EsigningSignatoryValueSelectColumn["UpdatedAt"] = "updated_at";
    /** column name */
    EsigningSignatoryValueSelectColumn["UserId"] = "user_id";
})(EsigningSignatoryValueSelectColumn || (EsigningSignatoryValueSelectColumn = {}));
/** update columns of table "pl.esigning_signatory_value" */
export var EsigningSignatoryValueUpdateColumn;
(function (EsigningSignatoryValueUpdateColumn) {
    /** column name */
    EsigningSignatoryValueUpdateColumn["CompanyId"] = "company_id";
    /** column name */
    EsigningSignatoryValueUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    EsigningSignatoryValueUpdateColumn["Email"] = "email";
    /** column name */
    EsigningSignatoryValueUpdateColumn["FileSystemDocumentId"] = "file_system_document_id";
    /** column name */
    EsigningSignatoryValueUpdateColumn["Firstname"] = "firstname";
    /** column name */
    EsigningSignatoryValueUpdateColumn["Id"] = "id";
    /** column name */
    EsigningSignatoryValueUpdateColumn["IsSigning"] = "is_signing";
    /** column name */
    EsigningSignatoryValueUpdateColumn["Label"] = "label";
    /** column name */
    EsigningSignatoryValueUpdateColumn["Lastname"] = "lastname";
    /** column name */
    EsigningSignatoryValueUpdateColumn["Mobile"] = "mobile";
    /** column name */
    EsigningSignatoryValueUpdateColumn["Name"] = "name";
    /** column name */
    EsigningSignatoryValueUpdateColumn["Title"] = "title";
    /** column name */
    EsigningSignatoryValueUpdateColumn["UpdatedAt"] = "updated_at";
    /** column name */
    EsigningSignatoryValueUpdateColumn["UserId"] = "user_id";
})(EsigningSignatoryValueUpdateColumn || (EsigningSignatoryValueUpdateColumn = {}));
export var ExternalSharingInviteStatusEnum;
(function (ExternalSharingInviteStatusEnum) {
    ExternalSharingInviteStatusEnum["Claimed"] = "CLAIMED";
    ExternalSharingInviteStatusEnum["Unclaimed"] = "UNCLAIMED";
})(ExternalSharingInviteStatusEnum || (ExternalSharingInviteStatusEnum = {}));
/** unique or primary key constraints on table "pl.feature" */
export var FeatureConstraint;
(function (FeatureConstraint) {
    /** unique or primary key constraint on columns "id" */
    FeatureConstraint["FeaturePkey"] = "feature_pkey";
})(FeatureConstraint || (FeatureConstraint = {}));
/** unique or primary key constraints on table "pl.feature_metadata" */
export var FeatureMetadataConstraint;
(function (FeatureMetadataConstraint) {
    /** unique or primary key constraint on columns "feature_id", "market_locale_code", "market_code" */
    FeatureMetadataConstraint["FeatureMetadataFeatureIdMarketCodeMarketLocaleCodeKey"] = "feature_metadata_feature_id_market_code_market_locale_code_key";
    /** unique or primary key constraint on columns "id" */
    FeatureMetadataConstraint["FeatureMetadataPkey"] = "feature_metadata_pkey";
})(FeatureMetadataConstraint || (FeatureMetadataConstraint = {}));
/** select columns of table "pl.feature_metadata" */
export var FeatureMetadataSelectColumn;
(function (FeatureMetadataSelectColumn) {
    /** column name */
    FeatureMetadataSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    FeatureMetadataSelectColumn["FeatureId"] = "feature_id";
    /** column name */
    FeatureMetadataSelectColumn["Id"] = "id";
    /** column name */
    FeatureMetadataSelectColumn["MarketCode"] = "market_code";
    /** column name */
    FeatureMetadataSelectColumn["MarketLocaleCode"] = "market_locale_code";
    /** column name */
    FeatureMetadataSelectColumn["Title"] = "title";
    /** column name */
    FeatureMetadataSelectColumn["UpdatedAt"] = "updated_at";
})(FeatureMetadataSelectColumn || (FeatureMetadataSelectColumn = {}));
/** update columns of table "pl.feature_metadata" */
export var FeatureMetadataUpdateColumn;
(function (FeatureMetadataUpdateColumn) {
    /** column name */
    FeatureMetadataUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    FeatureMetadataUpdateColumn["FeatureId"] = "feature_id";
    /** column name */
    FeatureMetadataUpdateColumn["Id"] = "id";
    /** column name */
    FeatureMetadataUpdateColumn["MarketCode"] = "market_code";
    /** column name */
    FeatureMetadataUpdateColumn["MarketLocaleCode"] = "market_locale_code";
    /** column name */
    FeatureMetadataUpdateColumn["Title"] = "title";
    /** column name */
    FeatureMetadataUpdateColumn["UpdatedAt"] = "updated_at";
})(FeatureMetadataUpdateColumn || (FeatureMetadataUpdateColumn = {}));
/** select columns of table "pl.feature" */
export var FeatureSelectColumn;
(function (FeatureSelectColumn) {
    /** column name */
    FeatureSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    FeatureSelectColumn["Data"] = "data";
    /** column name */
    FeatureSelectColumn["Id"] = "id";
    /** column name */
    FeatureSelectColumn["UpdatedAt"] = "updated_at";
})(FeatureSelectColumn || (FeatureSelectColumn = {}));
/** update columns of table "pl.feature" */
export var FeatureUpdateColumn;
(function (FeatureUpdateColumn) {
    /** column name */
    FeatureUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    FeatureUpdateColumn["Data"] = "data";
    /** column name */
    FeatureUpdateColumn["Id"] = "id";
    /** column name */
    FeatureUpdateColumn["UpdatedAt"] = "updated_at";
})(FeatureUpdateColumn || (FeatureUpdateColumn = {}));
/** unique or primary key constraints on table "pl.file" */
export var FileConstraint;
(function (FileConstraint) {
    /** unique or primary key constraint on columns "id" */
    FileConstraint["FilePkey"] = "file_pkey";
})(FileConstraint || (FileConstraint = {}));
/** select columns of table "pl.file" */
export var FileSelectColumn;
(function (FileSelectColumn) {
    /** column name */
    FileSelectColumn["CompanyId"] = "company_id";
    /** column name */
    FileSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    FileSelectColumn["Id"] = "id";
    /** column name */
    FileSelectColumn["MimeType"] = "mime_type";
    /** column name */
    FileSelectColumn["Name"] = "name";
    /** column name */
    FileSelectColumn["Path"] = "path";
    /** column name */
    FileSelectColumn["Size"] = "size";
    /** column name */
    FileSelectColumn["UpdatedAt"] = "updated_at";
    /** column name */
    FileSelectColumn["UserId"] = "user_id";
})(FileSelectColumn || (FileSelectColumn = {}));
export var FileSystemDocumentStatus;
(function (FileSystemDocumentStatus) {
    FileSystemDocumentStatus["AgreedStage"] = "AGREED_STAGE";
    FileSystemDocumentStatus["AgreedStageAgreedForm"] = "AGREED_STAGE__AGREED_FORM";
    FileSystemDocumentStatus["DraftStage"] = "DRAFT_STAGE";
    FileSystemDocumentStatus["Inconsistent"] = "INCONSISTENT";
    FileSystemDocumentStatus["ReviewStage"] = "REVIEW_STAGE";
    FileSystemDocumentStatus["ReviewStageApproved"] = "REVIEW_STAGE__APPROVED";
    FileSystemDocumentStatus["ReviewStageCancelled"] = "REVIEW_STAGE__CANCELLED";
    FileSystemDocumentStatus["ReviewStagePending"] = "REVIEW_STAGE__PENDING";
    FileSystemDocumentStatus["ReviewStageRejected"] = "REVIEW_STAGE__REJECTED";
    FileSystemDocumentStatus["SigningStage"] = "SIGNING_STAGE";
    FileSystemDocumentStatus["SigningStageCancelled"] = "SIGNING_STAGE__CANCELLED";
    FileSystemDocumentStatus["SigningStageFailed"] = "SIGNING_STAGE__FAILED";
    FileSystemDocumentStatus["SigningStagePending"] = "SIGNING_STAGE__PENDING";
    FileSystemDocumentStatus["SigningStagePendingNotYetSigned"] = "SIGNING_STAGE__PENDING__NOT_YET_SIGNED";
    FileSystemDocumentStatus["SigningStagePendingPartiallySigned"] = "SIGNING_STAGE__PENDING__PARTIALLY_SIGNED";
    FileSystemDocumentStatus["SigningStageRejected"] = "SIGNING_STAGE__REJECTED";
    FileSystemDocumentStatus["SigningStageSigned"] = "SIGNING_STAGE__SIGNED";
    FileSystemDocumentStatus["Unknown"] = "UNKNOWN";
})(FileSystemDocumentStatus || (FileSystemDocumentStatus = {}));
export var FileSystemDocumentStatusExplicitEvents;
(function (FileSystemDocumentStatusExplicitEvents) {
    FileSystemDocumentStatusExplicitEvents["ExplicitlySetAgreed"] = "EXPLICITLY_SET_AGREED";
    FileSystemDocumentStatusExplicitEvents["ExplicitlySetDraft"] = "EXPLICITLY_SET_DRAFT";
    FileSystemDocumentStatusExplicitEvents["ExplicitlySetSigned"] = "EXPLICITLY_SET_SIGNED";
})(FileSystemDocumentStatusExplicitEvents || (FileSystemDocumentStatusExplicitEvents = {}));
export var FileSystemDocumentSuggestionStatus;
(function (FileSystemDocumentSuggestionStatus) {
    FileSystemDocumentSuggestionStatus["Accepted"] = "accepted";
    FileSystemDocumentSuggestionStatus["Open"] = "open";
    FileSystemDocumentSuggestionStatus["Rejected"] = "rejected";
})(FileSystemDocumentSuggestionStatus || (FileSystemDocumentSuggestionStatus = {}));
export var FileSystemDocumentSuggestionType;
(function (FileSystemDocumentSuggestionType) {
    FileSystemDocumentSuggestionType["Deletion"] = "deletion";
    FileSystemDocumentSuggestionType["FormatBlock"] = "formatBlock";
    FileSystemDocumentSuggestionType["FormatInline"] = "formatInline";
    FileSystemDocumentSuggestionType["Insertion"] = "insertion";
})(FileSystemDocumentSuggestionType || (FileSystemDocumentSuggestionType = {}));
export var FileSystemFeature;
(function (FileSystemFeature) {
    FileSystemFeature["ImportExport"] = "IMPORT_EXPORT";
    FileSystemFeature["Rtc"] = "RTC";
    FileSystemFeature["Suggestions"] = "SUGGESTIONS";
})(FileSystemFeature || (FileSystemFeature = {}));
/** update columns of table "pl.file" */
export var FileUpdateColumn;
(function (FileUpdateColumn) {
    /** column name */
    FileUpdateColumn["CompanyId"] = "company_id";
    /** column name */
    FileUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    FileUpdateColumn["Id"] = "id";
    /** column name */
    FileUpdateColumn["MimeType"] = "mime_type";
    /** column name */
    FileUpdateColumn["Name"] = "name";
    /** column name */
    FileUpdateColumn["Path"] = "path";
    /** column name */
    FileUpdateColumn["Size"] = "size";
    /** column name */
    FileUpdateColumn["UpdatedAt"] = "updated_at";
    /** column name */
    FileUpdateColumn["UserId"] = "user_id";
})(FileUpdateColumn || (FileUpdateColumn = {}));
export var FolderUploadJobStatus;
(function (FolderUploadJobStatus) {
    FolderUploadJobStatus["Done"] = "DONE";
    FolderUploadJobStatus["Failed"] = "FAILED";
    FolderUploadJobStatus["Pending"] = "PENDING";
    FolderUploadJobStatus["Processing"] = "PROCESSING";
})(FolderUploadJobStatus || (FolderUploadJobStatus = {}));
export var GlobalAssistantChatMessageRole;
(function (GlobalAssistantChatMessageRole) {
    GlobalAssistantChatMessageRole["Assistant"] = "ASSISTANT";
    GlobalAssistantChatMessageRole["System"] = "SYSTEM";
    GlobalAssistantChatMessageRole["User"] = "USER";
})(GlobalAssistantChatMessageRole || (GlobalAssistantChatMessageRole = {}));
export var GlobalAssistantChatMessageStatus;
(function (GlobalAssistantChatMessageStatus) {
    GlobalAssistantChatMessageStatus["Failed"] = "FAILED";
    GlobalAssistantChatMessageStatus["Pending"] = "PENDING";
    GlobalAssistantChatMessageStatus["Resolved"] = "RESOLVED";
})(GlobalAssistantChatMessageStatus || (GlobalAssistantChatMessageStatus = {}));
export var GlobalAssistantChatMessageType;
(function (GlobalAssistantChatMessageType) {
    GlobalAssistantChatMessageType["Chronology"] = "CHRONOLOGY";
    GlobalAssistantChatMessageType["ExtractMetadata"] = "EXTRACT_METADATA";
    GlobalAssistantChatMessageType["GeneralQuestion"] = "GENERAL_QUESTION";
})(GlobalAssistantChatMessageType || (GlobalAssistantChatMessageType = {}));
/** unique or primary key constraints on table "pl.guidance_diagnosis" */
export var GuidanceDiagnosisConstraint;
(function (GuidanceDiagnosisConstraint) {
    /** unique or primary key constraint on columns "id" */
    GuidanceDiagnosisConstraint["GuidanceDiagnosisPkey"] = "guidance_diagnosis_pkey";
})(GuidanceDiagnosisConstraint || (GuidanceDiagnosisConstraint = {}));
/** select columns of table "pl.guidance_diagnosis" */
export var GuidanceDiagnosisSelectColumn;
(function (GuidanceDiagnosisSelectColumn) {
    /** column name */
    GuidanceDiagnosisSelectColumn["CategoryId"] = "category_id";
    /** column name */
    GuidanceDiagnosisSelectColumn["CompanyId"] = "company_id";
    /** column name */
    GuidanceDiagnosisSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    GuidanceDiagnosisSelectColumn["DeletedAt"] = "deleted_at";
    /** column name */
    GuidanceDiagnosisSelectColumn["Id"] = "id";
    /** column name */
    GuidanceDiagnosisSelectColumn["PublishedAt"] = "published_at";
    /** column name */
    GuidanceDiagnosisSelectColumn["Title"] = "title";
    /** column name */
    GuidanceDiagnosisSelectColumn["TopicId"] = "topic_id";
    /** column name */
    GuidanceDiagnosisSelectColumn["UpdatedAt"] = "updated_at";
    /** column name */
    GuidanceDiagnosisSelectColumn["UserId"] = "user_id";
})(GuidanceDiagnosisSelectColumn || (GuidanceDiagnosisSelectColumn = {}));
/** update columns of table "pl.guidance_diagnosis" */
export var GuidanceDiagnosisUpdateColumn;
(function (GuidanceDiagnosisUpdateColumn) {
    /** column name */
    GuidanceDiagnosisUpdateColumn["CategoryId"] = "category_id";
    /** column name */
    GuidanceDiagnosisUpdateColumn["CompanyId"] = "company_id";
    /** column name */
    GuidanceDiagnosisUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    GuidanceDiagnosisUpdateColumn["DeletedAt"] = "deleted_at";
    /** column name */
    GuidanceDiagnosisUpdateColumn["Id"] = "id";
    /** column name */
    GuidanceDiagnosisUpdateColumn["PublishedAt"] = "published_at";
    /** column name */
    GuidanceDiagnosisUpdateColumn["Title"] = "title";
    /** column name */
    GuidanceDiagnosisUpdateColumn["TopicId"] = "topic_id";
    /** column name */
    GuidanceDiagnosisUpdateColumn["UpdatedAt"] = "updated_at";
    /** column name */
    GuidanceDiagnosisUpdateColumn["UserId"] = "user_id";
})(GuidanceDiagnosisUpdateColumn || (GuidanceDiagnosisUpdateColumn = {}));
/** unique or primary key constraints on table "pl.guide" */
export var GuideConstraint;
(function (GuideConstraint) {
    /** unique or primary key constraint on columns "id" */
    GuideConstraint["GuidePkey"] = "guide_pkey";
})(GuideConstraint || (GuideConstraint = {}));
/** unique or primary key constraints on table "pl.guide_metadata" */
export var GuideMetadataConstraint;
(function (GuideMetadataConstraint) {
    /** unique or primary key constraint on columns "guide_id", "market_locale_code" */
    GuideMetadataConstraint["GuideMetadataGuideIdMarketLocaleCodeKey"] = "guide_metadata_guide_id_market_locale_code_key";
    /** unique or primary key constraint on columns "id" */
    GuideMetadataConstraint["GuideMetadataPkey"] = "guide_metadata_pkey";
})(GuideMetadataConstraint || (GuideMetadataConstraint = {}));
/** select columns of table "pl.guide_metadata" */
export var GuideMetadataSelectColumn;
(function (GuideMetadataSelectColumn) {
    /** column name */
    GuideMetadataSelectColumn["Description"] = "description";
    /** column name */
    GuideMetadataSelectColumn["GuideId"] = "guide_id";
    /** column name */
    GuideMetadataSelectColumn["Id"] = "id";
    /** column name */
    GuideMetadataSelectColumn["MarketCode"] = "market_code";
    /** column name */
    GuideMetadataSelectColumn["MarketLocaleCode"] = "market_locale_code";
    /** column name */
    GuideMetadataSelectColumn["Title"] = "title";
})(GuideMetadataSelectColumn || (GuideMetadataSelectColumn = {}));
/** update columns of table "pl.guide_metadata" */
export var GuideMetadataUpdateColumn;
(function (GuideMetadataUpdateColumn) {
    /** column name */
    GuideMetadataUpdateColumn["Description"] = "description";
    /** column name */
    GuideMetadataUpdateColumn["GuideId"] = "guide_id";
    /** column name */
    GuideMetadataUpdateColumn["Id"] = "id";
    /** column name */
    GuideMetadataUpdateColumn["MarketCode"] = "market_code";
    /** column name */
    GuideMetadataUpdateColumn["MarketLocaleCode"] = "market_locale_code";
    /** column name */
    GuideMetadataUpdateColumn["Title"] = "title";
})(GuideMetadataUpdateColumn || (GuideMetadataUpdateColumn = {}));
/** unique or primary key constraints on table "pl.guide_plan" */
export var GuidePlanConstraint;
(function (GuidePlanConstraint) {
    /** unique or primary key constraint on columns "id" */
    GuidePlanConstraint["GuidePlanPkey"] = "guide_plan_pkey";
    /** unique or primary key constraint on columns "guide_id", "plan_id" */
    GuidePlanConstraint["GuidePlanPlanIdGuideIdKey"] = "guide_plan_plan_id_guide_id_key";
})(GuidePlanConstraint || (GuidePlanConstraint = {}));
/** select columns of table "pl.guide_plan" */
export var GuidePlanSelectColumn;
(function (GuidePlanSelectColumn) {
    /** column name */
    GuidePlanSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    GuidePlanSelectColumn["GuideId"] = "guide_id";
    /** column name */
    GuidePlanSelectColumn["Id"] = "id";
    /** column name */
    GuidePlanSelectColumn["PlanId"] = "plan_id";
    /** column name */
    GuidePlanSelectColumn["UpdatedAt"] = "updated_at";
})(GuidePlanSelectColumn || (GuidePlanSelectColumn = {}));
/** update columns of table "pl.guide_plan" */
export var GuidePlanUpdateColumn;
(function (GuidePlanUpdateColumn) {
    /** column name */
    GuidePlanUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    GuidePlanUpdateColumn["GuideId"] = "guide_id";
    /** column name */
    GuidePlanUpdateColumn["Id"] = "id";
    /** column name */
    GuidePlanUpdateColumn["PlanId"] = "plan_id";
    /** column name */
    GuidePlanUpdateColumn["UpdatedAt"] = "updated_at";
})(GuidePlanUpdateColumn || (GuidePlanUpdateColumn = {}));
/** select columns of table "pl.guide" */
export var GuideSelectColumn;
(function (GuideSelectColumn) {
    /** column name */
    GuideSelectColumn["CategoryId"] = "category_id";
    /** column name */
    GuideSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    GuideSelectColumn["Enabled"] = "enabled";
    /** column name */
    GuideSelectColumn["Entry"] = "entry";
    /** column name */
    GuideSelectColumn["Id"] = "id";
    /** column name */
    GuideSelectColumn["MarketCode"] = "market_code";
    /** column name */
    GuideSelectColumn["Ordinal"] = "ordinal";
    /** column name */
    GuideSelectColumn["TopicId"] = "topic_id";
    /** column name */
    GuideSelectColumn["Type"] = "type";
    /** column name */
    GuideSelectColumn["TypeformId"] = "typeform_id";
    /** column name */
    GuideSelectColumn["UpdatedAt"] = "updated_at";
})(GuideSelectColumn || (GuideSelectColumn = {}));
/** select "pl_guide_aggregate_bool_exp_bool_and_arguments_columns" columns of table "pl.guide" */
export var GuideSelectColumnPlGuideAggregateBoolExpBoolAndArgumentsColumns;
(function (GuideSelectColumnPlGuideAggregateBoolExpBoolAndArgumentsColumns) {
    /** column name */
    GuideSelectColumnPlGuideAggregateBoolExpBoolAndArgumentsColumns["Enabled"] = "enabled";
    /** column name */
    GuideSelectColumnPlGuideAggregateBoolExpBoolAndArgumentsColumns["Entry"] = "entry";
})(GuideSelectColumnPlGuideAggregateBoolExpBoolAndArgumentsColumns || (GuideSelectColumnPlGuideAggregateBoolExpBoolAndArgumentsColumns = {}));
/** select "pl_guide_aggregate_bool_exp_bool_or_arguments_columns" columns of table "pl.guide" */
export var GuideSelectColumnPlGuideAggregateBoolExpBoolOrArgumentsColumns;
(function (GuideSelectColumnPlGuideAggregateBoolExpBoolOrArgumentsColumns) {
    /** column name */
    GuideSelectColumnPlGuideAggregateBoolExpBoolOrArgumentsColumns["Enabled"] = "enabled";
    /** column name */
    GuideSelectColumnPlGuideAggregateBoolExpBoolOrArgumentsColumns["Entry"] = "entry";
})(GuideSelectColumnPlGuideAggregateBoolExpBoolOrArgumentsColumns || (GuideSelectColumnPlGuideAggregateBoolExpBoolOrArgumentsColumns = {}));
/** unique or primary key constraints on table "pl.guide_template" */
export var GuideTemplateConstraint;
(function (GuideTemplateConstraint) {
    /** unique or primary key constraint on columns "id" */
    GuideTemplateConstraint["GuideTemplatePkey"] = "guide_template_pkey";
    /** unique or primary key constraint on columns "template_id", "guide_id" */
    GuideTemplateConstraint["GuideTemplateTemplateIdGuideIdKey"] = "guide_template_template_id_guide_id_key";
})(GuideTemplateConstraint || (GuideTemplateConstraint = {}));
/** select columns of table "pl.guide_template" */
export var GuideTemplateSelectColumn;
(function (GuideTemplateSelectColumn) {
    /** column name */
    GuideTemplateSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    GuideTemplateSelectColumn["GuideId"] = "guide_id";
    /** column name */
    GuideTemplateSelectColumn["Id"] = "id";
    /** column name */
    GuideTemplateSelectColumn["TemplateId"] = "template_id";
    /** column name */
    GuideTemplateSelectColumn["UpdatedAt"] = "updated_at";
})(GuideTemplateSelectColumn || (GuideTemplateSelectColumn = {}));
/** update columns of table "pl.guide_template" */
export var GuideTemplateUpdateColumn;
(function (GuideTemplateUpdateColumn) {
    /** column name */
    GuideTemplateUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    GuideTemplateUpdateColumn["GuideId"] = "guide_id";
    /** column name */
    GuideTemplateUpdateColumn["Id"] = "id";
    /** column name */
    GuideTemplateUpdateColumn["TemplateId"] = "template_id";
    /** column name */
    GuideTemplateUpdateColumn["UpdatedAt"] = "updated_at";
})(GuideTemplateUpdateColumn || (GuideTemplateUpdateColumn = {}));
/** unique or primary key constraints on table "pl.guide_type_enum" */
export var GuideTypeEnumConstraint;
(function (GuideTypeEnumConstraint) {
    /** unique or primary key constraint on columns "name" */
    GuideTypeEnumConstraint["GuideTypeEnumPkey"] = "guide_type_enum_pkey";
})(GuideTypeEnumConstraint || (GuideTypeEnumConstraint = {}));
export var GuideTypeEnumEnum;
(function (GuideTypeEnumEnum) {
    /** Checkup guide that can be only one per topic */
    GuideTypeEnumEnum["Checkup"] = "checkup";
    /** Entry guide to other guides that is available for everyone */
    GuideTypeEnumEnum["Entry"] = "entry";
    /** Normal guide */
    GuideTypeEnumEnum["Normal"] = "normal";
})(GuideTypeEnumEnum || (GuideTypeEnumEnum = {}));
/** select columns of table "pl.guide_type_enum" */
export var GuideTypeEnumSelectColumn;
(function (GuideTypeEnumSelectColumn) {
    /** column name */
    GuideTypeEnumSelectColumn["Description"] = "description";
    /** column name */
    GuideTypeEnumSelectColumn["Name"] = "name";
})(GuideTypeEnumSelectColumn || (GuideTypeEnumSelectColumn = {}));
/** update columns of table "pl.guide_type_enum" */
export var GuideTypeEnumUpdateColumn;
(function (GuideTypeEnumUpdateColumn) {
    /** column name */
    GuideTypeEnumUpdateColumn["Description"] = "description";
    /** column name */
    GuideTypeEnumUpdateColumn["Name"] = "name";
})(GuideTypeEnumUpdateColumn || (GuideTypeEnumUpdateColumn = {}));
/** update columns of table "pl.guide" */
export var GuideUpdateColumn;
(function (GuideUpdateColumn) {
    /** column name */
    GuideUpdateColumn["CategoryId"] = "category_id";
    /** column name */
    GuideUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    GuideUpdateColumn["Enabled"] = "enabled";
    /** column name */
    GuideUpdateColumn["Entry"] = "entry";
    /** column name */
    GuideUpdateColumn["Id"] = "id";
    /** column name */
    GuideUpdateColumn["MarketCode"] = "market_code";
    /** column name */
    GuideUpdateColumn["Ordinal"] = "ordinal";
    /** column name */
    GuideUpdateColumn["TopicId"] = "topic_id";
    /** column name */
    GuideUpdateColumn["Type"] = "type";
    /** column name */
    GuideUpdateColumn["TypeformId"] = "typeform_id";
    /** column name */
    GuideUpdateColumn["UpdatedAt"] = "updated_at";
})(GuideUpdateColumn || (GuideUpdateColumn = {}));
export var ImportDocumentToCcsStatus;
(function (ImportDocumentToCcsStatus) {
    ImportDocumentToCcsStatus["Done"] = "DONE";
    ImportDocumentToCcsStatus["Failed"] = "FAILED";
    ImportDocumentToCcsStatus["Pending"] = "PENDING";
    ImportDocumentToCcsStatus["Processing"] = "PROCESSING";
})(ImportDocumentToCcsStatus || (ImportDocumentToCcsStatus = {}));
/** https://stripe.com/docs/api/invoices/object#invoice_object-status */
export var InvoiceStatusEnum;
(function (InvoiceStatusEnum) {
    InvoiceStatusEnum["Draft"] = "DRAFT";
    InvoiceStatusEnum["Open"] = "OPEN";
    InvoiceStatusEnum["Paid"] = "PAID";
    InvoiceStatusEnum["Uncollectible"] = "UNCOLLECTIBLE";
    InvoiceStatusEnum["Void"] = "VOID";
})(InvoiceStatusEnum || (InvoiceStatusEnum = {}));
/** unique or primary key constraints on table "pl.market" */
export var MarketConstraint;
(function (MarketConstraint) {
    /** unique or primary key constraint on columns "id" */
    MarketConstraint["MarketIdKey"] = "market_id_key";
    /** unique or primary key constraint on columns "code" */
    MarketConstraint["MarketPkey"] = "market_pkey";
})(MarketConstraint || (MarketConstraint = {}));
/** unique or primary key constraints on table "pl.market_feature" */
export var MarketFeatureConstraint;
(function (MarketFeatureConstraint) {
    /** unique or primary key constraint on columns "feature_id", "market_code" */
    MarketFeatureConstraint["MarketFeatureMarketCodeFeatureIdKey"] = "market_feature_market_code_feature_id_key";
    /** unique or primary key constraint on columns "id" */
    MarketFeatureConstraint["MarketFeaturePkey"] = "market_feature_pkey";
})(MarketFeatureConstraint || (MarketFeatureConstraint = {}));
/** select columns of table "pl.market_feature" */
export var MarketFeatureSelectColumn;
(function (MarketFeatureSelectColumn) {
    /** column name */
    MarketFeatureSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    MarketFeatureSelectColumn["FeatureId"] = "feature_id";
    /** column name */
    MarketFeatureSelectColumn["Id"] = "id";
    /** column name */
    MarketFeatureSelectColumn["MarketCode"] = "market_code";
    /** column name */
    MarketFeatureSelectColumn["Ordinal"] = "ordinal";
    /** column name */
    MarketFeatureSelectColumn["SalesPromoted"] = "sales_promoted";
    /** column name */
    MarketFeatureSelectColumn["UpdatedAt"] = "updated_at";
})(MarketFeatureSelectColumn || (MarketFeatureSelectColumn = {}));
/** update columns of table "pl.market_feature" */
export var MarketFeatureUpdateColumn;
(function (MarketFeatureUpdateColumn) {
    /** column name */
    MarketFeatureUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    MarketFeatureUpdateColumn["FeatureId"] = "feature_id";
    /** column name */
    MarketFeatureUpdateColumn["Id"] = "id";
    /** column name */
    MarketFeatureUpdateColumn["MarketCode"] = "market_code";
    /** column name */
    MarketFeatureUpdateColumn["Ordinal"] = "ordinal";
    /** column name */
    MarketFeatureUpdateColumn["SalesPromoted"] = "sales_promoted";
    /** column name */
    MarketFeatureUpdateColumn["UpdatedAt"] = "updated_at";
})(MarketFeatureUpdateColumn || (MarketFeatureUpdateColumn = {}));
/** unique or primary key constraints on table "pl.market_locale" */
export var MarketLocaleConstraint;
(function (MarketLocaleConstraint) {
    /** unique or primary key constraint on columns "code", "market_code" */
    MarketLocaleConstraint["MarketLocalePkey"] = "market_locale_pkey";
})(MarketLocaleConstraint || (MarketLocaleConstraint = {}));
/** select columns of table "pl.market_locale" */
export var MarketLocaleSelectColumn;
(function (MarketLocaleSelectColumn) {
    /** column name */
    MarketLocaleSelectColumn["Code"] = "code";
    /** column name */
    MarketLocaleSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    MarketLocaleSelectColumn["Id"] = "id";
    /** column name */
    MarketLocaleSelectColumn["MarketCode"] = "market_code";
    /** column name */
    MarketLocaleSelectColumn["MarketId"] = "market_id";
    /** column name */
    MarketLocaleSelectColumn["UpdatedAt"] = "updated_at";
})(MarketLocaleSelectColumn || (MarketLocaleSelectColumn = {}));
/** update columns of table "pl.market_locale" */
export var MarketLocaleUpdateColumn;
(function (MarketLocaleUpdateColumn) {
    /** column name */
    MarketLocaleUpdateColumn["Code"] = "code";
    /** column name */
    MarketLocaleUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    MarketLocaleUpdateColumn["Id"] = "id";
    /** column name */
    MarketLocaleUpdateColumn["MarketCode"] = "market_code";
    /** column name */
    MarketLocaleUpdateColumn["MarketId"] = "market_id";
    /** column name */
    MarketLocaleUpdateColumn["UpdatedAt"] = "updated_at";
})(MarketLocaleUpdateColumn || (MarketLocaleUpdateColumn = {}));
/** select columns of table "pl.market" */
export var MarketSelectColumn;
(function (MarketSelectColumn) {
    /** column name */
    MarketSelectColumn["Code"] = "code";
    /** column name */
    MarketSelectColumn["CodeIso"] = "code_iso";
    /** column name */
    MarketSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    MarketSelectColumn["Id"] = "id";
    /** column name */
    MarketSelectColumn["Name"] = "name";
    /** column name */
    MarketSelectColumn["StripePublicKey"] = "stripe_public_key";
    /** column name */
    MarketSelectColumn["StripeTaxRateId"] = "stripe_tax_rate_id";
    /** column name */
    MarketSelectColumn["UpdatedAt"] = "updated_at";
})(MarketSelectColumn || (MarketSelectColumn = {}));
/** update columns of table "pl.market" */
export var MarketUpdateColumn;
(function (MarketUpdateColumn) {
    /** column name */
    MarketUpdateColumn["Code"] = "code";
    /** column name */
    MarketUpdateColumn["CodeIso"] = "code_iso";
    /** column name */
    MarketUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    MarketUpdateColumn["Id"] = "id";
    /** column name */
    MarketUpdateColumn["Name"] = "name";
    /** column name */
    MarketUpdateColumn["StripePublicKey"] = "stripe_public_key";
    /** column name */
    MarketUpdateColumn["StripeTaxRateId"] = "stripe_tax_rate_id";
    /** column name */
    MarketUpdateColumn["UpdatedAt"] = "updated_at";
})(MarketUpdateColumn || (MarketUpdateColumn = {}));
/** column ordering options */
export var OrderBy;
(function (OrderBy) {
    /** in ascending order, nulls last */
    OrderBy["Asc"] = "asc";
    /** in ascending order, nulls first */
    OrderBy["AscNullsFirst"] = "asc_nulls_first";
    /** in ascending order, nulls last */
    OrderBy["AscNullsLast"] = "asc_nulls_last";
    /** in descending order, nulls first */
    OrderBy["Desc"] = "desc";
    /** in descending order, nulls first */
    OrderBy["DescNullsFirst"] = "desc_nulls_first";
    /** in descending order, nulls last */
    OrderBy["DescNullsLast"] = "desc_nulls_last";
})(OrderBy || (OrderBy = {}));
export var OrderByArg;
(function (OrderByArg) {
    OrderByArg["Asc"] = "asc";
    OrderByArg["Desc"] = "desc";
})(OrderByArg || (OrderByArg = {}));
export var PartialCompanyUserRoleEnum;
(function (PartialCompanyUserRoleEnum) {
    PartialCompanyUserRoleEnum["Member"] = "member";
    PartialCompanyUserRoleEnum["Owner"] = "owner";
})(PartialCompanyUserRoleEnum || (PartialCompanyUserRoleEnum = {}));
/** https://stripe.com/docs/error-codes */
export var PaymentErrorCodeEnum;
(function (PaymentErrorCodeEnum) {
    /** https://stripe.com/docs/error-codes#card-declined */
    PaymentErrorCodeEnum["CardDeclined"] = "CARD_DECLINED";
    /** https://stripe.com/docs/error-codes#resource-missing */
    PaymentErrorCodeEnum["ResourceMissing"] = "RESOURCE_MISSING";
})(PaymentErrorCodeEnum || (PaymentErrorCodeEnum = {}));
/** https://stripe.com/docs/api/payment_intents/object#payment_intent_object-status */
export var PaymentIntentStatusEnum;
(function (PaymentIntentStatusEnum) {
    PaymentIntentStatusEnum["Canceled"] = "CANCELED";
    PaymentIntentStatusEnum["Processing"] = "PROCESSING";
    PaymentIntentStatusEnum["RequiresAction"] = "REQUIRES_ACTION";
    PaymentIntentStatusEnum["RequiresCapture"] = "REQUIRES_CAPTURE";
    PaymentIntentStatusEnum["RequiresConfirmation"] = "REQUIRES_CONFIRMATION";
    PaymentIntentStatusEnum["RequiresPaymentMethod"] = "REQUIRES_PAYMENT_METHOD";
    PaymentIntentStatusEnum["Succeeded"] = "SUCCEEDED";
})(PaymentIntentStatusEnum || (PaymentIntentStatusEnum = {}));
/** https://stripe.com/docs/api/subscriptions/object#subscription_object-collection_method */
export var PaymentSubscriptionCollectionMethodEnum;
(function (PaymentSubscriptionCollectionMethodEnum) {
    PaymentSubscriptionCollectionMethodEnum["ChargeAutomatically"] = "CHARGE_AUTOMATICALLY";
    PaymentSubscriptionCollectionMethodEnum["SendInvoice"] = "SEND_INVOICE";
})(PaymentSubscriptionCollectionMethodEnum || (PaymentSubscriptionCollectionMethodEnum = {}));
/** https://stripe.com/docs/api/subscriptions/object#subscription_object-status */
export var PaymentSubscriptionStatusEnum;
(function (PaymentSubscriptionStatusEnum) {
    PaymentSubscriptionStatusEnum["Active"] = "ACTIVE";
    PaymentSubscriptionStatusEnum["Canceled"] = "CANCELED";
    PaymentSubscriptionStatusEnum["Incomplete"] = "INCOMPLETE";
    PaymentSubscriptionStatusEnum["IncompleteExpired"] = "INCOMPLETE_EXPIRED";
    PaymentSubscriptionStatusEnum["PastDue"] = "PAST_DUE";
    PaymentSubscriptionStatusEnum["Traling"] = "TRALING";
    PaymentSubscriptionStatusEnum["Unpaid"] = "UNPAID";
})(PaymentSubscriptionStatusEnum || (PaymentSubscriptionStatusEnum = {}));
/** unique or primary key constraints on table "pl.plan" */
export var PlanConstraint;
(function (PlanConstraint) {
    /** unique or primary key constraint on columns "id" */
    PlanConstraint["PlanPkey"] = "plan_pkey";
})(PlanConstraint || (PlanConstraint = {}));
/** unique or primary key constraints on table "pl.plan_feature" */
export var PlanFeatureConstraint;
(function (PlanFeatureConstraint) {
    /** unique or primary key constraint on columns "id" */
    PlanFeatureConstraint["PlanFeaturePkey"] = "plan_feature_pkey";
    /** unique or primary key constraint on columns "feature_id", "plan_id" */
    PlanFeatureConstraint["PlanFeaturePlanIdFeatureIdKey"] = "plan_feature_plan_id_feature_id_key";
})(PlanFeatureConstraint || (PlanFeatureConstraint = {}));
/** select columns of table "pl.plan_feature" */
export var PlanFeatureSelectColumn;
(function (PlanFeatureSelectColumn) {
    /** column name */
    PlanFeatureSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    PlanFeatureSelectColumn["FeatureId"] = "feature_id";
    /** column name */
    PlanFeatureSelectColumn["Id"] = "id";
    /** column name */
    PlanFeatureSelectColumn["PlanId"] = "plan_id";
    /** column name */
    PlanFeatureSelectColumn["UpdatedAt"] = "updated_at";
})(PlanFeatureSelectColumn || (PlanFeatureSelectColumn = {}));
/** update columns of table "pl.plan_feature" */
export var PlanFeatureUpdateColumn;
(function (PlanFeatureUpdateColumn) {
    /** column name */
    PlanFeatureUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    PlanFeatureUpdateColumn["FeatureId"] = "feature_id";
    /** column name */
    PlanFeatureUpdateColumn["Id"] = "id";
    /** column name */
    PlanFeatureUpdateColumn["PlanId"] = "plan_id";
    /** column name */
    PlanFeatureUpdateColumn["UpdatedAt"] = "updated_at";
})(PlanFeatureUpdateColumn || (PlanFeatureUpdateColumn = {}));
/** unique or primary key constraints on table "pl.plan_metadata" */
export var PlanMetadataConstraint;
(function (PlanMetadataConstraint) {
    /** unique or primary key constraint on columns "id" */
    PlanMetadataConstraint["PlanMetadataPkey"] = "plan_metadata_pkey";
    /** unique or primary key constraint on columns "market_locale_code", "plan_id" */
    PlanMetadataConstraint["PlanMetadataPlanIdMarketLocaleCodeKey"] = "plan_metadata_plan_id_market_locale_code_key";
})(PlanMetadataConstraint || (PlanMetadataConstraint = {}));
/** select columns of table "pl.plan_metadata" */
export var PlanMetadataSelectColumn;
(function (PlanMetadataSelectColumn) {
    /** column name */
    PlanMetadataSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    PlanMetadataSelectColumn["Description"] = "description";
    /** column name */
    PlanMetadataSelectColumn["Id"] = "id";
    /** column name */
    PlanMetadataSelectColumn["MarketCode"] = "market_code";
    /** column name */
    PlanMetadataSelectColumn["MarketLocaleCode"] = "market_locale_code";
    /** column name */
    PlanMetadataSelectColumn["PlanId"] = "plan_id";
    /** column name */
    PlanMetadataSelectColumn["UpdatedAt"] = "updated_at";
})(PlanMetadataSelectColumn || (PlanMetadataSelectColumn = {}));
/** update columns of table "pl.plan_metadata" */
export var PlanMetadataUpdateColumn;
(function (PlanMetadataUpdateColumn) {
    /** column name */
    PlanMetadataUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    PlanMetadataUpdateColumn["Description"] = "description";
    /** column name */
    PlanMetadataUpdateColumn["Id"] = "id";
    /** column name */
    PlanMetadataUpdateColumn["MarketCode"] = "market_code";
    /** column name */
    PlanMetadataUpdateColumn["MarketLocaleCode"] = "market_locale_code";
    /** column name */
    PlanMetadataUpdateColumn["PlanId"] = "plan_id";
    /** column name */
    PlanMetadataUpdateColumn["UpdatedAt"] = "updated_at";
})(PlanMetadataUpdateColumn || (PlanMetadataUpdateColumn = {}));
/** select columns of table "pl.plan" */
export var PlanSelectColumn;
(function (PlanSelectColumn) {
    /** column name */
    PlanSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    PlanSelectColumn["Enabled"] = "enabled";
    /** column name */
    PlanSelectColumn["Features"] = "features";
    /** column name */
    PlanSelectColumn["Id"] = "id";
    /** column name */
    PlanSelectColumn["MarketCode"] = "market_code";
    /** column name */
    PlanSelectColumn["Name"] = "name";
    /** column name */
    PlanSelectColumn["Ordinal"] = "ordinal";
    /** column name */
    PlanSelectColumn["StripeProductId"] = "stripe_product_id";
    /** column name */
    PlanSelectColumn["Title"] = "title";
    /** column name */
    PlanSelectColumn["Type"] = "type";
    /** column name */
    PlanSelectColumn["UpdatedAt"] = "updated_at";
})(PlanSelectColumn || (PlanSelectColumn = {}));
/** unique or primary key constraints on table "pl.plan_type_enum" */
export var PlanTypeEnumConstraint;
(function (PlanTypeEnumConstraint) {
    /** unique or primary key constraint on columns "name" */
    PlanTypeEnumConstraint["PlanTypeEnumPkey"] = "plan_type_enum_pkey";
})(PlanTypeEnumConstraint || (PlanTypeEnumConstraint = {}));
export var PlanTypeEnumEnum;
(function (PlanTypeEnumEnum) {
    /** big plan with monthly payment */
    PlanTypeEnumEnum["Big"] = "big";
    /** free plan */
    PlanTypeEnumEnum["Free"] = "free";
    /** medium plan with monthly payment */
    PlanTypeEnumEnum["Medium"] = "medium";
    /** small plan with yearly payment */
    PlanTypeEnumEnum["Small"] = "small";
    /** smallplus plan with monthly payment */
    PlanTypeEnumEnum["Smallplus"] = "smallplus";
    /** trial period */
    PlanTypeEnumEnum["Trial"] = "trial";
})(PlanTypeEnumEnum || (PlanTypeEnumEnum = {}));
/** select columns of table "pl.plan_type_enum" */
export var PlanTypeEnumSelectColumn;
(function (PlanTypeEnumSelectColumn) {
    /** column name */
    PlanTypeEnumSelectColumn["Description"] = "description";
    /** column name */
    PlanTypeEnumSelectColumn["Name"] = "name";
})(PlanTypeEnumSelectColumn || (PlanTypeEnumSelectColumn = {}));
/** update columns of table "pl.plan_type_enum" */
export var PlanTypeEnumUpdateColumn;
(function (PlanTypeEnumUpdateColumn) {
    /** column name */
    PlanTypeEnumUpdateColumn["Description"] = "description";
    /** column name */
    PlanTypeEnumUpdateColumn["Name"] = "name";
})(PlanTypeEnumUpdateColumn || (PlanTypeEnumUpdateColumn = {}));
/** update columns of table "pl.plan" */
export var PlanUpdateColumn;
(function (PlanUpdateColumn) {
    /** column name */
    PlanUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    PlanUpdateColumn["Enabled"] = "enabled";
    /** column name */
    PlanUpdateColumn["Features"] = "features";
    /** column name */
    PlanUpdateColumn["Id"] = "id";
    /** column name */
    PlanUpdateColumn["MarketCode"] = "market_code";
    /** column name */
    PlanUpdateColumn["Name"] = "name";
    /** column name */
    PlanUpdateColumn["Ordinal"] = "ordinal";
    /** column name */
    PlanUpdateColumn["StripeProductId"] = "stripe_product_id";
    /** column name */
    PlanUpdateColumn["Title"] = "title";
    /** column name */
    PlanUpdateColumn["Type"] = "type";
    /** column name */
    PlanUpdateColumn["UpdatedAt"] = "updated_at";
})(PlanUpdateColumn || (PlanUpdateColumn = {}));
/** https://stripe.com/docs/api/prices/object#price_object-recurring-interval */
export var PriceReccuringIntervalEnum;
(function (PriceReccuringIntervalEnum) {
    PriceReccuringIntervalEnum["Day"] = "DAY";
    PriceReccuringIntervalEnum["Month"] = "MONTH";
    PriceReccuringIntervalEnum["Week"] = "WEEK";
    PriceReccuringIntervalEnum["Year"] = "YEAR";
})(PriceReccuringIntervalEnum || (PriceReccuringIntervalEnum = {}));
/** unique or primary key constraints on table "pl.reminder" */
export var ReminderConstraint;
(function (ReminderConstraint) {
    /** unique or primary key constraint on columns "id" */
    ReminderConstraint["ReminderPkey"] = "reminder_pkey";
})(ReminderConstraint || (ReminderConstraint = {}));
/** unique or primary key constraints on table "pl.reminder_interval" */
export var ReminderIntervalConstraint;
(function (ReminderIntervalConstraint) {
    /** unique or primary key constraint on columns "value" */
    ReminderIntervalConstraint["ReminderIntervalPkey"] = "reminder_interval_pkey";
})(ReminderIntervalConstraint || (ReminderIntervalConstraint = {}));
export var ReminderIntervalEnum;
(function (ReminderIntervalEnum) {
    /** Daily */
    ReminderIntervalEnum["Daily"] = "DAILY";
    /** Every 4th month */
    ReminderIntervalEnum["EveryFourthMonth"] = "EVERY_FOURTH_MONTH";
    /** Every 2nd month */
    ReminderIntervalEnum["EverySecondMonth"] = "EVERY_SECOND_MONTH";
    /** Every 2nd week */
    ReminderIntervalEnum["EverySecondWeek"] = "EVERY_SECOND_WEEK";
    /** Every 6th month */
    ReminderIntervalEnum["EverySixthMonth"] = "EVERY_SIXTH_MONTH";
    /** Monthly */
    ReminderIntervalEnum["Monthly"] = "MONTHLY";
    /** Weekly */
    ReminderIntervalEnum["Weekly"] = "WEEKLY";
    /** Yearly */
    ReminderIntervalEnum["Yearly"] = "YEARLY";
})(ReminderIntervalEnum || (ReminderIntervalEnum = {}));
/** select columns of table "pl.reminder_interval" */
export var ReminderIntervalSelectColumn;
(function (ReminderIntervalSelectColumn) {
    /** column name */
    ReminderIntervalSelectColumn["Description"] = "description";
    /** column name */
    ReminderIntervalSelectColumn["Value"] = "value";
})(ReminderIntervalSelectColumn || (ReminderIntervalSelectColumn = {}));
/** update columns of table "pl.reminder_interval" */
export var ReminderIntervalUpdateColumn;
(function (ReminderIntervalUpdateColumn) {
    /** column name */
    ReminderIntervalUpdateColumn["Description"] = "description";
    /** column name */
    ReminderIntervalUpdateColumn["Value"] = "value";
})(ReminderIntervalUpdateColumn || (ReminderIntervalUpdateColumn = {}));
/** select columns of table "pl.reminder" */
export var ReminderSelectColumn;
(function (ReminderSelectColumn) {
    /** column name */
    ReminderSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    ReminderSelectColumn["DueDate"] = "due_date";
    /** column name */
    ReminderSelectColumn["Id"] = "id";
    /** column name */
    ReminderSelectColumn["Interval"] = "interval";
    /** column name */
    ReminderSelectColumn["RecipientEmail"] = "recipient_email";
    /** column name */
    ReminderSelectColumn["TaskId"] = "task_id";
    /** column name */
    ReminderSelectColumn["UpdatedAt"] = "updated_at";
})(ReminderSelectColumn || (ReminderSelectColumn = {}));
/** update columns of table "pl.reminder" */
export var ReminderUpdateColumn;
(function (ReminderUpdateColumn) {
    /** column name */
    ReminderUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    ReminderUpdateColumn["DueDate"] = "due_date";
    /** column name */
    ReminderUpdateColumn["Id"] = "id";
    /** column name */
    ReminderUpdateColumn["Interval"] = "interval";
    /** column name */
    ReminderUpdateColumn["RecipientEmail"] = "recipient_email";
    /** column name */
    ReminderUpdateColumn["TaskId"] = "task_id";
    /** column name */
    ReminderUpdateColumn["UpdatedAt"] = "updated_at";
})(ReminderUpdateColumn || (ReminderUpdateColumn = {}));
export var ReviewFeedbackCriticality;
(function (ReviewFeedbackCriticality) {
    ReviewFeedbackCriticality["Critical"] = "Critical";
    ReviewFeedbackCriticality["Minor"] = "Minor";
    ReviewFeedbackCriticality["Notable"] = "Notable";
})(ReviewFeedbackCriticality || (ReviewFeedbackCriticality = {}));
export var ReviewFeedbackStatus;
(function (ReviewFeedbackStatus) {
    ReviewFeedbackStatus["Accepted"] = "Accepted";
    ReviewFeedbackStatus["Discarded"] = "Discarded";
    ReviewFeedbackStatus["Pending"] = "Pending";
})(ReviewFeedbackStatus || (ReviewFeedbackStatus = {}));
export var ReviewStatus;
(function (ReviewStatus) {
    ReviewStatus["Cancelled"] = "Cancelled";
    ReviewStatus["Cancelling"] = "Cancelling";
    ReviewStatus["Done"] = "Done";
    ReviewStatus["Error"] = "Error";
    ReviewStatus["Loading"] = "Loading";
})(ReviewStatus || (ReviewStatus = {}));
export var SearchAggregationDateInterval;
(function (SearchAggregationDateInterval) {
    SearchAggregationDateInterval["Day"] = "day";
    SearchAggregationDateInterval["Month"] = "month";
    SearchAggregationDateInterval["Quarter"] = "quarter";
    SearchAggregationDateInterval["Week"] = "week";
    SearchAggregationDateInterval["Year"] = "year";
})(SearchAggregationDateInterval || (SearchAggregationDateInterval = {}));
export var SearchAggregationMetricType;
(function (SearchAggregationMetricType) {
    SearchAggregationMetricType["Avg"] = "avg";
    SearchAggregationMetricType["Cardinality"] = "cardinality";
    SearchAggregationMetricType["Max"] = "max";
    SearchAggregationMetricType["Min"] = "min";
    SearchAggregationMetricType["Sum"] = "sum";
    SearchAggregationMetricType["ValueCount"] = "value_count";
})(SearchAggregationMetricType || (SearchAggregationMetricType = {}));
export var SearchAggregationTimeUnit;
(function (SearchAggregationTimeUnit) {
    SearchAggregationTimeUnit["Day"] = "day";
    SearchAggregationTimeUnit["Month"] = "month";
    SearchAggregationTimeUnit["Week"] = "week";
    SearchAggregationTimeUnit["Year"] = "year";
})(SearchAggregationTimeUnit || (SearchAggregationTimeUnit = {}));
export var SearchAggregationType;
(function (SearchAggregationType) {
    SearchAggregationType["DateHistogram"] = "dateHistogram";
    SearchAggregationType["DateRange"] = "dateRange";
    SearchAggregationType["Histogram"] = "histogram";
    SearchAggregationType["Metric"] = "metric";
    SearchAggregationType["Range"] = "range";
    SearchAggregationType["Terms"] = "terms";
    SearchAggregationType["TopHits"] = "topHits";
})(SearchAggregationType || (SearchAggregationType = {}));
export var SearchAggregationsAllowedPropertiesForDateHistogramAggregation;
(function (SearchAggregationsAllowedPropertiesForDateHistogramAggregation) {
    SearchAggregationsAllowedPropertiesForDateHistogramAggregation["CreatedAt"] = "createdAt";
    SearchAggregationsAllowedPropertiesForDateHistogramAggregation["MetadataDocumentDateSigned"] = "metadataDocumentDateSigned";
    SearchAggregationsAllowedPropertiesForDateHistogramAggregation["MetadataDocumentNoticeDate"] = "metadataDocumentNoticeDate";
    SearchAggregationsAllowedPropertiesForDateHistogramAggregation["MetadataDocumentProbationaryPeriodExpirationDate"] = "metadataDocumentProbationaryPeriodExpirationDate";
    SearchAggregationsAllowedPropertiesForDateHistogramAggregation["MetadataDocumentStartDate"] = "metadataDocumentStartDate";
    SearchAggregationsAllowedPropertiesForDateHistogramAggregation["MetadataDocumentTerminationDate"] = "metadataDocumentTerminationDate";
    SearchAggregationsAllowedPropertiesForDateHistogramAggregation["MetadataTaskDueDate"] = "metadataTaskDueDate";
    SearchAggregationsAllowedPropertiesForDateHistogramAggregation["UpdatedAt"] = "updatedAt";
})(SearchAggregationsAllowedPropertiesForDateHistogramAggregation || (SearchAggregationsAllowedPropertiesForDateHistogramAggregation = {}));
export var SearchAggregationsAllowedPropertiesForDateRangeAggregation;
(function (SearchAggregationsAllowedPropertiesForDateRangeAggregation) {
    SearchAggregationsAllowedPropertiesForDateRangeAggregation["CreatedAt"] = "createdAt";
    SearchAggregationsAllowedPropertiesForDateRangeAggregation["MetadataDocumentDateSigned"] = "metadataDocumentDateSigned";
    SearchAggregationsAllowedPropertiesForDateRangeAggregation["MetadataDocumentNoticeDate"] = "metadataDocumentNoticeDate";
    SearchAggregationsAllowedPropertiesForDateRangeAggregation["MetadataDocumentProbationaryPeriodExpirationDate"] = "metadataDocumentProbationaryPeriodExpirationDate";
    SearchAggregationsAllowedPropertiesForDateRangeAggregation["MetadataDocumentStartDate"] = "metadataDocumentStartDate";
    SearchAggregationsAllowedPropertiesForDateRangeAggregation["MetadataDocumentTerminationDate"] = "metadataDocumentTerminationDate";
    SearchAggregationsAllowedPropertiesForDateRangeAggregation["MetadataTaskDueDate"] = "metadataTaskDueDate";
    SearchAggregationsAllowedPropertiesForDateRangeAggregation["UpdatedAt"] = "updatedAt";
})(SearchAggregationsAllowedPropertiesForDateRangeAggregation || (SearchAggregationsAllowedPropertiesForDateRangeAggregation = {}));
export var SearchAggregationsAllowedPropertiesForHistogramAggregation;
(function (SearchAggregationsAllowedPropertiesForHistogramAggregation) {
    SearchAggregationsAllowedPropertiesForHistogramAggregation["EntityId"] = "entityId";
    SearchAggregationsAllowedPropertiesForHistogramAggregation["MetadataDocumentLiabilityCap"] = "metadataDocumentLiabilityCap";
    SearchAggregationsAllowedPropertiesForHistogramAggregation["MetadataDocumentNoticePeriodDays"] = "metadataDocumentNoticePeriodDays";
    SearchAggregationsAllowedPropertiesForHistogramAggregation["MetadataDocumentNoticePeriodMonths"] = "metadataDocumentNoticePeriodMonths";
    SearchAggregationsAllowedPropertiesForHistogramAggregation["MetadataDocumentOriginalTermMonths"] = "metadataDocumentOriginalTermMonths";
    SearchAggregationsAllowedPropertiesForHistogramAggregation["MetadataDocumentPaymentTermsDaysFromInvoiceDate"] = "metadataDocumentPaymentTermsDaysFromInvoiceDate";
    SearchAggregationsAllowedPropertiesForHistogramAggregation["MetadataDocumentRenewalTermMonths"] = "metadataDocumentRenewalTermMonths";
    SearchAggregationsAllowedPropertiesForHistogramAggregation["MetadataDocumentTotalContractCost"] = "metadataDocumentTotalContractCost";
    SearchAggregationsAllowedPropertiesForHistogramAggregation["MetadataDocumentTotalContractValue"] = "metadataDocumentTotalContractValue";
})(SearchAggregationsAllowedPropertiesForHistogramAggregation || (SearchAggregationsAllowedPropertiesForHistogramAggregation = {}));
export var SearchAggregationsAllowedPropertiesForMetricAggregation;
(function (SearchAggregationsAllowedPropertiesForMetricAggregation) {
    SearchAggregationsAllowedPropertiesForMetricAggregation["EntityId"] = "entityId";
    SearchAggregationsAllowedPropertiesForMetricAggregation["MetadataDocumentLiabilityCap"] = "metadataDocumentLiabilityCap";
    SearchAggregationsAllowedPropertiesForMetricAggregation["MetadataDocumentNoticePeriodDays"] = "metadataDocumentNoticePeriodDays";
    SearchAggregationsAllowedPropertiesForMetricAggregation["MetadataDocumentNoticePeriodMonths"] = "metadataDocumentNoticePeriodMonths";
    SearchAggregationsAllowedPropertiesForMetricAggregation["MetadataDocumentOriginalTermMonths"] = "metadataDocumentOriginalTermMonths";
    SearchAggregationsAllowedPropertiesForMetricAggregation["MetadataDocumentPaymentTermsDaysFromInvoiceDate"] = "metadataDocumentPaymentTermsDaysFromInvoiceDate";
    SearchAggregationsAllowedPropertiesForMetricAggregation["MetadataDocumentRenewalTermMonths"] = "metadataDocumentRenewalTermMonths";
    SearchAggregationsAllowedPropertiesForMetricAggregation["MetadataDocumentTotalContractCost"] = "metadataDocumentTotalContractCost";
    SearchAggregationsAllowedPropertiesForMetricAggregation["MetadataDocumentTotalContractValue"] = "metadataDocumentTotalContractValue";
})(SearchAggregationsAllowedPropertiesForMetricAggregation || (SearchAggregationsAllowedPropertiesForMetricAggregation = {}));
export var SearchAggregationsAllowedPropertiesForRangeAggregation;
(function (SearchAggregationsAllowedPropertiesForRangeAggregation) {
    SearchAggregationsAllowedPropertiesForRangeAggregation["EntityId"] = "entityId";
    SearchAggregationsAllowedPropertiesForRangeAggregation["MetadataDocumentLiabilityCap"] = "metadataDocumentLiabilityCap";
    SearchAggregationsAllowedPropertiesForRangeAggregation["MetadataDocumentNoticePeriodDays"] = "metadataDocumentNoticePeriodDays";
    SearchAggregationsAllowedPropertiesForRangeAggregation["MetadataDocumentNoticePeriodMonths"] = "metadataDocumentNoticePeriodMonths";
    SearchAggregationsAllowedPropertiesForRangeAggregation["MetadataDocumentOriginalTermMonths"] = "metadataDocumentOriginalTermMonths";
    SearchAggregationsAllowedPropertiesForRangeAggregation["MetadataDocumentPaymentTermsDaysFromInvoiceDate"] = "metadataDocumentPaymentTermsDaysFromInvoiceDate";
    SearchAggregationsAllowedPropertiesForRangeAggregation["MetadataDocumentRenewalTermMonths"] = "metadataDocumentRenewalTermMonths";
    SearchAggregationsAllowedPropertiesForRangeAggregation["MetadataDocumentTotalContractCost"] = "metadataDocumentTotalContractCost";
    SearchAggregationsAllowedPropertiesForRangeAggregation["MetadataDocumentTotalContractValue"] = "metadataDocumentTotalContractValue";
})(SearchAggregationsAllowedPropertiesForRangeAggregation || (SearchAggregationsAllowedPropertiesForRangeAggregation = {}));
export var SearchAggregationsAllowedPropertiesForTermsAggregation;
(function (SearchAggregationsAllowedPropertiesForTermsAggregation) {
    SearchAggregationsAllowedPropertiesForTermsAggregation["CategoryId"] = "categoryId";
    SearchAggregationsAllowedPropertiesForTermsAggregation["CreatedByUserId"] = "createdByUserId";
    SearchAggregationsAllowedPropertiesForTermsAggregation["EntityType"] = "entityType";
    SearchAggregationsAllowedPropertiesForTermsAggregation["IsPlGenerated"] = "isPlGenerated";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MarketCode"] = "marketCode";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MarketLocaleCode"] = "marketLocaleCode";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataDocumentActive"] = "metadataDocumentActive";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataDocumentAutomaticRenewal"] = "metadataDocumentAutomaticRenewal";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataDocumentContractOwner"] = "metadataDocumentContractOwner";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataDocumentCountry"] = "metadataDocumentCountry";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataDocumentCurrency"] = "metadataDocumentCurrency";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataDocumentDeviateFromStandard"] = "metadataDocumentDeviateFromStandard";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataDocumentDiscount"] = "metadataDocumentDiscount";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataDocumentDocumentCategory"] = "metadataDocumentDocumentCategory";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataDocumentDocumentType"] = "metadataDocumentDocumentType";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataDocumentEsigningStatus"] = "metadataDocumentEsigningStatus";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataDocumentFeeAdjustmentClause"] = "metadataDocumentFeeAdjustmentClause";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataDocumentGoverningLaw"] = "metadataDocumentGoverningLaw";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataDocumentIndemnity"] = "metadataDocumentIndemnity";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataDocumentIsSharedExternally"] = "metadataDocumentIsSharedExternally";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataDocumentLiabilityCapScope"] = "metadataDocumentLiabilityCapScope";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataDocumentLimitationOfLiability"] = "metadataDocumentLimitationOfLiability";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataDocumentMaterialAgreement"] = "metadataDocumentMaterialAgreement";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataDocumentOutsourcing"] = "metadataDocumentOutsourcing";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataDocumentParties"] = "metadataDocumentParties";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataDocumentPostTerminationObligations"] = "metadataDocumentPostTerminationObligations";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataDocumentProbationaryPeriod"] = "metadataDocumentProbationaryPeriod";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataDocumentProcessingOfPersonalData"] = "metadataDocumentProcessingOfPersonalData";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataDocumentStatus"] = "metadataDocumentStatus";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataDocumentTerminationForCause"] = "metadataDocumentTerminationForCause";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataDocumentTerminationForConvenience"] = "metadataDocumentTerminationForConvenience";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataDocumentType"] = "metadataDocumentType";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataEntryPath"] = "metadataEntryPath";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataTaskIsPublic"] = "metadataTaskIsPublic";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataTaskStatus"] = "metadataTaskStatus";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataTemplateComposerId"] = "metadataTemplateComposerId";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataTemplateMarketLocaleCode"] = "metadataTemplateMarketLocaleCode";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataUserEmail"] = "metadataUserEmail";
    SearchAggregationsAllowedPropertiesForTermsAggregation["MetadataUserRole"] = "metadataUserRole";
})(SearchAggregationsAllowedPropertiesForTermsAggregation || (SearchAggregationsAllowedPropertiesForTermsAggregation = {}));
export var SearchDocumentTypeEnum;
(function (SearchDocumentTypeEnum) {
    SearchDocumentTypeEnum["Composed"] = "COMPOSED";
    SearchDocumentTypeEnum["Freetext"] = "FREETEXT";
    SearchDocumentTypeEnum["Uploaded"] = "UPLOADED";
})(SearchDocumentTypeEnum || (SearchDocumentTypeEnum = {}));
export var SearchEntityTypeEnum;
(function (SearchEntityTypeEnum) {
    SearchEntityTypeEnum["Document"] = "document";
    SearchEntityTypeEnum["DocumentComposed"] = "document_composed";
    SearchEntityTypeEnum["DocumentFreetext"] = "document_freetext";
    SearchEntityTypeEnum["DocumentUploaded"] = "document_uploaded";
    SearchEntityTypeEnum["Draft"] = "draft";
    SearchEntityTypeEnum["Folder"] = "folder";
    SearchEntityTypeEnum["Task"] = "task";
    SearchEntityTypeEnum["Template"] = "template";
    SearchEntityTypeEnum["User"] = "user";
})(SearchEntityTypeEnum || (SearchEntityTypeEnum = {}));
export var SearchFeature;
(function (SearchFeature) {
    SearchFeature["AiCharts"] = "AI_CHARTS";
})(SearchFeature || (SearchFeature = {}));
export var SearchInsightsWidgetTypeEnum;
(function (SearchInsightsWidgetTypeEnum) {
    SearchInsightsWidgetTypeEnum["Barchart"] = "barchart";
    SearchInsightsWidgetTypeEnum["Count"] = "count";
    SearchInsightsWidgetTypeEnum["Doughnut"] = "doughnut";
    SearchInsightsWidgetTypeEnum["Linechart"] = "linechart";
    SearchInsightsWidgetTypeEnum["Table"] = "table";
})(SearchInsightsWidgetTypeEnum || (SearchInsightsWidgetTypeEnum = {}));
export var SearchMarketLocaleCodeEnum;
(function (SearchMarketLocaleCodeEnum) {
    SearchMarketLocaleCodeEnum["De"] = "de";
    SearchMarketLocaleCodeEnum["En"] = "en";
    SearchMarketLocaleCodeEnum["Nb"] = "nb";
    SearchMarketLocaleCodeEnum["Sv"] = "sv";
})(SearchMarketLocaleCodeEnum || (SearchMarketLocaleCodeEnum = {}));
export var SearchTextFieldAllowedPropertiesForTextFieldSearch;
(function (SearchTextFieldAllowedPropertiesForTextFieldSearch) {
    SearchTextFieldAllowedPropertiesForTextFieldSearch["AllApplicable"] = "_allApplicable";
    SearchTextFieldAllowedPropertiesForTextFieldSearch["Body"] = "body";
    SearchTextFieldAllowedPropertiesForTextFieldSearch["Description"] = "description";
    SearchTextFieldAllowedPropertiesForTextFieldSearch["MetadataDocumentCountry"] = "metadataDocumentCountry";
    SearchTextFieldAllowedPropertiesForTextFieldSearch["MetadataDocumentCurrency"] = "metadataDocumentCurrency";
    SearchTextFieldAllowedPropertiesForTextFieldSearch["MetadataDocumentDocumentCategory"] = "metadataDocumentDocumentCategory";
    SearchTextFieldAllowedPropertiesForTextFieldSearch["MetadataDocumentDocumentType"] = "metadataDocumentDocumentType";
    SearchTextFieldAllowedPropertiesForTextFieldSearch["MetadataDocumentEsigningStatus"] = "metadataDocumentEsigningStatus";
    SearchTextFieldAllowedPropertiesForTextFieldSearch["MetadataDocumentGoverningLaw"] = "metadataDocumentGoverningLaw";
    SearchTextFieldAllowedPropertiesForTextFieldSearch["MetadataDocumentNotes"] = "metadataDocumentNotes";
    SearchTextFieldAllowedPropertiesForTextFieldSearch["MetadataDocumentParties"] = "metadataDocumentParties";
    SearchTextFieldAllowedPropertiesForTextFieldSearch["MetadataDocumentProcessingOfPersonalData"] = "metadataDocumentProcessingOfPersonalData";
    SearchTextFieldAllowedPropertiesForTextFieldSearch["MetadataUserEmail"] = "metadataUserEmail";
    SearchTextFieldAllowedPropertiesForTextFieldSearch["Name"] = "name";
})(SearchTextFieldAllowedPropertiesForTextFieldSearch || (SearchTextFieldAllowedPropertiesForTextFieldSearch = {}));
export var SearchTimeUnit;
(function (SearchTimeUnit) {
    SearchTimeUnit["Day"] = "day";
    SearchTimeUnit["Month"] = "month";
    SearchTimeUnit["Week"] = "week";
    SearchTimeUnit["Year"] = "year";
})(SearchTimeUnit || (SearchTimeUnit = {}));
export var SearchTypeEnum;
(function (SearchTypeEnum) {
    SearchTypeEnum["All"] = "all";
    SearchTypeEnum["Any"] = "any";
    SearchTypeEnum["AutocompleteName"] = "autocompleteName";
    SearchTypeEnum["ExactName"] = "exactName";
    SearchTypeEnum["Phrase"] = "phrase";
})(SearchTypeEnum || (SearchTypeEnum = {}));
/** unique or primary key constraints on table "pl.sign_request" */
export var SignRequestConstraint;
(function (SignRequestConstraint) {
    /** unique or primary key constraint on columns "id" */
    SignRequestConstraint["SignRequestPkey"] = "sign_request_pkey";
})(SignRequestConstraint || (SignRequestConstraint = {}));
/** select columns of table "pl.sign_request" */
export var SignRequestSelectColumn;
(function (SignRequestSelectColumn) {
    /** column name */
    SignRequestSelectColumn["CcRecipients"] = "cc_recipients";
    /** column name */
    SignRequestSelectColumn["CompanyId"] = "company_id";
    /** column name */
    SignRequestSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    SignRequestSelectColumn["EsigningProviderId"] = "esigning_provider_id";
    /** column name */
    SignRequestSelectColumn["FileId"] = "file_id";
    /** column name */
    SignRequestSelectColumn["FileSystemDocumentId"] = "file_system_document_id";
    /** column name */
    SignRequestSelectColumn["HasAutomaticReminders"] = "has_automatic_reminders";
    /** column name */
    SignRequestSelectColumn["Id"] = "id";
    /** column name */
    SignRequestSelectColumn["Message"] = "message";
    /** column name */
    SignRequestSelectColumn["Signatories"] = "signatories";
    /** column name */
    SignRequestSelectColumn["SignedAt"] = "signed_at";
    /** column name */
    SignRequestSelectColumn["SourceId"] = "source_id";
    /** column name */
    SignRequestSelectColumn["Status"] = "status";
    /** column name */
    SignRequestSelectColumn["StatusSubcode"] = "status_subcode";
    /** column name */
    SignRequestSelectColumn["UpdatedAt"] = "updated_at";
    /** column name */
    SignRequestSelectColumn["UserId"] = "user_id";
})(SignRequestSelectColumn || (SignRequestSelectColumn = {}));
export var SignRequestStatus;
(function (SignRequestStatus) {
    SignRequestStatus["Cancelled"] = "CANCELLED";
    SignRequestStatus["Cancelling"] = "CANCELLING";
    SignRequestStatus["Creating"] = "CREATING";
    SignRequestStatus["Failed"] = "FAILED";
    SignRequestStatus["Opened"] = "OPENED";
    SignRequestStatus["Pending"] = "PENDING";
    SignRequestStatus["Rejected"] = "REJECTED";
    SignRequestStatus["Signed"] = "SIGNED";
    SignRequestStatus["Unknown"] = "UNKNOWN";
})(SignRequestStatus || (SignRequestStatus = {}));
/** update columns of table "pl.sign_request" */
export var SignRequestUpdateColumn;
(function (SignRequestUpdateColumn) {
    /** column name */
    SignRequestUpdateColumn["CcRecipients"] = "cc_recipients";
    /** column name */
    SignRequestUpdateColumn["CompanyId"] = "company_id";
    /** column name */
    SignRequestUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    SignRequestUpdateColumn["EsigningProviderId"] = "esigning_provider_id";
    /** column name */
    SignRequestUpdateColumn["FileId"] = "file_id";
    /** column name */
    SignRequestUpdateColumn["FileSystemDocumentId"] = "file_system_document_id";
    /** column name */
    SignRequestUpdateColumn["HasAutomaticReminders"] = "has_automatic_reminders";
    /** column name */
    SignRequestUpdateColumn["Id"] = "id";
    /** column name */
    SignRequestUpdateColumn["Message"] = "message";
    /** column name */
    SignRequestUpdateColumn["Signatories"] = "signatories";
    /** column name */
    SignRequestUpdateColumn["SignedAt"] = "signed_at";
    /** column name */
    SignRequestUpdateColumn["SourceId"] = "source_id";
    /** column name */
    SignRequestUpdateColumn["Status"] = "status";
    /** column name */
    SignRequestUpdateColumn["StatusSubcode"] = "status_subcode";
    /** column name */
    SignRequestUpdateColumn["UpdatedAt"] = "updated_at";
    /** column name */
    SignRequestUpdateColumn["UserId"] = "user_id";
})(SignRequestUpdateColumn || (SignRequestUpdateColumn = {}));
/** unique or primary key constraints on table "pl.sign_status_enum" */
export var SignStatusEnumConstraint;
(function (SignStatusEnumConstraint) {
    /** unique or primary key constraint on columns "value" */
    SignStatusEnumConstraint["SignStatusEnumPkey"] = "sign_status_enum_pkey";
})(SignStatusEnumConstraint || (SignStatusEnumConstraint = {}));
export var SignStatusEnumEnum;
(function (SignStatusEnumEnum) {
    /** Cancelled */
    SignStatusEnumEnum["Cancelled"] = "CANCELLED";
    /** Cancelling */
    SignStatusEnumEnum["Cancelling"] = "CANCELLING";
    /** Creating */
    SignStatusEnumEnum["Creating"] = "CREATING";
    /** Failed */
    SignStatusEnumEnum["Failed"] = "FAILED";
    /** Opened */
    SignStatusEnumEnum["Opened"] = "OPENED";
    /** Pending */
    SignStatusEnumEnum["Pending"] = "PENDING";
    /** Rejected */
    SignStatusEnumEnum["Rejected"] = "REJECTED";
    /** Signed */
    SignStatusEnumEnum["Signed"] = "SIGNED";
    /** Unknown */
    SignStatusEnumEnum["Unknown"] = "UNKNOWN";
})(SignStatusEnumEnum || (SignStatusEnumEnum = {}));
/** select columns of table "pl.sign_status_enum" */
export var SignStatusEnumSelectColumn;
(function (SignStatusEnumSelectColumn) {
    /** column name */
    SignStatusEnumSelectColumn["Comment"] = "comment";
    /** column name */
    SignStatusEnumSelectColumn["Value"] = "value";
})(SignStatusEnumSelectColumn || (SignStatusEnumSelectColumn = {}));
/** update columns of table "pl.sign_status_enum" */
export var SignStatusEnumUpdateColumn;
(function (SignStatusEnumUpdateColumn) {
    /** column name */
    SignStatusEnumUpdateColumn["Comment"] = "comment";
    /** column name */
    SignStatusEnumUpdateColumn["Value"] = "value";
})(SignStatusEnumUpdateColumn || (SignStatusEnumUpdateColumn = {}));
export var SignatoryStatus;
(function (SignatoryStatus) {
    SignatoryStatus["Draft"] = "DRAFT";
    SignatoryStatus["Failed"] = "FAILED";
    SignatoryStatus["Opened"] = "OPENED";
    SignatoryStatus["Pending"] = "PENDING";
    SignatoryStatus["Rejected"] = "REJECTED";
    SignatoryStatus["Signed"] = "SIGNED";
    SignatoryStatus["Unknown"] = "UNKNOWN";
})(SignatoryStatus || (SignatoryStatus = {}));
export var StringFormat;
(function (StringFormat) {
    StringFormat["Html"] = "HTML";
    StringFormat["Markdown"] = "MARKDOWN";
    StringFormat["Text"] = "TEXT";
})(StringFormat || (StringFormat = {}));
/** unique or primary key constraints on table "pl.task_assignee" */
export var TaskAssigneeConstraint;
(function (TaskAssigneeConstraint) {
    /** unique or primary key constraint on columns "id" */
    TaskAssigneeConstraint["TaskAssigneePkey"] = "task_assignee_pkey";
    /** unique or primary key constraint on columns "task_id", "assignee_id" */
    TaskAssigneeConstraint["TaskAssigneeTaskIdAssigneeIdKey"] = "task_assignee_task_id_assignee_id_key";
})(TaskAssigneeConstraint || (TaskAssigneeConstraint = {}));
/** select columns of table "pl.task_assignee" */
export var TaskAssigneeSelectColumn;
(function (TaskAssigneeSelectColumn) {
    /** column name */
    TaskAssigneeSelectColumn["AssigneeId"] = "assignee_id";
    /** column name */
    TaskAssigneeSelectColumn["Id"] = "id";
    /** column name */
    TaskAssigneeSelectColumn["TaskId"] = "task_id";
})(TaskAssigneeSelectColumn || (TaskAssigneeSelectColumn = {}));
/** update columns of table "pl.task_assignee" */
export var TaskAssigneeUpdateColumn;
(function (TaskAssigneeUpdateColumn) {
    /** column name */
    TaskAssigneeUpdateColumn["AssigneeId"] = "assignee_id";
    /** column name */
    TaskAssigneeUpdateColumn["Id"] = "id";
    /** column name */
    TaskAssigneeUpdateColumn["TaskId"] = "task_id";
})(TaskAssigneeUpdateColumn || (TaskAssigneeUpdateColumn = {}));
/** unique or primary key constraints on table "pl.task" */
export var TaskConstraint;
(function (TaskConstraint) {
    /** unique or primary key constraint on columns "id" */
    TaskConstraint["TaskPkey"] = "task_pkey";
})(TaskConstraint || (TaskConstraint = {}));
/** unique or primary key constraints on table "pl.task_document" */
export var TaskDocumentConstraint;
(function (TaskDocumentConstraint) {
    /** unique or primary key constraint on columns "id" */
    TaskDocumentConstraint["TaskDocumentPkey"] = "task_document_pkey";
})(TaskDocumentConstraint || (TaskDocumentConstraint = {}));
/** select columns of table "pl.task_document" */
export var TaskDocumentSelectColumn;
(function (TaskDocumentSelectColumn) {
    /** column name */
    TaskDocumentSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    TaskDocumentSelectColumn["DocumentDeletedAt"] = "document_deleted_at";
    /** column name */
    TaskDocumentSelectColumn["DocumentDeletedByUserId"] = "document_deleted_by_user_id";
    /** column name */
    TaskDocumentSelectColumn["DocumentId"] = "document_id";
    /** column name */
    TaskDocumentSelectColumn["Id"] = "id";
    /** column name */
    TaskDocumentSelectColumn["TaskId"] = "task_id";
})(TaskDocumentSelectColumn || (TaskDocumentSelectColumn = {}));
/** update columns of table "pl.task_document" */
export var TaskDocumentUpdateColumn;
(function (TaskDocumentUpdateColumn) {
    /** column name */
    TaskDocumentUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    TaskDocumentUpdateColumn["DocumentDeletedAt"] = "document_deleted_at";
    /** column name */
    TaskDocumentUpdateColumn["DocumentDeletedByUserId"] = "document_deleted_by_user_id";
    /** column name */
    TaskDocumentUpdateColumn["DocumentId"] = "document_id";
    /** column name */
    TaskDocumentUpdateColumn["Id"] = "id";
    /** column name */
    TaskDocumentUpdateColumn["TaskId"] = "task_id";
})(TaskDocumentUpdateColumn || (TaskDocumentUpdateColumn = {}));
/** select columns of table "pl.task_only_creator" */
export var TaskOnlyCreatorSelectColumn;
(function (TaskOnlyCreatorSelectColumn) {
    /** column name */
    TaskOnlyCreatorSelectColumn["CreatorId"] = "creator_id";
    /** column name */
    TaskOnlyCreatorSelectColumn["IsPublic"] = "is_public";
    /** column name */
    TaskOnlyCreatorSelectColumn["TaskId"] = "task_id";
})(TaskOnlyCreatorSelectColumn || (TaskOnlyCreatorSelectColumn = {}));
/** select columns of table "pl.task" */
export var TaskSelectColumn;
(function (TaskSelectColumn) {
    /** column name */
    TaskSelectColumn["AssigneeId"] = "assignee_id";
    /** column name */
    TaskSelectColumn["CompanyId"] = "company_id";
    /** column name */
    TaskSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    TaskSelectColumn["CreatorId"] = "creator_id";
    /** column name */
    TaskSelectColumn["DeletedAt"] = "deleted_at";
    /** column name */
    TaskSelectColumn["Description"] = "description";
    /** column name */
    TaskSelectColumn["DueDate"] = "due_date";
    /** column name */
    TaskSelectColumn["Id"] = "id";
    /** column name */
    TaskSelectColumn["IsCompleted"] = "is_completed";
    /** column name */
    TaskSelectColumn["IsPublic"] = "is_public";
    /** column name */
    TaskSelectColumn["Status"] = "status";
    /** column name */
    TaskSelectColumn["Title"] = "title";
    /** column name */
    TaskSelectColumn["UpdatedAt"] = "updated_at";
})(TaskSelectColumn || (TaskSelectColumn = {}));
/** unique or primary key constraints on table "pl.task_status_enum" */
export var TaskStatusEnumConstraint;
(function (TaskStatusEnumConstraint) {
    /** unique or primary key constraint on columns "name" */
    TaskStatusEnumConstraint["TaskStatusEnumPkey"] = "task_status_enum_pkey";
})(TaskStatusEnumConstraint || (TaskStatusEnumConstraint = {}));
export var TaskStatusEnumEnum;
(function (TaskStatusEnumEnum) {
    /** Completed */
    TaskStatusEnumEnum["Completed"] = "completed";
    /** In Progress */
    TaskStatusEnumEnum["InProgress"] = "in_progress";
    /** Todo */
    TaskStatusEnumEnum["Todo"] = "todo";
})(TaskStatusEnumEnum || (TaskStatusEnumEnum = {}));
/** select columns of table "pl.task_status_enum" */
export var TaskStatusEnumSelectColumn;
(function (TaskStatusEnumSelectColumn) {
    /** column name */
    TaskStatusEnumSelectColumn["Description"] = "description";
    /** column name */
    TaskStatusEnumSelectColumn["Name"] = "name";
})(TaskStatusEnumSelectColumn || (TaskStatusEnumSelectColumn = {}));
/** update columns of table "pl.task_status_enum" */
export var TaskStatusEnumUpdateColumn;
(function (TaskStatusEnumUpdateColumn) {
    /** column name */
    TaskStatusEnumUpdateColumn["Description"] = "description";
    /** column name */
    TaskStatusEnumUpdateColumn["Name"] = "name";
})(TaskStatusEnumUpdateColumn || (TaskStatusEnumUpdateColumn = {}));
/** unique or primary key constraints on table "pl.task_template" */
export var TaskTemplateConstraint;
(function (TaskTemplateConstraint) {
    /** unique or primary key constraint on columns "id" */
    TaskTemplateConstraint["TaskTemplatePkey"] = "task_template_pkey";
})(TaskTemplateConstraint || (TaskTemplateConstraint = {}));
/** select columns of table "pl.task_template" */
export var TaskTemplateSelectColumn;
(function (TaskTemplateSelectColumn) {
    /** column name */
    TaskTemplateSelectColumn["Id"] = "id";
    /** column name */
    TaskTemplateSelectColumn["TaskId"] = "task_id";
    /** column name */
    TaskTemplateSelectColumn["TemplateId"] = "template_id";
})(TaskTemplateSelectColumn || (TaskTemplateSelectColumn = {}));
/** update columns of table "pl.task_template" */
export var TaskTemplateUpdateColumn;
(function (TaskTemplateUpdateColumn) {
    /** column name */
    TaskTemplateUpdateColumn["Id"] = "id";
    /** column name */
    TaskTemplateUpdateColumn["TaskId"] = "task_id";
    /** column name */
    TaskTemplateUpdateColumn["TemplateId"] = "template_id";
})(TaskTemplateUpdateColumn || (TaskTemplateUpdateColumn = {}));
/** update columns of table "pl.task" */
export var TaskUpdateColumn;
(function (TaskUpdateColumn) {
    /** column name */
    TaskUpdateColumn["AssigneeId"] = "assignee_id";
    /** column name */
    TaskUpdateColumn["CompanyId"] = "company_id";
    /** column name */
    TaskUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    TaskUpdateColumn["CreatorId"] = "creator_id";
    /** column name */
    TaskUpdateColumn["DeletedAt"] = "deleted_at";
    /** column name */
    TaskUpdateColumn["Description"] = "description";
    /** column name */
    TaskUpdateColumn["DueDate"] = "due_date";
    /** column name */
    TaskUpdateColumn["Id"] = "id";
    /** column name */
    TaskUpdateColumn["IsCompleted"] = "is_completed";
    /** column name */
    TaskUpdateColumn["IsPublic"] = "is_public";
    /** column name */
    TaskUpdateColumn["Status"] = "status";
    /** column name */
    TaskUpdateColumn["Title"] = "title";
    /** column name */
    TaskUpdateColumn["UpdatedAt"] = "updated_at";
})(TaskUpdateColumn || (TaskUpdateColumn = {}));
export var TeamMemberRole;
(function (TeamMemberRole) {
    TeamMemberRole["Manager"] = "Manager";
    TeamMemberRole["Member"] = "Member";
})(TeamMemberRole || (TeamMemberRole = {}));
/** unique or primary key constraints on table "pl.template" */
export var TemplateConstraint;
(function (TemplateConstraint) {
    /** unique or primary key constraint on columns "id" */
    TemplateConstraint["TemplatePkey"] = "template_pkey";
})(TemplateConstraint || (TemplateConstraint = {}));
/** select columns of table "pl.template" */
export var TemplateSelectColumn;
(function (TemplateSelectColumn) {
    /** column name */
    TemplateSelectColumn["Body"] = "body";
    /** column name */
    TemplateSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    TemplateSelectColumn["Enabled"] = "enabled";
    /** column name */
    TemplateSelectColumn["Id"] = "id";
    /** column name */
    TemplateSelectColumn["MarketCode"] = "market_code";
    /** column name */
    TemplateSelectColumn["MarketLocaleCode"] = "market_locale_code";
    /** column name */
    TemplateSelectColumn["Name"] = "name";
    /** column name */
    TemplateSelectColumn["PreviewFiles"] = "preview_files";
    /** column name */
    TemplateSelectColumn["UpdatedAt"] = "updated_at";
})(TemplateSelectColumn || (TemplateSelectColumn = {}));
/** update columns of table "pl.template" */
export var TemplateUpdateColumn;
(function (TemplateUpdateColumn) {
    /** column name */
    TemplateUpdateColumn["Body"] = "body";
    /** column name */
    TemplateUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    TemplateUpdateColumn["Enabled"] = "enabled";
    /** column name */
    TemplateUpdateColumn["Id"] = "id";
    /** column name */
    TemplateUpdateColumn["MarketCode"] = "market_code";
    /** column name */
    TemplateUpdateColumn["MarketLocaleCode"] = "market_locale_code";
    /** column name */
    TemplateUpdateColumn["Name"] = "name";
    /** column name */
    TemplateUpdateColumn["PreviewFiles"] = "preview_files";
    /** column name */
    TemplateUpdateColumn["UpdatedAt"] = "updated_at";
})(TemplateUpdateColumn || (TemplateUpdateColumn = {}));
/** unique or primary key constraints on table "pl.template_version" */
export var TemplateVersionConstraint;
(function (TemplateVersionConstraint) {
    /** unique or primary key constraint on columns "template_id", "reference" */
    TemplateVersionConstraint["TemplateVersionPkey"] = "template_version_pkey";
})(TemplateVersionConstraint || (TemplateVersionConstraint = {}));
/** select columns of table "pl.template_version" */
export var TemplateVersionSelectColumn;
(function (TemplateVersionSelectColumn) {
    /** column name */
    TemplateVersionSelectColumn["AstBody"] = "ast_body";
    /** column name */
    TemplateVersionSelectColumn["Body"] = "body";
    /** column name */
    TemplateVersionSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    TemplateVersionSelectColumn["Reference"] = "reference";
    /** column name */
    TemplateVersionSelectColumn["TemplateId"] = "template_id";
    /** column name */
    TemplateVersionSelectColumn["UpdatedAt"] = "updated_at";
})(TemplateVersionSelectColumn || (TemplateVersionSelectColumn = {}));
/** update columns of table "pl.template_version" */
export var TemplateVersionUpdateColumn;
(function (TemplateVersionUpdateColumn) {
    /** column name */
    TemplateVersionUpdateColumn["AstBody"] = "ast_body";
    /** column name */
    TemplateVersionUpdateColumn["Body"] = "body";
    /** column name */
    TemplateVersionUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    TemplateVersionUpdateColumn["Reference"] = "reference";
    /** column name */
    TemplateVersionUpdateColumn["TemplateId"] = "template_id";
    /** column name */
    TemplateVersionUpdateColumn["UpdatedAt"] = "updated_at";
})(TemplateVersionUpdateColumn || (TemplateVersionUpdateColumn = {}));
/** unique or primary key constraints on table "pl.topic" */
export var TopicConstraint;
(function (TopicConstraint) {
    /** unique or primary key constraint on columns "id" */
    TopicConstraint["TopicPkey"] = "topic_pkey";
})(TopicConstraint || (TopicConstraint = {}));
/** unique or primary key constraints on table "pl.topic_metadata" */
export var TopicMetadataConstraint;
(function (TopicMetadataConstraint) {
    /** unique or primary key constraint on columns "id" */
    TopicMetadataConstraint["TopicMetadataPkey"] = "topic_metadata_pkey";
    /** unique or primary key constraint on columns "market_locale_code", "topic_id" */
    TopicMetadataConstraint["TopicMetadataTopicIdMarketLocaleCodeKey"] = "topic_metadata_topic_id_market_locale_code_key";
})(TopicMetadataConstraint || (TopicMetadataConstraint = {}));
/** select columns of table "pl.topic_metadata" */
export var TopicMetadataSelectColumn;
(function (TopicMetadataSelectColumn) {
    /** column name */
    TopicMetadataSelectColumn["Description"] = "description";
    /** column name */
    TopicMetadataSelectColumn["Id"] = "id";
    /** column name */
    TopicMetadataSelectColumn["MarketCode"] = "market_code";
    /** column name */
    TopicMetadataSelectColumn["MarketLocaleCode"] = "market_locale_code";
    /** column name */
    TopicMetadataSelectColumn["Title"] = "title";
    /** column name */
    TopicMetadataSelectColumn["TopicId"] = "topic_id";
})(TopicMetadataSelectColumn || (TopicMetadataSelectColumn = {}));
/** update columns of table "pl.topic_metadata" */
export var TopicMetadataUpdateColumn;
(function (TopicMetadataUpdateColumn) {
    /** column name */
    TopicMetadataUpdateColumn["Description"] = "description";
    /** column name */
    TopicMetadataUpdateColumn["Id"] = "id";
    /** column name */
    TopicMetadataUpdateColumn["MarketCode"] = "market_code";
    /** column name */
    TopicMetadataUpdateColumn["MarketLocaleCode"] = "market_locale_code";
    /** column name */
    TopicMetadataUpdateColumn["Title"] = "title";
    /** column name */
    TopicMetadataUpdateColumn["TopicId"] = "topic_id";
})(TopicMetadataUpdateColumn || (TopicMetadataUpdateColumn = {}));
/** select columns of table "pl.topic" */
export var TopicSelectColumn;
(function (TopicSelectColumn) {
    /** column name */
    TopicSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    TopicSelectColumn["Id"] = "id";
    /** column name */
    TopicSelectColumn["MarketCode"] = "market_code";
    /** column name */
    TopicSelectColumn["MarketLocaleId"] = "market_locale_id";
    /** column name */
    TopicSelectColumn["Order"] = "order";
    /** column name */
    TopicSelectColumn["ThemeId"] = "theme_id";
    /** column name */
    TopicSelectColumn["UpdatedAt"] = "updated_at";
})(TopicSelectColumn || (TopicSelectColumn = {}));
/** unique or primary key constraints on table "pl.topic_theme_enum" */
export var TopicThemeEnumConstraint;
(function (TopicThemeEnumConstraint) {
    /** unique or primary key constraint on columns "id" */
    TopicThemeEnumConstraint["TopicThemeEnumPkey"] = "topic_theme_enum_pkey";
})(TopicThemeEnumConstraint || (TopicThemeEnumConstraint = {}));
export var TopicThemeEnumEnum;
(function (TopicThemeEnumEnum) {
    TopicThemeEnumEnum["A2893ff283a1f421d9d8ea32035fa15f"] = "a2893ff283a1f421d9d8ea32035fa15f";
    TopicThemeEnumEnum["B990b423a75b245f7939d65c447e19fa"] = "b990b423a75b245f7939d65c447e19fa";
    TopicThemeEnumEnum["Cee78c5058bd1481197b21330e61b088"] = "cee78c5058bd1481197b21330e61b088";
    TopicThemeEnumEnum["D68d9f4ef6c7e4e12b1452e2a6d81d80"] = "d68d9f4ef6c7e4e12b1452e2a6d81d80";
    TopicThemeEnumEnum["E2a527f41e3b2452094549a6c2cbd06c"] = "e2a527f41e3b2452094549a6c2cbd06c";
    TopicThemeEnumEnum["F6e1452416e7a4146934dd532edef069"] = "f6e1452416e7a4146934dd532edef069";
    TopicThemeEnumEnum["G5bae9a0132ec4e5f94127a231b4c15c"] = "g5bae9a0132ec4e5f94127a231b4c15c";
})(TopicThemeEnumEnum || (TopicThemeEnumEnum = {}));
/** select columns of table "pl.topic_theme_enum" */
export var TopicThemeEnumSelectColumn;
(function (TopicThemeEnumSelectColumn) {
    /** column name */
    TopicThemeEnumSelectColumn["Id"] = "id";
})(TopicThemeEnumSelectColumn || (TopicThemeEnumSelectColumn = {}));
/** update columns of table "pl.topic_theme_enum" */
export var TopicThemeEnumUpdateColumn;
(function (TopicThemeEnumUpdateColumn) {
    /** column name */
    TopicThemeEnumUpdateColumn["Id"] = "id";
})(TopicThemeEnumUpdateColumn || (TopicThemeEnumUpdateColumn = {}));
/** update columns of table "pl.topic" */
export var TopicUpdateColumn;
(function (TopicUpdateColumn) {
    /** column name */
    TopicUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    TopicUpdateColumn["Id"] = "id";
    /** column name */
    TopicUpdateColumn["MarketCode"] = "market_code";
    /** column name */
    TopicUpdateColumn["MarketLocaleId"] = "market_locale_id";
    /** column name */
    TopicUpdateColumn["Order"] = "order";
    /** column name */
    TopicUpdateColumn["ThemeId"] = "theme_id";
    /** column name */
    TopicUpdateColumn["UpdatedAt"] = "updated_at";
})(TopicUpdateColumn || (TopicUpdateColumn = {}));
export var Unstable__Action;
(function (Unstable__Action) {
    Unstable__Action["Create"] = "CREATE";
    Unstable__Action["Delete"] = "DELETE";
    Unstable__Action["Read"] = "READ";
    Unstable__Action["Update"] = "UPDATE";
    Unstable__Action["UpdatePermissions"] = "UPDATE_PERMISSIONS";
})(Unstable__Action || (Unstable__Action = {}));
export var Unstable__OrderByArg;
(function (Unstable__OrderByArg) {
    Unstable__OrderByArg["Asc"] = "asc";
    Unstable__OrderByArg["Desc"] = "desc";
})(Unstable__OrderByArg || (Unstable__OrderByArg = {}));
export var UploadIntent;
(function (UploadIntent) {
    UploadIntent["FileSystemDocument"] = "FILE_SYSTEM_DOCUMENT";
    UploadIntent["SignedDocument"] = "SIGNED_DOCUMENT";
    UploadIntent["SimpleUploadedFile"] = "SIMPLE_UPLOADED_FILE";
})(UploadIntent || (UploadIntent = {}));
/** select columns of table "pl.user_companies_view" */
export var UserCompaniesViewSelectColumn;
(function (UserCompaniesViewSelectColumn) {
    /** column name */
    UserCompaniesViewSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    UserCompaniesViewSelectColumn["Id"] = "id";
    /** column name */
    UserCompaniesViewSelectColumn["Name"] = "name";
    /** column name */
    UserCompaniesViewSelectColumn["OrganizationNumber"] = "organization_number";
    /** column name */
    UserCompaniesViewSelectColumn["StripeCustomerId"] = "stripe_customer_id";
    /** column name */
    UserCompaniesViewSelectColumn["UpdatedAt"] = "updated_at";
    /** column name */
    UserCompaniesViewSelectColumn["UserId"] = "user_id";
})(UserCompaniesViewSelectColumn || (UserCompaniesViewSelectColumn = {}));
/** unique or primary key constraints on table "pl.user" */
export var UserConstraint;
(function (UserConstraint) {
    /** unique or primary key constraint on columns "id" */
    UserConstraint["UserPkey"] = "user_pkey";
})(UserConstraint || (UserConstraint = {}));
/** unique or primary key constraints on table "pl.user_invite" */
export var UserInviteConstraint;
(function (UserInviteConstraint) {
    /** unique or primary key constraint on columns "id" */
    UserInviteConstraint["UserInvitePkey"] = "user_invite_pkey";
})(UserInviteConstraint || (UserInviteConstraint = {}));
/** select columns of table "pl.user_invite" */
export var UserInviteSelectColumn;
(function (UserInviteSelectColumn) {
    /** column name */
    UserInviteSelectColumn["AcceptedByUserId"] = "accepted_by_user_id";
    /** column name */
    UserInviteSelectColumn["AllowedComposers"] = "allowed_composers";
    /** column name */
    UserInviteSelectColumn["CanManageMember"] = "can_manage_member";
    /** column name */
    UserInviteSelectColumn["CompanyId"] = "company_id";
    /** column name */
    UserInviteSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    UserInviteSelectColumn["DeletedAt"] = "deleted_at";
    /** column name */
    UserInviteSelectColumn["Email"] = "email";
    /** column name */
    UserInviteSelectColumn["Id"] = "id";
    /** column name */
    UserInviteSelectColumn["InvitedByUserId"] = "invited_by_user_id";
    /** column name */
    UserInviteSelectColumn["Role"] = "role";
    /** column name */
    UserInviteSelectColumn["UpdatedAt"] = "updated_at";
})(UserInviteSelectColumn || (UserInviteSelectColumn = {}));
/** select "pl_user_invite_aggregate_bool_exp_bool_and_arguments_columns" columns of table "pl.user_invite" */
export var UserInviteSelectColumnPlUserInviteAggregateBoolExpBoolAndArgumentsColumns;
(function (UserInviteSelectColumnPlUserInviteAggregateBoolExpBoolAndArgumentsColumns) {
    /** column name */
    UserInviteSelectColumnPlUserInviteAggregateBoolExpBoolAndArgumentsColumns["CanManageMember"] = "can_manage_member";
})(UserInviteSelectColumnPlUserInviteAggregateBoolExpBoolAndArgumentsColumns || (UserInviteSelectColumnPlUserInviteAggregateBoolExpBoolAndArgumentsColumns = {}));
/** select "pl_user_invite_aggregate_bool_exp_bool_or_arguments_columns" columns of table "pl.user_invite" */
export var UserInviteSelectColumnPlUserInviteAggregateBoolExpBoolOrArgumentsColumns;
(function (UserInviteSelectColumnPlUserInviteAggregateBoolExpBoolOrArgumentsColumns) {
    /** column name */
    UserInviteSelectColumnPlUserInviteAggregateBoolExpBoolOrArgumentsColumns["CanManageMember"] = "can_manage_member";
})(UserInviteSelectColumnPlUserInviteAggregateBoolExpBoolOrArgumentsColumns || (UserInviteSelectColumnPlUserInviteAggregateBoolExpBoolOrArgumentsColumns = {}));
/** update columns of table "pl.user_invite" */
export var UserInviteUpdateColumn;
(function (UserInviteUpdateColumn) {
    /** column name */
    UserInviteUpdateColumn["AcceptedByUserId"] = "accepted_by_user_id";
    /** column name */
    UserInviteUpdateColumn["AllowedComposers"] = "allowed_composers";
    /** column name */
    UserInviteUpdateColumn["CanManageMember"] = "can_manage_member";
    /** column name */
    UserInviteUpdateColumn["CompanyId"] = "company_id";
    /** column name */
    UserInviteUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    UserInviteUpdateColumn["DeletedAt"] = "deleted_at";
    /** column name */
    UserInviteUpdateColumn["Email"] = "email";
    /** column name */
    UserInviteUpdateColumn["Id"] = "id";
    /** column name */
    UserInviteUpdateColumn["InvitedByUserId"] = "invited_by_user_id";
    /** column name */
    UserInviteUpdateColumn["Role"] = "role";
    /** column name */
    UserInviteUpdateColumn["UpdatedAt"] = "updated_at";
})(UserInviteUpdateColumn || (UserInviteUpdateColumn = {}));
/** unique or primary key constraints on table "pl.user_referrer_enum" */
export var UserReferrerEnumConstraint;
(function (UserReferrerEnumConstraint) {
    /** unique or primary key constraint on columns "name" */
    UserReferrerEnumConstraint["UserReferrerEnumPkey"] = "user_referrer_enum_pkey";
})(UserReferrerEnumConstraint || (UserReferrerEnumConstraint = {}));
export var UserReferrerEnumEnum;
(function (UserReferrerEnumEnum) {
    /** Other (free choice) */
    UserReferrerEnumEnum["Other"] = "_other";
    /** Article or Blog */
    UserReferrerEnumEnum["ArticleBlog"] = "article_blog";
    /** Event or Seminar */
    UserReferrerEnumEnum["EventSeminar"] = "event_seminar";
    /** Recommended by friend or colleague */
    UserReferrerEnumEnum["Recommended"] = "recommended";
    /** Search Engine (Google, Bing, etc). */
    UserReferrerEnumEnum["SearchEngine"] = "search_engine";
    /** Social Media (LinkedIn, Instagram etc.) */
    UserReferrerEnumEnum["SocialMedia"] = "social_media";
})(UserReferrerEnumEnum || (UserReferrerEnumEnum = {}));
/** select columns of table "pl.user_referrer_enum" */
export var UserReferrerEnumSelectColumn;
(function (UserReferrerEnumSelectColumn) {
    /** column name */
    UserReferrerEnumSelectColumn["Description"] = "description";
    /** column name */
    UserReferrerEnumSelectColumn["Name"] = "name";
})(UserReferrerEnumSelectColumn || (UserReferrerEnumSelectColumn = {}));
/** update columns of table "pl.user_referrer_enum" */
export var UserReferrerEnumUpdateColumn;
(function (UserReferrerEnumUpdateColumn) {
    /** column name */
    UserReferrerEnumUpdateColumn["Description"] = "description";
    /** column name */
    UserReferrerEnumUpdateColumn["Name"] = "name";
})(UserReferrerEnumUpdateColumn || (UserReferrerEnumUpdateColumn = {}));
/** select columns of table "pl.user" */
export var UserSelectColumn;
(function (UserSelectColumn) {
    /** column name */
    UserSelectColumn["CreatedAt"] = "created_at";
    /** column name */
    UserSelectColumn["DefaultCompanyId"] = "default_company_id";
    /** column name */
    UserSelectColumn["Email"] = "email";
    /** column name */
    UserSelectColumn["Id"] = "id";
    /** column name */
    UserSelectColumn["IsExternal"] = "is_external";
    /** column name */
    UserSelectColumn["Name"] = "name";
    /** column name */
    UserSelectColumn["PhoneNumber"] = "phone_number";
    /** column name */
    UserSelectColumn["PreferredLocale"] = "preferred_locale";
    /** column name */
    UserSelectColumn["Referrer"] = "referrer";
    /** column name */
    UserSelectColumn["ReferrerOther"] = "referrer_other";
    /** column name */
    UserSelectColumn["UpdatedAt"] = "updated_at";
})(UserSelectColumn || (UserSelectColumn = {}));
/** update columns of table "pl.user" */
export var UserUpdateColumn;
(function (UserUpdateColumn) {
    /** column name */
    UserUpdateColumn["CreatedAt"] = "created_at";
    /** column name */
    UserUpdateColumn["DefaultCompanyId"] = "default_company_id";
    /** column name */
    UserUpdateColumn["Email"] = "email";
    /** column name */
    UserUpdateColumn["Id"] = "id";
    /** column name */
    UserUpdateColumn["IsExternal"] = "is_external";
    /** column name */
    UserUpdateColumn["Name"] = "name";
    /** column name */
    UserUpdateColumn["PhoneNumber"] = "phone_number";
    /** column name */
    UserUpdateColumn["PreferredLocale"] = "preferred_locale";
    /** column name */
    UserUpdateColumn["Referrer"] = "referrer";
    /** column name */
    UserUpdateColumn["ReferrerOther"] = "referrer_other";
    /** column name */
    UserUpdateColumn["UpdatedAt"] = "updated_at";
})(UserUpdateColumn || (UserUpdateColumn = {}));
export var WebApiClientUserRole;
(function (WebApiClientUserRole) {
    WebApiClientUserRole["Member"] = "MEMBER";
    WebApiClientUserRole["Owner"] = "OWNER";
})(WebApiClientUserRole || (WebApiClientUserRole = {}));
