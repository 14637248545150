var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Trans, t } from '@lingui/macro';
import { Alert, Box, LoaderOverlay, Typography, useToast } from '@pocketlaw/tetris';
import { useDocumentLocked, useDownloadDocumentAttachment } from 'app/domains/documents';
import { EsigningAttachmentKind } from 'shared/domains/apollo/generated/types';
import { DocumentAttachments, useDocumentAttachments, DocumentDrawerHeader, usePleditor, usePleditorDocumentAttachments, usePreview, } from 'shared/domains/documents';
import { Attachment } from './Attachment';
import { DefaultAttachment } from './DefaultAttachment';
import { DrawerContainer, ScrollContainer } from '../../styled';
import { getTitle } from '../../utils';
export function Attachments(props) {
    const { onClose, onGoBack } = props;
    const { addToast } = useToast();
    const { locked, reason } = useDocumentLocked();
    const { plugins } = usePleditor();
    const { attachments, addingAttachment, addAttachment, loading, error } = useDocumentAttachments();
    const { downloadAttachment } = useDownloadDocumentAttachment();
    const { isFreeText } = usePreview();
    const embeddedCounter = usePleditorDocumentAttachments();
    const title = getTitle('attachments');
    const isLocked = locked && (reason === 'esigning:pending' || reason === 'esigning:signed');
    const emptyDescription = t({
        comment: 'Document attachments empty state - description',
        message: 'Attachments are included with the document when sent for e-signing.',
    });
    const handleAddAttachments = (newAttachments) => __awaiter(this, void 0, void 0, function* () {
        try {
            const results = yield Promise.all(newAttachments.map(attachment => addAttachment(attachment)));
            results.forEach(result => {
                var _a, _b;
                if ((_a = result.data) === null || _a === void 0 ? void 0 : _a.addDocumentAttachment) {
                    (_b = plugins.attachmentStore) === null || _b === void 0 ? void 0 : _b.add(result.data.addDocumentAttachment);
                }
            });
        }
        catch (_a) {
            addToast({
                appearance: 'danger',
                title: t({
                    comment: 'Document attachments - error when adding attachment toast title',
                    message: 'Could not add attachment',
                }),
            });
        }
    });
    const handleScrollToAttachment = (attachmentId) => {
        var _a;
        (_a = plugins.attachmentStore) === null || _a === void 0 ? void 0 : _a.scrollToEmbed(attachmentId);
    };
    const handleEmbedAttachment = isFreeText
        ? (attachment) => {
            var _a;
            (_a = plugins.attachmentStore) === null || _a === void 0 ? void 0 : _a.insert(attachment);
        }
        : undefined;
    if (loading) {
        return (<DrawerContainer>
        <DocumentDrawerHeader type="nested" title={title} onClose={onClose} onGoBack={onGoBack}/>
        <LoaderOverlay enabled label={t({
                comment: 'Loading document attachments label',
                message: 'Loading attachments...',
            })}/>
      </DrawerContainer>);
    }
    if (error) {
        return (<DrawerContainer>
        <DocumentDrawerHeader type="nested" title={title} onClose={onClose} onGoBack={onGoBack}/>
        <Box display="block" p="2xl">
          <Alert appearance="danger" message={t({
                comment: 'Loading document attachments error message',
                message: 'Could not load attachments',
            })}/>
        </Box>
      </DrawerContainer>);
    }
    return (<DrawerContainer>
      <DocumentDrawerHeader type="nested" title={title} onClose={onClose} onGoBack={onGoBack}/>
      <ScrollContainer>
        <Box flexDirection="column" gap="sm" px="2xl">
          {attachments.length > 0 && (<Typography $fontSize="small" $appearance="100">
              <Trans comment="Document attachments - added label">Added</Trans>
            </Typography>)}
          <DocumentAttachments hideAdd={isLocked} disableAdd={addingAttachment} attachments={attachments} onAddAttachments={handleAddAttachments} emptyStateDescription={emptyDescription} showAddingAttachmentLoader={addingAttachment} renderProps={embeddedCounter} render={(attachment, counter) => attachment.kind === EsigningAttachmentKind.Default ? (<DefaultAttachment key={attachment.id} attachment={attachment} disabled={isLocked} embeddedCount={counter[attachment.id]} onDownload={downloadAttachment} onScrollToAttachment={handleScrollToAttachment} onEmbed={handleEmbedAttachment}/>) : (<Attachment key={attachment.id} attachment={attachment} disabled={isLocked} embeddedCount={counter[attachment.id]} onDownload={downloadAttachment} onScrollToAttachment={handleScrollToAttachment} onEmbed={handleEmbedAttachment}/>)}/>
        </Box>
      </ScrollContainer>
    </DrawerContainer>);
}
