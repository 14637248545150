import { Box, Typography } from '@pocketlaw/tetris';
export function PartyRow(props) {
    const { label, value } = props;
    return (<Box key={label} alignItems="center" justifyContent="space-between">
      <Typography $fontSize="small" $appearance="100">
        {label}
      </Typography>
      <Typography $fontSize="small" $appearance="300">
        {value}
      </Typography>
    </Box>);
}
