import { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Box, Typography } from '@pocketlaw/tetris';
import { AssistantContext } from 'app/domains/assistant';
import { ChatMessageType } from 'shared/domains/apollo/generated/types';
import { ChatInput } from 'shared/domains/common-ui';
import { usePreview } from 'shared/domains/documents';
import { useFeatureFlag } from 'shared/domains/featureFlag';
import { AlertHandler } from './AlertHandler';
import { CompareButton } from './CompareButton';
import { HistoryButton } from './HistoryButton';
import { NoCreditsAlert } from './NoCreditsAlert';
import { PromptButton } from './PromptButton';
import { PromptLibraryButton } from './PromptLibraryButton';
import { WelcomeText } from './WelcomeText';
import { EXTRACT_KEY_INFORMATION_PROMPT, OVERVIEW_OF_IMPORTANT_DATES_PROMPT, PROOFREAD_CONTRACT_PROMPT, } from './suggestedPrompts';
import { useDefaultChatType } from './useDefaultChatType';
import { useAssistant } from '../../AssistantProvider';
import { AssistantChatContainer } from '../styled';
import { useInputElements } from '../useInputElements';
import { InputContainer } from './styled';
export function Dashboard() {
    const { initiating, creatingChat, context, hasCredits, createNewChat, goToConversations } = useAssistant();
    const { isPdf } = usePreview();
    const { elements } = useInputElements();
    const isPlaiQuotesEnabled = useFeatureFlag('feature_plai_quotes');
    const [input, setInput] = useState('');
    const [compareError, setCompareError] = useState(null);
    const defaultChatType = useDefaultChatType(context);
    const disableChat = initiating || creatingChat || !hasCredits;
    const showNoCreditsAlert = !initiating && !hasCredits;
    const showChat = !(isPdf && context === AssistantContext.Selection) && hasCredits;
    const showErrors = Boolean(showNoCreditsAlert || compareError);
    const createNewChatWithMessage = (message) => {
        createNewChat({ message, type: defaultChatType });
    };
    const handleSelectPrompt = (prompt) => {
        setInput(prompt);
    };
    const handleSummarize = () => {
        const type = isPlaiQuotesEnabled
            ? ChatMessageType.DocumentSummarizeWithQuotes
            : ChatMessageType.DocumentSummarize;
        createNewChat({ type, message: '' });
    };
    const handleSendMessage = (message) => createNewChatWithMessage(message);
    const suggestedPrompts = [
        {
            id: '1',
            name: t({
                comment: 'Assistant chat - Dashboard suggested prompt - Summarize document',
                message: 'Summarize document',
            }),
            onClick: handleSummarize,
        },
        {
            id: '2',
            name: t({
                comment: 'Assistant chat - Dashboard suggested prompt - Extract key information',
                message: 'Extract key information',
            }),
            onClick: () => createNewChatWithMessage(EXTRACT_KEY_INFORMATION_PROMPT),
        },
        {
            id: '3',
            name: t({
                comment: 'Assistant chat - Dashboard suggested prompt - Overview of important dates',
                message: 'Overview of important dates',
            }),
            onClick: () => createNewChatWithMessage(OVERVIEW_OF_IMPORTANT_DATES_PROMPT),
        },
        {
            id: '4',
            name: t({
                comment: 'Assistant chat - Dashboard suggested prompt - Proofread contract',
                message: 'Proofread contract',
            }),
            onClick: () => createNewChatWithMessage(PROOFREAD_CONTRACT_PROMPT),
        },
    ];
    return (<AssistantChatContainer $withScroll>
      <Box flexDirection="column" gap="2xl" height="100%" mb="2xl">
        <WelcomeText />
        <Box gap="lg">
          <PromptLibraryButton disabled={disableChat} onSelectPrompt={handleSelectPrompt}/>
          <CompareButton disabled={disableChat} onError={setCompareError}/>
          <HistoryButton disabled={initiating} onClick={goToConversations}/>
        </Box>
        {showErrors && (<Box flexDirection="column" gap="md">
            {showNoCreditsAlert && <NoCreditsAlert />}
            {compareError && <AlertHandler error={compareError}/>}
          </Box>)}
        <Box flexDirection="column" gap="md">
          <Typography $fontSize="small" $fontWeight="semibold" $appearance="100">
            <Trans comment="Assistant chat - Dashboard suggested prompts title">
              Suggested prompts
            </Trans>
          </Typography>
          {suggestedPrompts.map(prompt => (<PromptButton key={prompt.id} label={prompt.name} disabled={disableChat} onClick={prompt.onClick}/>))}
        </Box>
      </Box>
      {showChat && (<InputContainer>
          <ChatInput showPlaiDisclaimerText value={input} loading={creatingChat} disabled={disableChat} placeholder={t({
                comment: 'Assistant chat - Dashboard textarea placeholder',
                message: 'Ask the assistant anything...',
            })} send={{ disabled: disableChat, onSend: handleSendMessage }} onChange={setInput} prependElements={elements}/>
        </InputContainer>)}
    </AssistantChatContainer>);
}
