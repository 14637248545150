import { ApprovalRequestApproverStatus } from 'shared/domains/apollo/generated/types';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { useLastApprovalRequest } from '../useLastApprovalRequest';
export const useIsCurrentUserApprover = (documentId) => {
    const { lastApprovalRequest } = useLastApprovalRequest(documentId);
    const { data: accountData } = useAccount();
    const userApprover = lastApprovalRequest === null || lastApprovalRequest === void 0 ? void 0 : lastApprovalRequest.approvers.find(approver => {
        var _a, _b;
        if (!((_a = approver === null || approver === void 0 ? void 0 : approver.user) === null || _a === void 0 ? void 0 : _a.id) || !accountData.userId) {
            return false;
        }
        return ((_b = approver.user) === null || _b === void 0 ? void 0 : _b.id) === accountData.userId;
    });
    if (userApprover === null || userApprover === void 0 ? void 0 : userApprover.id) {
        return {
            isApprover: true,
            approverId: userApprover.id,
            hasApproved: userApprover.status === ApprovalRequestApproverStatus.Approved,
        };
    }
    return {
        isApprover: false,
        approverId: null,
        hasApproved: false,
    };
};
