import { Trans } from '@lingui/macro';
import { Box, Typography } from '@pocketlaw/tetris';
export function PlaiDisclaimerText() {
    return (<Box p="md" justifyContent="center">
      <Typography $fontSize="tiny" $appearance="100">
        <Trans comment="Assistant chat - PLAI warning text">
          PLAI can make mistakes. Verify important information.
        </Trans>
      </Typography>
    </Box>);
}
