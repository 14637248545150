import { t, Trans } from '@lingui/macro';
import { ActivityEntry, ActivityEntryText } from 'shared/domains/common-ui';
import { ChangedDataTable } from './ChangedDataTable';
import { getIcon } from './utils';
export function SignRequestSignatoryChanged(props) {
    const { actor, timestamp, changedData } = props;
    const { newSignatory, oldSignatory } = changedData;
    const fallbackName = t({
        message: 'Unknown',
        comment: 'Fallback name when user does not exist',
    });
    const userName = (actor === null || actor === void 0 ? void 0 : actor.name) || fallbackName;
    const signatoryName = newSignatory.name;
    return (<ActivityEntry appearance="info" icon={getIcon('info')} timestamp={timestamp} text={<ActivityEntryText>
          <Trans comment="Esigning request - User updated information about signatory">
            <b>{userName}</b> updated information about <b>{signatoryName}</b>
          </Trans>
        </ActivityEntryText>}>
      <ChangedDataTable from={oldSignatory} to={newSignatory}/>
    </ActivityEntry>);
}
