var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useState } from 'react';
import { useDocumentPermission } from 'app/domains/documents';
import { AclAction } from 'shared/domains/apollo/generated/types';
import { useApprovalWorkflow } from '../../components/ApprovalWorkflowProvider';
export const useDocumentApproversPermissions = (approverUserIds) => {
    const { documentId } = useApprovalWorkflow();
    const { refetch: fetchDocumentPermission } = useDocumentPermission({});
    const [called, setCalled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userIdsWithoutPermission, setUserIdsWithoutPermission] = useState([]);
    const fetchUserActions = useCallback((userIds) => __awaiter(void 0, void 0, void 0, function* () {
        const actionsMap = {};
        const usersPermissions = yield Promise.all(userIds.map(userId => fetchDocumentPermission({ documentId, userId })));
        userIds.forEach(userId => {
            var _a;
            const userPermissions = usersPermissions.find(({ data }) => { var _a; return ((_a = data.fileSystemEntryACL) === null || _a === void 0 ? void 0 : _a.userId) === userId; });
            const actions = ((_a = userPermissions === null || userPermissions === void 0 ? void 0 : userPermissions.data.fileSystemEntryACL) === null || _a === void 0 ? void 0 : _a.actions) || [];
            actionsMap[userId] = actions;
        });
        return actionsMap;
    }), [documentId, fetchDocumentPermission]);
    const fetchUserPermissions = useCallback((userIds) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        setCalled(true);
        try {
            const userActions = yield fetchUserActions(userIds);
            const idsWithoutPermission = Object.entries(userActions)
                .filter(([, actions]) => !actions.find(action => action === AclAction.Read))
                .map(([userId]) => userId);
            setUserIdsWithoutPermission(idsWithoutPermission);
        }
        catch (_a) {
            setUserIdsWithoutPermission(userIds);
        }
        setLoading(false);
    }), [fetchUserActions]);
    useEffect(() => {
        if (approverUserIds.length > 0 && !called) {
            fetchUserPermissions(approverUserIds);
        }
    }, [called, approverUserIds, fetchUserPermissions]);
    return { userIdsWithoutPermission, loading };
};
