import { useEffect } from 'react';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { usePrevious } from 'react-use';
import { SignRequestStatus } from 'shared/domains/apollo/generated/types';
import { EsigningContext } from './EsigningContext';
import { useSendForEsigning } from './useSendForEsigning';
import { useRemoveSignRequest } from '../../hooks/useRemoveSignRequest';
const PENDING_STATUSES = [SignRequestStatus.Pending, SignRequestStatus.Opened];
export function EsigningProvider(props) {
    const { children, documentId, lastSignRequest } = props;
    const { addToast } = useToast();
    const { sendForEsigning, loading: submittingCreation, creationError: createSignRequestError, creationFailedSubcode: createSignRequestFailedSubcode, setCreationFailedSubcode: setCreateSignRequestFailedSubcode, } = useSendForEsigning(documentId);
    const { removeSignRequest, error: RemoveError, loading: removeLoading } = useRemoveSignRequest();
    const lastSignRequestStatus = lastSignRequest === null || lastSignRequest === void 0 ? void 0 : lastSignRequest.status;
    const pending = Boolean(lastSignRequestStatus && PENDING_STATUSES.includes(lastSignRequestStatus));
    const signed = lastSignRequestStatus === SignRequestStatus.Signed;
    const creating = lastSignRequestStatus === SignRequestStatus.Creating;
    const cancelling = lastSignRequestStatus === SignRequestStatus.Cancelling;
    const cancelled = lastSignRequestStatus === SignRequestStatus.Cancelled;
    const rejected = lastSignRequestStatus === SignRequestStatus.Rejected;
    const failed = lastSignRequestStatus === SignRequestStatus.Failed;
    const unknown = lastSignRequestStatus === SignRequestStatus.Unknown;
    const creatingStatus = creating || submittingCreation;
    const previousCreatingStatus = usePrevious(creatingStatus);
    const cancellingStatus = cancelling || removeLoading;
    const previousCancellingStatus = usePrevious(cancellingStatus);
    useEffect(() => {
        if (pending && previousCreatingStatus && !creatingStatus) {
            addToast({
                appearance: 'success',
                title: t({
                    message: 'Successfully send document for e-signing.',
                    comment: 'Message for when a user send a document for e-signing',
                }),
            });
        }
    }, [addToast, creatingStatus, pending, previousCreatingStatus]);
    useEffect(() => {
        if (cancelled && previousCancellingStatus && !cancellingStatus) {
            addToast({
                appearance: 'info',
                title: t({
                    message: 'Successfully cancelled e-signing request.',
                    comment: 'Message for when a user cancels an e-signing request',
                }),
            });
        }
    }, [addToast, cancelled, cancellingStatus, previousCancellingStatus]);
    const value = {
        documentId,
        signed,
        pending,
        rejected,
        creating,
        failed,
        unknown,
        cancelling,
        createSignRequestError,
        createSignRequestFailedSubcode,
        setCreateSignRequestFailedSubcode,
        submittingCreation,
        cancelError: RemoveError,
        cancelSignRequest: removeSignRequest,
        submittingCancellation: removeLoading,
        sendDocumentForEsigning: sendForEsigning,
        lastSignRequestAttemptTimestamp: lastSignRequest === null || lastSignRequest === void 0 ? void 0 : lastSignRequest.createdAt,
    };
    return <EsigningContext.Provider value={value}>{children}</EsigningContext.Provider>;
}
