import { Trans, t } from '@lingui/macro';
import { ActivityEntry, ActivityEntryText } from 'shared/domains/common-ui';
import { getIcon } from './utils';
export function SignRequestReminded(props) {
    const { actor, timestamp } = props;
    const fallbackName = t({
        message: 'Unknown',
        comment: 'Fallback name when user does not exist',
    });
    const userName = (actor === null || actor === void 0 ? void 0 : actor.name) || fallbackName;
    return (<ActivityEntry appearance="info" icon={getIcon('info')} timestamp={timestamp} text={<ActivityEntryText>
          <Trans comment="Esigning activity - User sent a reminder for the eSigning request">
            <b>{userName}</b> sent a reminder for the eSigning request
          </Trans>
        </ActivityEntryText>}/>);
}
