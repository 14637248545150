import { Trans } from '@lingui/macro';
import { ActivityEntry, ActivityEntryText } from 'shared/domains/common-ui';
import { getIcon } from './utils';
export function SignRequestExpired(props) {
    const { timestamp } = props;
    return (<ActivityEntry appearance="danger" icon={getIcon('danger')} timestamp={timestamp} text={<ActivityEntryText>
          <Trans comment="Esigning activity - The eSigning request is expired">
            The eSigning request is expired
          </Trans>
        </ActivityEntryText>}/>);
}
