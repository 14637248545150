var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment, useRef, useState } from 'react';
import { t } from '@lingui/macro';
import { useGeneratedId } from '@pocketlaw/tetris';
import { ESIGNING_UPLOAD_ATTACHMENT_SUPPORTED_MIME_TYPES } from 'app/domains/esigning/utils';
import { FileUpload, MAX_ATTACHMENT_UPLOAD_SIZE_BYTES } from 'app/domains/upload';
import { DocumentType } from 'shared/domains/apollo/generated/types';
import { FilePickerDialog } from 'shared/domains/common-ui/components/pickers';
import { ACTIVE_STATUSES } from 'shared/domains/documents';
import { useActiveDocument } from 'shared/domains/documents/hooks/usePreview';
import { CustomError } from 'shared/domains/errors/CustomError';
import { MimeTypesByExtension } from 'shared/domains/filesystem/utils/fileExtensions';
import * as Sentry from 'shared/domains/sentry';
import { Dropdown } from './Dropdown';
import { getFileToUpload } from './getFileToUpload';
import { useGetEntryFile } from './useGetEntryFile';
import { useUploadAttachment } from './useUploadAttachment';
import { useAttachments } from '../Provider';
import { AttachmentErrors } from '../Provider/types';
export function UploadAttachmentDropdown(props) {
    const { origin } = props;
    const { disableAdd, addAttachments, setAttachmentsError, uploadingAttachment, setUploadingAttachment, setFileSizeTooBigWarning, } = useAttachments();
    const fileUploadRef = useRef(null);
    const [renderPicker, setRenderPicker] = useState(false);
    const inputId = useGeneratedId('attachment-input');
    const [uploadFile] = useUploadAttachment();
    const getEntryFile = useGetEntryFile();
    const { id: documentId } = useActiveDocument();
    const startLoading = () => {
        setAttachmentsError(undefined);
        setFileSizeTooBigWarning(false);
        setUploadingAttachment(true);
    };
    const cancelLoading = () => {
        setUploadingAttachment(false);
    };
    const showPicker = () => setRenderPicker(true);
    const hidePicker = () => setRenderPicker(false);
    const handleUploadFile = () => { var _a; return (_a = fileUploadRef.current) === null || _a === void 0 ? void 0 : _a.click(); };
    const handleUploadError = (error) => {
        var _a;
        if (error instanceof CustomError && ((_a = error.data) === null || _a === void 0 ? void 0 : _a.code) && error.data.code === 'EntityTooLarge') {
            setFileSizeTooBigWarning(true);
        }
        else {
            setAttachmentsError(AttachmentErrors.UploadFailed);
            Sentry.captureException(error);
        }
    };
    const handleGetPdfError = (error) => {
        setUploadingAttachment(false);
        setAttachmentsError(AttachmentErrors.GetPdfFailed);
        Sentry.captureException(error);
    };
    const handleUpload = (files) => {
        startLoading();
        Promise.all(files.map(file => uploadFile(file)))
            .then(addAttachments)
            .catch(handleUploadError)
            .finally(cancelLoading);
    };
    function isNotNull(item) {
        return item !== null;
    }
    const isNotString = (file) => typeof file !== 'string';
    const handleSelectEntries = (entries) => __awaiter(this, void 0, void 0, function* () {
        setUploadingAttachment(true);
        const files = yield Promise.all(entries.map(getEntryFile)).catch(handleGetPdfError);
        hidePicker();
        if (!files || !files.length) {
            return;
        }
        const normalizedFiles = files
            .map((file, index) => {
            var _a, _b;
            if (typeof file === 'string' || !file) {
                return null;
            }
            return {
                url: (_a = file.signed) === null || _a === void 0 ? void 0 : _a.url,
                mimeType: file.mimeType,
                name: (_b = entries[index]) === null || _b === void 0 ? void 0 : _b.name,
            };
        })
            .filter(isNotNull);
        const filesToUpload = yield Promise.all(normalizedFiles.map(getFileToUpload));
        const filteredFilesToUpload = filesToUpload.filter(isNotString);
        if (!filteredFilesToUpload.length) {
            setAttachmentsError(AttachmentErrors.NoFileFound);
            cancelLoading();
            return;
        }
        handleUpload(filteredFilesToUpload);
    });
    const isAnyFileTypeUnsupported = (files) => files.some(file => !ESIGNING_UPLOAD_ATTACHMENT_SUPPORTED_MIME_TYPES.includes(file.type));
    const isAnyFileTooBig = (files) => files.some(file => file.size > MAX_ATTACHMENT_UPLOAD_SIZE_BYTES);
    const handleChange = (event) => {
        var _a;
        const fileList = (_a = event.target) === null || _a === void 0 ? void 0 : _a.files;
        startLoading();
        if (!fileList) {
            setAttachmentsError(AttachmentErrors.NoFileProvided);
            cancelLoading();
            return;
        }
        const files = Array.from(fileList);
        if (isAnyFileTypeUnsupported(files)) {
            setAttachmentsError(AttachmentErrors.InvalidFormat);
            cancelLoading();
            return;
        }
        if (isAnyFileTooBig(files)) {
            setFileSizeTooBigWarning(true);
            cancelLoading();
            return;
        }
        handleUpload(files);
        // eslint-disable-next-line no-param-reassign
        event.target.value = ''; // reset input value so there's no reference to it for future events (hover, focus)
    };
    const isEntryPdf = (entry) => {
        const { status, type, mimeType } = entry;
        const isSentForEsigning = Boolean(status && ACTIVE_STATUSES.includes(status));
        const isPdf = isSentForEsigning ||
            (type === DocumentType.Uploaded && mimeType === MimeTypesByExtension.Pdf);
        return isPdf;
    };
    return (<Fragment>
      <Dropdown origin={origin} onUpload={handleUploadFile} onSelect={showPicker}/>
      <FileUpload id={inputId} accept={ESIGNING_UPLOAD_ATTACHMENT_SUPPORTED_MIME_TYPES} multiple ref={fileUploadRef} onChange={handleChange} disabled={disableAdd || uploadingAttachment} name="attachments"/>
      {renderPicker && (<FilePickerDialog multiselect selfId={documentId} dialogTitle={t({
                comment: 'Select document attachment: title',
                message: 'Select attachment',
            })} dialogSubtitle={t({
                comment: 'Select document attachment: subtitle',
                message: 'Select a document to attach',
            })} submitLabel={t({
                comment: 'Select document attachment: submit button label',
                message: 'Attach',
            })} selectedEntryAlert={{
                appearance: 'info',
                title: t({
                    comment: 'Select document attachment: Selected attachment is not a pdf',
                    message: 'Selected attachment is not a pdf',
                }),
                message: t({
                    comment: 'Select document attachment: The selected document will be attached as a pdf',
                    message: 'The selected document will be attached as a pdf',
                }),
                check: isEntryPdf,
            }} onClose={hidePicker} onSubmit={handleSelectEntries}/>)}
    </Fragment>);
}
