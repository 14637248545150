import { useLastApprovalRequest } from 'app/domains/approval/hooks/useLastApprovalRequest';
import { useApprovalWorkflow } from '../../components/ApprovalWorkflowProvider';
import { useDocumentApprovers } from '../useDocumentApprovers';
export const useDocumentApproversWithStatus = () => {
    const { documentId, approvalNotRequested } = useApprovalWorkflow();
    const { documentApprovers, loading: loadingDocumentApprovers } = useDocumentApprovers(documentId);
    const { lastApprovalRequest, loading: loadingLastApprovalRequest } = useLastApprovalRequest(documentId);
    // If approval has not been requested show the approvers attached to the document
    if (approvalNotRequested) {
        const normalizedApprovers = documentApprovers.map(approver => (Object.assign(Object.assign({}, approver), { status: null })));
        return { approvers: normalizedApprovers, loading: loadingDocumentApprovers };
    }
    const { approvers = [] } = lastApprovalRequest || {};
    const approversMap = approvers.reduce((acc, approver) => {
        if (!(approver === null || approver === void 0 ? void 0 : approver.user)) {
            return acc;
        }
        acc[approver.user.id] = {
            userId: approver.user.id,
            name: approver.user.name || '',
            email: approver.user.email || '',
            status: approver.status || null,
            updatedAt: approver.updatedAt || '',
        };
        return acc;
    }, {});
    const approversWithStatus = Object.values(approversMap);
    return { approvers: approversWithStatus, loading: loadingLastApprovalRequest };
};
