import { t } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
import { useApprovalWorkflow } from 'app/domains/approval';
import { ApprovalType } from 'shared/domains/apollo/generated/types';
export function DefaultAlert() {
    const { approvalType } = useApprovalWorkflow();
    const alertTitle = t({
        comment: 'Document awaiting approval state - Alert title',
        message: 'Awaiting approval',
    });
    const oneOfMessage = t({
        comment: 'Document awaiting approval state - Needs approval by one of the approvers',
        message: 'This document needs approval by one of the assigned approvers before being sent for e-signing',
    });
    const allMessage = t({
        comment: 'Document awaiting approval state - Needs approval by all of the approvers',
        message: 'This document needs approval by all of the assigned approvers before being sent for e-signing',
    });
    const alertMessage = approvalType === ApprovalType.All ? allMessage : oneOfMessage;
    return <Alert appearance="info" title={alertTitle} message={alertMessage}/>;
}
