var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { AddSignatoryValueDocument } from './AddSignatoryValue.gql';
export function useAddSignatoryValue(documentId) {
    const [mutation, state] = useMutation(AddSignatoryValueDocument);
    const handleMutation = (values) => __awaiter(this, void 0, void 0, function* () {
        return mutation({
            variables: {
                input: values.map(value => ({
                    email: value.email,
                    fileSystemDocumentId: documentId,
                    firstName: value.firstname,
                    isSigning: value.is_signing,
                    label: value.label,
                    lastName: value.lastname,
                    mobile: value.mobile,
                    name: value.name,
                })),
            },
            update(cache, { data }) {
                const newData = (data === null || data === void 0 ? void 0 : data.createEsigningSignatoryValue) || [];
                cache.modify({
                    fields: {
                        esigningSignatoryValues(existing = []) {
                            return [...existing, ...newData];
                        },
                    },
                });
            },
        });
    });
    return {
        addSignatoryValue: handleMutation,
        state,
    };
}
