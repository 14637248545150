import { themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const IconContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${themeGet('spacing.sm')};

  [data-icon='falcon'] {
    color: ${themeGet('colors.gray.800')};
  }
`;
