import { Trans, t } from '@lingui/macro';
import { Select } from '@pocketlaw/tetris';
import { ApprovalType } from 'shared/domains/apollo/generated/types';
export function SelectApprovalType(props) {
    const { value, onChange } = props;
    const allDescription = t({
        message: 'If you select this option, all assigned approvers need to approve before eSigning is enabled.',
        comment: 'Select approval type - select all description',
    });
    const oneOfDescription = t({
        message: 'Only one of the added approvers needs to approve the document before it can be sent for eSigning. User can choose who of the added approvers to send the request to.',
        comment: 'Select approval type - select one description',
    });
    const handleChange = (event) => {
        onChange(event.target.value);
    };
    const getDescription = () => {
        if (!value) {
            return undefined;
        }
        return value === ApprovalType.All ? allDescription : oneOfDescription;
    };
    return (<Select value={value} onChange={handleChange} description={getDescription()} placeholder={t({
            message: 'Select type',
            comment: 'Select approval type - placeholder',
        })}>
      <option value={ApprovalType.All}>
        <Trans comment="Select approval type - select all label">By all approvers</Trans>
      </option>
      <option value={ApprovalType.OneOf}>
        <Trans comment="Select approval type - select one label">By one of the approvers</Trans>
      </option>
    </Select>);
}
