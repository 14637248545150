import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const IconContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxToRem(56)};
  height: ${pxToRem(56)};
  border-radius: ${themeGet('borderRadius.circle')};
  background-color: ${themeGet('colors.green.500')};

  [data-icon='falcon'] {
    color: ${themeGet('colors.gray.0')};
  }
`;
