import { t } from '@lingui/macro';
import { Alert, Box, EmptyState, LoaderOverlay } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { ActivityLog } from 'shared/domains/common-ui';
import { SignRequestCancelled } from './SignRequestCancelled';
import { SignRequestCreated } from './SignRequestCreated';
import { SignRequestExpired } from './SignRequestExpired';
import { SignRequestFailed } from './SignRequestFailed';
import { SignRequestRejected } from './SignRequestRejected';
import { SignRequestReminded } from './SignRequestReminded';
import { SignRequestSignatoryChanged } from './SignRequestSignatoryChanged';
import { SignRequestSignatoryOpened } from './SignRequestSignatoryOpened';
import { SignRequestSignatoryRejected } from './SignRequestSignatoryRejected';
import { SignRequestSignatoryRemoved } from './SignRequestSignatoryRemoved';
import { SignRequestSignatorySigned } from './SignRequestSignatorySigned';
import { SignRequestSigned } from './SignRequestSigned';
import { useEsigningActivity } from './useEsigningActivity';
import { Container } from './styled';
export function ESignActivityTab() {
    const { activities, loading, error } = useEsigningActivity();
    if (loading) {
        return (<Box p="2xl" height="100%">
        <LoaderOverlay label={t({
                comment: 'Esigning activity sidebar - Loading activity label',
                message: 'Loading eSigning activity...',
            })}/>
      </Box>);
    }
    if (error) {
        return (<Box p="2xl">
        <Alert appearance="danger" title={t({
                comment: 'Esigning activity sidebar - Esigning activity error - Title',
                message: 'Could not load eSigning activities',
            })} message={t({
                comment: 'Esigning activity sidebar - Esigning activity error - Message',
                message: 'Something went wrong while loading eSigning activities, please try again',
            })}/>
      </Box>);
    }
    if (activities.length === 0) {
        return (<EmptyState withAvatar icon={<Falcon icon="pen-swirl"/>} title={t({
                comment: 'Esigning activity sidebar - Esigning activity empty state - Title',
                message: 'No activities',
            })} description={t({
                comment: 'Esigning activity sidebar - Esigning activity empty state - Description',
                message: 'There are no activities to show',
            })}/>);
    }
    const getActivityItem = (activity) => {
        // eslint-disable-next-line no-underscore-dangle
        switch (activity.__typename) {
            case 'SignRequestCreatedEsigningActivity':
                return (<SignRequestCreated key={activity.published} actor={activity.actor.user} timestamp={activity.published} viewers={activity.object.viewers} signatories={activity.object.signatories}/>);
            case 'SignRequestCancelledEsigningActivity':
                return (<SignRequestCancelled key={activity.published} actor={activity.actor.user} timestamp={activity.published}/>);
            case 'SignRequestRemindedEsigningActivity':
                return (<SignRequestReminded key={activity.published} actor={activity.actor.user} timestamp={activity.published}/>);
            case 'SignRequestSignedEsigningActivity':
                return <SignRequestSigned key={activity.published} timestamp={activity.published}/>;
            case 'SignRequestFailedEsigningActivity':
                return <SignRequestFailed key={activity.published} timestamp={activity.published}/>;
            case 'SignRequestSignatoryOpenedEsigningActivity':
                return (<SignRequestSignatoryOpened key={activity.published} actor={activity.actor} timestamp={activity.published}/>);
            case 'SignRequestRejectedEsigningActivity':
                return <SignRequestRejected key={activity.published} timestamp={activity.published}/>;
            case 'SignRequestExpiredEsigningActivity':
                return <SignRequestExpired key={activity.published} timestamp={activity.published}/>;
            case 'SignRequestSignatorySignedEsigningActivity':
                return (<SignRequestSignatorySigned key={activity.published} actor={activity.actor} timestamp={activity.published}/>);
            case 'SignRequestSignatoryRemovedEsigningActivity':
                return (<SignRequestSignatoryRemoved key={activity.published} actor={activity.actor.user} timestamp={activity.published} removedUserName={activity.object.name}/>);
            case 'SignRequestSignatoryChangedEsigningActivity':
                return (<SignRequestSignatoryChanged key={activity.published} actor={activity.actor.user} timestamp={activity.published} changedData={activity.object}/>);
            case 'SignRequestSignatoryRejectedEsigningActivity':
                return (<SignRequestSignatoryRejected key={activity.published} actor={activity.actor} timestamp={activity.published} message={activity.object.message}/>);
            default:
                return null;
        }
    };
    return (<Container>
      <ActivityLog>{activities.map(getActivityItem)}</ActivityLog>
    </Container>);
}
