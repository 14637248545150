import { Trans, t } from '@lingui/macro';
import { Alert, Box, Button } from '@pocketlaw/tetris';
import { NoApproversAlert } from './NoApproversAlert';
export function RequestFooter(props) {
    const { approvalType, disabled, showNoApproversAlert, onSubmit } = props;
    const getAlert = () => {
        if (showNoApproversAlert) {
            return <NoApproversAlert />;
        }
        if (approvalType === 'voluntary') {
            return (<Alert appearance="info" title={t({
                    comment: 'Approval sidebar - Voluntary approval info - Info label',
                    message: 'Voluntary approval',
                })} message={t({
                    comment: 'Approval sidebar - Voluntary approval info - Info message',
                    message: 'Request a voluntary review from anyone in your organization to get their input on the document. This feature is optional.',
                })}/>);
        }
        if (approvalType === 'all') {
            return (<Alert appearance="info" title={t({
                    comment: 'Approval sidebar - Needs approval by all of the approvers - Info label',
                    message: 'Approval required',
                })} message={t({
                    comment: 'Document requires approval state - Needs approval by all of the approvers - Info message',
                    message: 'This document needs approval by all of the assigned approvers before being sent for eSigning',
                })}/>);
        }
        return (<Alert appearance="info" title={t({
                comment: 'Document requires approval state - Needs approval by one of the approvers - Info label',
                message: 'Select approvers',
            })} message={t({
                comment: 'Document requires approval state - Needs approval by one of the approvers - Info message',
                message: 'Select who to send the approval request to. When one of the approvers have approved, the document can be sent for eSigning.',
            })}/>);
    };
    return (<Box p="2xl" pt="0" mt="auto" flexDirection="column" gap="md">
      {getAlert()}
      <Button $stretch disabled={disabled} onClick={onSubmit}>
        <Trans comment="Ask for approval button label">Ask for approval</Trans>
      </Button>
    </Box>);
}
