import { Trans, t } from '@lingui/macro';
import { Alert, Box, Typography } from '@pocketlaw/tetris';
import { useLastApprovalRequest } from 'app/domains/approval/hooks/useLastApprovalRequest';
import { useApprovalWorkflow } from '../../../ApprovalWorkflowProvider';
export function RejectedByApprover() {
    var _a;
    const { documentId } = useApprovalWorkflow();
    const { rejecter } = useLastApprovalRequest(documentId);
    if (!rejecter) {
        return null;
    }
    const alertTitle = t({
        comment: 'Document approval rejected alert - Title',
        message: 'Approval rejected',
    });
    const alertMessage = t({
        comment: 'Document approval rejected alert - Message',
        message: `${(_a = rejecter.user) === null || _a === void 0 ? void 0 : _a.name} rejected the approval request. Review your document and send it for approval again.`,
    });
    return (<Alert appearance="warning" title={alertTitle} message={alertMessage}>
      {rejecter.statusMessage && (<Box flexDirection="column" gap="xs">
          <Typography $appearance="200" $fontSize="small" $fontWeight="semibold">
            <Trans comment="Document approval rejected alert - Rejection message label">
              Rejection message
            </Trans>
          </Typography>
          <Typography $appearance="100" $fontSize="small">
            {rejecter.statusMessage}
          </Typography>
        </Box>)}
    </Alert>);
}
