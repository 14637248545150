import { Trans } from '@lingui/macro';
import { ActivityEntry, ActivityEntryText } from 'shared/domains/common-ui';
import { getIcon } from './utils';
export function ApprovalRequestApproved(props) {
    const { timestamp } = props;
    return (<ActivityEntry appearance="success" icon={getIcon('success')} timestamp={timestamp} text={<ActivityEntryText>
          <Trans comment="Approval activity - Request fully approved">
            The request was fully approved
          </Trans>
        </ActivityEntryText>}/>);
}
