import { booleanFormatter, categoryFormatter, dateFormatter, documentStatusFormatter, entityTypeFormatter, esigningStatusFormatter, marketFormatter, metadataValueFormatter, stringFormatter, userFormatter, } from './formatters';
export function fieldDisplayValue(field, value) {
    if (!field || !value) {
        return stringFormatter(value);
    }
    switch (field) {
        case 'createdAt':
        case 'metadataDocumentDateSigned':
        case 'metadataDocumentNoticeDate':
        case 'metadataDocumentStartDate':
        case 'metadataDocumentTerminationDate':
        case 'updatedAt':
            return dateFormatter(value);
        case 'categoryId':
            return categoryFormatter(value);
        case 'createdByUserId':
        case 'metadataDocumentContractOwner':
            return userFormatter(value);
        case 'entityType':
            return entityTypeFormatter(value);
        case 'metadataDocumentActive':
        case 'metadataDocumentAutomaticRenewal':
        case 'isPlGenerated':
        case 'metadataDocumentDeviateFromStandard':
        case 'metadataDocumentIsSharedExternally':
        case 'metadataDocumentMaterialAgreement':
        case 'metadataDocumentOutsourcing':
        case 'metadataDocumentLimitationOfLiability':
            return booleanFormatter(value);
        case 'marketCode':
            return marketFormatter(value);
        case 'metadataDocumentStatus':
            return documentStatusFormatter(value);
        case 'metadataDocumentCountry':
            return metadataValueFormatter('Country', value);
        case 'metadataDocumentCurrency':
            return metadataValueFormatter('Currency', value);
        case 'metadataDocumentDocumentCategory':
            return metadataValueFormatter('DocumentCategory', value);
        case 'metadataDocumentDocumentType':
            return metadataValueFormatter('DocumentType', value);
        case 'metadataDocumentGoverningLaw':
            return metadataValueFormatter('GoverningLaw', value);
        case 'metadataDocumentProcessingOfPersonalData':
            return metadataValueFormatter('ProcessingOfPersonalData', value);
        case 'metadataDocumentType':
            return metadataValueFormatter('DocumentType', value);
        case 'metadataDocumentLiabilityCapScope':
            return metadataValueFormatter('LiabilityCapScope', value);
        case 'metadataDocumentEsigningStatus':
            return esigningStatusFormatter(value);
        case 'metadataDocumentTotalContractCost':
        case 'metadataDocumentTotalContractValue':
        default:
            stringFormatter(value);
    }
    return stringFormatter(value);
}
