import { Trans, t } from '@lingui/macro';
import { ActivityEntry, ActivityEntryText } from 'shared/domains/common-ui';
import { getIcon } from './utils';
export function ApprovalRequestApproverApproved(props) {
    const { user, timestamp, message } = props;
    const fallbackName = t({
        message: 'Unknown',
        comment: 'Fallback name when user does not exist',
    });
    const userName = (user === null || user === void 0 ? void 0 : user.name) || fallbackName;
    return (<ActivityEntry appearance="success" icon={getIcon('success')} timestamp={timestamp} text={<ActivityEntryText>
          <Trans comment="Approval activity - User approved the request">
            <b>{userName}</b> approved the request
          </Trans>
        </ActivityEntryText>} message={message}/>);
}
