import { useState } from 'react';
import { Trans } from '@lingui/macro';
import { Tabs, Tab } from '@pocketlaw/tetris';
import { TabsContainer, StyledTabContent, StyledTabNav } from './styled';
import { EsignFormTabs } from './types';
export function ESignTabs(props) {
    const { parties, settings, activity } = props;
    const [activeTab, setActiveTab] = useState(EsignFormTabs.Parties);
    const handleSelectTab = (tab) => {
        setActiveTab(tab);
    };
    return (<TabsContainer>
      <Tabs activeTab={activeTab} onSelect={handleSelectTab}>
        <StyledTabNav>
          <Tab id={EsignFormTabs.Parties}>
            <Trans comment="Label for e-signing parties tab">Parties</Trans>
          </Tab>
          {settings && (<Tab id={EsignFormTabs.Settings}>
              <Trans comment="Label for e-signing settings tab">Settings</Trans>
            </Tab>)}
          <Tab id={EsignFormTabs.Activity}>
            <Trans comment="Label for e-signing activity tab">Activity</Trans>
          </Tab>
        </StyledTabNav>
        <StyledTabContent tabId={EsignFormTabs.Parties}>{parties}</StyledTabContent>
        <StyledTabContent tabId={EsignFormTabs.Settings}>{settings}</StyledTabContent>
        <StyledTabContent tabId={EsignFormTabs.Activity}>{activity}</StyledTabContent>
      </Tabs>
    </TabsContainer>);
}
