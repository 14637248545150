import { t } from '@lingui/macro';
import { formatPhoneNumber } from '@pocketlaw/tetris';
import { Party } from './Party';
import { PartyRow } from './PartyRow';
import { getAuthenticationModeTitle } from '../../utils';
export function Signatory(props) {
    const { name, email, mobile, message, authenticationMode, order, endElement } = props;
    return (<Party name={name} email={email} message={message} endElement={endElement} order={order}>
      {mobile && (<PartyRow label={t({ comment: 'Signatory - Mobile label', message: 'Mobile' })} value={formatPhoneNumber(mobile)}/>)}
      {authenticationMode && (<PartyRow label={t({ comment: 'Signatory - Authentication label', message: 'Authentication' })} value={getAuthenticationModeTitle(authenticationMode)}/>)}
    </Party>);
}
