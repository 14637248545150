import { Box, Typography } from '@pocketlaw/tetris';
import { localizedFormatDate } from 'shared/domains/common-utils';
import { Container, EntryLine, IconContainer, LeftColumn, MessageContainer, RightColumn, TextContainer, } from './styled';
export function ActivityEntry(props) {
    const { icon, appearance, timestamp, text, message, children } = props;
    const formattedTimestamp = localizedFormatDate(timestamp, {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    });
    return (<Container>
      <LeftColumn>
        <IconContainer $appearance={appearance}>{icon}</IconContainer>
        <EntryLine />
      </LeftColumn>
      <RightColumn>
        <TextContainer>{text}</TextContainer>
        <Box flexDirection="column" gap="md">
          <Typography $appearance="100" $fontSize="small">
            {formattedTimestamp}
          </Typography>
          {message && (<MessageContainer>
              <Typography $appearance="300" $fontSize="small">
                {message}
              </Typography>
            </MessageContainer>)}
          {children}
        </Box>
      </RightColumn>
    </Container>);
}
