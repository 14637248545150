import { useState } from 'react';
import { Trans } from '@lingui/macro';
import { Box } from '@pocketlaw/tetris';
import { ToggleTabs, ToggleTabsButton, ToggleTabsButtonsContainer, ToggleTabsContent, } from 'shared/domains/common-ui';
import { Activity } from './Activity';
import { Approvers } from './Approvers';
import { VoluntaryApproversProvider } from './VoluntaryApproversProvider';
export function ApprovalWorkflowSidebar() {
    const [activeTab, setActiveTab] = useState('approvers');
    return (<VoluntaryApproversProvider>
      <Box height="100%" style={{ overflow: 'hidden' }}>
        <ToggleTabs activeTab={activeTab} onSelectTab={setActiveTab}>
          <Box flexDirection="column" width="100%">
            <Box px="2xl">
              <ToggleTabsButtonsContainer stretch size="small">
                <ToggleTabsButton tabKey="approvers">
                  <Trans comment="Approval sidebar - Request tab label">Request</Trans>
                </ToggleTabsButton>
                <ToggleTabsButton tabKey="activity">
                  <Trans comment="Approval sidebar - Activity tab label">Activity</Trans>
                </ToggleTabsButton>
              </ToggleTabsButtonsContainer>
            </Box>
            <ToggleTabsContent tabKey="approvers">
              <Box flexDirection="column" style={{ flex: '1 1 auto', overflow: 'hidden' }}>
                <Approvers />
              </Box>
            </ToggleTabsContent>
            <ToggleTabsContent tabKey="activity">
              <Activity />
            </ToggleTabsContent>
          </Box>
        </ToggleTabs>
      </Box>
    </VoluntaryApproversProvider>);
}
