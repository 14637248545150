import { Avatar, AvatarWithLabel, Radio, TableBodyCell } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useTemplateBrowserContext } from 'app/domains/templates';
import Flag from 'shared/domains/languages/components/Flag';
import { StyledButton, StyledTableRow } from './styled';
export function Row(props) {
    const { template } = props;
    const { id, name, locale, locked } = template;
    const { selectTemplateId, selectedTemplateId } = useTemplateBrowserContext();
    const selected = id === selectedTemplateId;
    const handleClick = () => selectTemplateId(id);
    const handleChange = () => selectTemplateId(id);
    return (<StyledTableRow $hoverCursor $disabled={locked} disabled={locked} onClick={handleClick}>
      <TableBodyCell headers="cell:name">
        <StyledButton>
          <AvatarWithLabel size="sm" appearance="light" shape="circle" title={name}>
            <Falcon icon="file"/>
          </AvatarWithLabel>
        </StyledButton>
      </TableBodyCell>
      <TableBodyCell headers="cell:language">
        <AvatarWithLabel size="sm" title={locale.name}>
          <Avatar>
            <Flag languageCode={locale.code}/>
          </Avatar>
        </AvatarWithLabel>
      </TableBodyCell>
      <TableBodyCell headers="cell:select">
        <Radio checked={selected} disabled={locked} onChange={handleChange}/>
      </TableBodyCell>
    </StyledTableRow>);
}
