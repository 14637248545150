import { t } from '@lingui/macro';
import { SearchAggregationType, } from 'shared/domains/apollo/generated/types';
import { allowedPropertiesTranslations } from './translations';
const values = [
    'categoryId',
    'createdByUserId',
    'entityType',
    'marketCode',
    'metadataDocumentActive',
    'metadataDocumentAutomaticRenewal',
    'metadataDocumentContractOwner',
    'metadataDocumentCountry',
    'metadataDocumentCurrency',
    'metadataDocumentDeviateFromStandard',
    'metadataDocumentDiscount',
    'metadataDocumentDocumentCategory',
    'metadataDocumentDocumentType',
    'metadataDocumentEsigningStatus',
    'metadataDocumentFeeAdjustmentClause',
    'metadataDocumentGoverningLaw',
    'metadataDocumentIndemnity',
    'metadataDocumentIsSharedExternally',
    'metadataDocumentLiabilityCapScope',
    'metadataDocumentLimitationOfLiability',
    'metadataDocumentMaterialAgreement',
    'metadataDocumentOutsourcing',
    'metadataDocumentPostTerminationObligations',
    'metadataDocumentProbationaryPeriod',
    'metadataDocumentProcessingOfPersonalData',
    'metadataDocumentStatus',
    'metadataDocumentTerminationForCause',
    'metadataDocumentTerminationForConvenience',
    'metadataDocumentType',
    'metadataDocumentParties',
];
export const getTermsAllowedProperties = () => ({
    type: SearchAggregationType.Terms,
    label: t({ message: 'Terms', comment: 'Insights - Terms aggregation' }),
    values: values.map(value => ({ value, label: allowedPropertiesTranslations(value) })),
});
