import { t } from '@lingui/macro';
import { EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { SelectApprovers } from '../../../SelectApprovers';
import { useVoluntaryApprovers } from '../../VoluntaryApproversProvider';
export function NoApprovers() {
    const { selectedApprovers, selectedTeamApprovers, addApprovers, addTeamApprovers } = useVoluntaryApprovers();
    return (<EmptyState withAvatar icon={<Falcon icon="user-group"/>} title={t({
            comment: 'Approval sidebar - No approvers added - title',
            message: 'No approvers added',
        })} description={t({
            comment: 'Approval sidebar - No approvers added - description',
            message: 'Add approvers to send the document for approval.',
        })} actions={<SelectApprovers buttonAppearance="secondary" approvers={selectedApprovers} teamApprovers={selectedTeamApprovers} onAddApprovers={addApprovers} onAddTeamApprovers={addTeamApprovers}/>}/>);
}
