import { Trans, t } from '@lingui/macro';
import { ActivityEntry, ActivityEntryText } from 'shared/domains/common-ui';
import { getIcon } from './utils';
export function SignRequestSignatorySigned(props) {
    const { actor, timestamp } = props;
    const fallbackName = t({
        message: 'Unknown',
        comment: 'Fallback name when user does not exist',
    });
    const userName = (actor === null || actor === void 0 ? void 0 : actor.name) || fallbackName;
    return (<ActivityEntry appearance="success" icon={getIcon('success')} timestamp={timestamp} text={<ActivityEntryText>
          <Trans comment="Esigning request - Signatory signed the document">
            <b>{userName}</b> signed the document
          </Trans>
        </ActivityEntryText>}/>);
}
