import { useMutation } from '@apollo/client';
import { useLastApprovalRequest } from 'app/domains/approval/hooks/useLastApprovalRequest';
import { SendApprovalRequestReminderDocument } from './mutation.gql';
import { useApprovalWorkflow } from '../../components/ApprovalWorkflowProvider';
export const useSendReminderToApprovers = () => {
    const { documentId } = useApprovalWorkflow();
    const { lastApprovalRequest } = useLastApprovalRequest(documentId);
    const { id } = lastApprovalRequest || {};
    const approvalRequestId = id !== null && id !== void 0 ? id : '';
    const [mutate, result] = useMutation(SendApprovalRequestReminderDocument, {
        variables: { input: { approvalRequestId } },
    });
    const sendReminder = () => mutate();
    return Object.assign({ sendReminder }, result);
};
