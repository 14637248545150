import { Table, TableRow } from '@pocketlaw/tetris';
import styled, { css } from 'styled-components';
export const StyledTable = styled(Table) `
  table-layout: fixed;
`;
export const StyledButton = styled.button `
  display: flex;
  cursor: inherit;
  max-width: 100%;
`;
export const StyledTableRow = styled(TableRow) `
  ${props => props.$hoverCursor && 'cursor: pointer;'};
  ${props => props.$disabled &&
    css `
      cursor: default;
      pointer-events: none;
      opacity: 0.3;
    `};
`;
export const AlertContainer = styled.div `
  position: sticky;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
`;
