import { useEffect } from 'react';
import { useApprovalWorkflow } from 'app/domains/approval';
import { useEsigning } from 'app/domains/esigning';
import { usePleditor } from 'shared/domains/documents';
const READ_ONLY_KEY = 'esigning';
export const useDocumentLocked = () => {
    const { pleditor } = usePleditor();
    const { signed, creating, pending, cancelling, submittingCancellation, submittingCreation } = useEsigning();
    const { approvalPending, approvalApproved } = useApprovalWorkflow();
    const esigningLocked = signed || creating || pending || cancelling || submittingCancellation || submittingCreation;
    /*
     * This hook allows us to completely lock the document from went its sent for eSigning
     */
    useEffect(() => {
        if (esigningLocked) {
            pleditor === null || pleditor === void 0 ? void 0 : pleditor.enableReadOnlyMode(READ_ONLY_KEY);
        }
        else {
            pleditor === null || pleditor === void 0 ? void 0 : pleditor.disableReadOnlyMode(READ_ONLY_KEY);
        }
    }, [esigningLocked, pleditor]);
    if (signed) {
        return {
            locked: true,
            reason: 'esigning:signed',
        };
    }
    if (creating || pending || cancelling || submittingCancellation || submittingCreation) {
        return {
            locked: true,
            reason: 'esigning:pending',
        };
    }
    if (approvalApproved) {
        return {
            locked: true,
            reason: 'approval:approved',
        };
    }
    if (approvalPending) {
        return {
            locked: true,
            reason: 'approval:pending',
        };
    }
    return { locked: false, reason: null };
};
