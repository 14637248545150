import { useState } from 'react';
import { Trans } from '@lingui/macro';
import { Box, Button, IconButton, Loader, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { PromptLibraryModal, PromptLibraryProvider } from 'app/domains/prompt-library';
import { useFeatureFlag } from 'shared/domains/featureFlag';
import { PlaiDisclaimerText } from './PlaiDisclaimerText';
import { useChatWrapperContext } from '../ChatWrapper/hook';
import { ActionsContainer, Container, InputContainer, PrependElement, PrependIcon, StyledTextArea, Wrapper, } from './styled';
const INPUT_MAX_LENGTH = 4000;
export function ChatInput(props) {
    const { value: controlledValue, loading, disabled, placeholder, initialValue = '', prependElements, actions, send, cancel, showPlaiDisclaimerText = false, onChange, } = props;
    const { scrollToBottom } = useChatWrapperContext();
    const [uncontrolledValue, setUncontrolledValue] = useState(initialValue);
    const [promptLibraryOpen, setPromptLibraryOpen] = useState(false);
    const [focus, setFocus] = useState(false);
    const value = controlledValue || uncontrolledValue;
    const isPromptLibraryAvailable = useFeatureFlag('feature_prompt_library');
    const isSendDisabled = send.disabled || value.length === 0;
    /**
     * Used to update the value of the input.
     * If onChange is passed the component instance is controlled, otherwise we set it directly.
     */
    const updateValue = (newValue) => {
        if (onChange) {
            onChange(newValue);
        }
        else {
            setUncontrolledValue(newValue);
        }
    };
    const onChangeValue = (event) => updateValue(event.target.value);
    const handleOpenPromptLibrary = () => setPromptLibraryOpen(true);
    const handleClosePromptLibrary = () => setPromptLibraryOpen(false);
    const handleSelectPrompt = (prompt) => {
        updateValue(prompt);
        handleClosePromptLibrary();
    };
    const handleSendMessage = () => {
        if (isSendDisabled) {
            return;
        }
        send.onSend(value);
        updateValue('');
        if (scrollToBottom) {
            scrollToBottom('smooth');
        }
    };
    const handleKeyDown = (event) => {
        if (isSendDisabled) {
            return;
        }
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSendMessage();
        }
    };
    const renderPrimaryButton = () => {
        const buttonProps = {
            size: 'small',
            appearance: 'primary',
        };
        if (loading) {
            return (<IconButton {...buttonProps} disabled>
          <Loader />
        </IconButton>);
        }
        if (cancel === null || cancel === void 0 ? void 0 : cancel.render) {
            return (<IconButton {...buttonProps} disabled={cancel.disabled} onClick={cancel.onCancel}>
          <Falcon icon="circle-stop"/>
        </IconButton>);
        }
        const appearance = value.length === 0 ? 'ghost' : buttonProps.appearance;
        return (<IconButton {...buttonProps} appearance={appearance} disabled={isSendDisabled} onClick={handleSendMessage}>
        <Falcon icon="arrow-right"/>
      </IconButton>);
    };
    return (<Wrapper $paddingBottom={!showPlaiDisclaimerText}>
      <Container $focus={focus}>
        <InputContainer>
          <Box alignItems="flex-start">
            <StyledTextArea stretch autoFocus autosizeHeight maxLength={INPUT_MAX_LENGTH} maxHeight={260} appearance="transparent" value={value} disabled={disabled} placeholder={placeholder} onChange={onChangeValue} onKeyDown={handleKeyDown} onFocus={() => setFocus(true)} onBlur={() => setFocus(false)}/>
            <Box p="lg">{renderPrimaryButton()}</Box>
          </Box>
          {prependElements && (<Box alignItems="center" p="md" gap="md">
              {prependElements.map(element => (<PrependElement key={element.label}>
                  <PrependIcon>{element.icon}</PrependIcon>
                  <Typography $fontSize="small" $fontWeight="semibold" $color="gray.200">
                    {element.label}
                  </Typography>
                  <Box ml="auto">
                    <IconButton size="xs" appearance="glass" onClick={element.onClick}>
                      <Falcon icon="xmark"/>
                    </IconButton>
                  </Box>
                </PrependElement>))}
            </Box>)}
        </InputContainer>
        <ActionsContainer>
          <Box alignItems="center" p="md">
            <Box gap="sm">
              <Button size="small" appearance="ghost" onClick={handleOpenPromptLibrary}>
                <Falcon icon="book-bookmark"/>
                <Trans comment="Chat input actions - Prompts label">Prompts</Trans>
              </Button>
              {actions}
            </Box>
            <Box ml="auto" pr="md">
              <Typography $fontSize="tiny" $appearance="200">
                {value.length} / {INPUT_MAX_LENGTH}
              </Typography>
            </Box>
          </Box>
        </ActionsContainer>
      </Container>
      {showPlaiDisclaimerText && <PlaiDisclaimerText />}
      {isPromptLibraryAvailable && promptLibraryOpen && (<PromptLibraryProvider defaultView="library" onSelect={handleSelectPrompt} onClose={handleClosePromptLibrary}>
          <PromptLibraryModal />
        </PromptLibraryProvider>)}
    </Wrapper>);
}
