import { createContext, useState } from 'react';
import { useTemplates } from './useTemplates';
export const TemplateBrowserContext = createContext(null);
export function TemplateBrowserProvider(props) {
    const { children, onSelect, initialTemplateId } = props;
    const { templates, loading, error } = useTemplates();
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedTemplateId, setSelectedTemplateId] = useState(initialTemplateId);
    const handleSelectTemplateId = (templateId) => {
        setSelectedTemplateId(templateId);
        const template = templates.find(({ id }) => id === templateId);
        if (template) {
            onSelect(template);
        }
    };
    const search = (query) => setSearchQuery(query);
    const clearSearch = () => setSearchQuery('');
    const searching = searchQuery.length > 0;
    const filteredTemplates = templates.filter(template => template.name.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()));
    const value = {
        templates: searching ? filteredTemplates : templates,
        loading,
        selectedTemplateId,
        selectTemplateId: handleSelectTemplateId,
        error: Boolean(error),
        templateBrowserSearch: {
            search,
            searching,
            searchQuery,
            clearSearch,
        },
    };
    return (<TemplateBrowserContext.Provider value={value}>{children}</TemplateBrowserContext.Provider>);
}
