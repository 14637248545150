import { useDraggable, TableRow } from '@pocketlaw/tetris';
import { ContextMenuCell, DateCell, NameCell } from './TableCells';
import { getFileType } from './utils';
export default function DocumentTableRow(props) {
    const { id, type, documentType, name, date, onDuplicate, onRename, onMove, onDelete, onEditAccess, } = props;
    const { draggableProps } = useDraggable(`${type}:${id}`);
    const editUrl = `/document/${id}`;
    const fileType = getFileType(documentType);
    return (<TableRow {...draggableProps}>
      <NameCell headers="filesystemtable-name" to={editUrl} title={name} type={fileType}/>
      <DateCell headers="filesystemtable-updatedAt" date={date}/>
      <ContextMenuCell download id={id} editUrl={editUrl} onDuplicate={onDuplicate} onRename={onRename} onMove={onMove} onDelete={onDelete} onEditAccess={onEditAccess}/>
    </TableRow>);
}
