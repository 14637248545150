import { Trans } from '@lingui/macro';
import { List, ListSubHeader } from '@pocketlaw/tetris';
import { useApprovalWorkflow } from 'app/domains/approval';
import { AccessButton } from './AccessButton';
import { ApprovalButton } from './ApprovalButton';
import { AttachmentsButton } from './AttachmentsButton';
import { ChatButton } from './ChatButton';
import { ESigningButton } from './ESigningButton';
import { ExternalSharingButton } from './ExternalSharingButton';
import { MetadataButton } from './MetadataButton';
import { NoticeDate } from './NoticeDate';
import { TasksButton } from './TasksButton';
export function SettingsList() {
    const { approvalWorkflowAvailable } = useApprovalWorkflow();
    return (<List>
      <NoticeDate />

      <ListSubHeader>
        <Trans comment="Document settings list - Prepare subheader">Prepare</Trans>
      </ListSubHeader>
      <ChatButton />
      <ExternalSharingButton />
      {approvalWorkflowAvailable && <ApprovalButton />}

      <ListSubHeader>
        <Trans comment="Document settings list - Execute subheader">Execute</Trans>
      </ListSubHeader>
      <AttachmentsButton />
      <ESigningButton />

      <ListSubHeader>
        <Trans comment="Document settings list - Manage subheader">Manage</Trans>
      </ListSubHeader>
      <TasksButton />

      <ListSubHeader>
        <Trans comment="Document settings list - Overview subheader">Overview</Trans>
      </ListSubHeader>
      <MetadataButton />
      <AccessButton />
    </List>);
}
