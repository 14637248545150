import { useState } from 'react';
import { Box } from '@pocketlaw/tetris';
import { ApprovalType } from 'shared/domains/apollo/generated/types';
import { Approver } from '../Approver';
import { ApproversHeader } from '../ApproversHeader';
import { Content } from '../Content';
import { RejectedByApprover } from '../RejectedByApprover';
import { RequestFooter } from '../RequestFooter';
import { SendForApprovalModal } from '../SendForApprovalModal';
export function RequestAll(props) {
    const { approvers } = props;
    const [renderDialog, setRenderDialog] = useState(false);
    const noApprovers = approvers.length === 0;
    const hideDialog = () => setRenderDialog(false);
    const showDialog = () => setRenderDialog(true);
    return (<Box flexDirection="column" height="100%">
      <Content>
        <RejectedByApprover />
        <Box flexDirection="column" gap="md">
          <ApproversHeader />
          {approvers.map(approver => (<Approver key={approver.userId} name={approver.name} subtitle={approver.email}/>))}
        </Box>
      </Content>
      <RequestFooter approvalType="all" showNoApproversAlert={noApprovers} disabled={noApprovers} onSubmit={showDialog}/>
      {renderDialog && (<SendForApprovalModal approvalType={ApprovalType.All} selectedApprovers={approvers} onClose={hideDialog}/>)}
    </Box>);
}
