import { Falcon } from '@pocketlaw/tetris/macro';
export const getIcon = (appearance) => {
    switch (appearance) {
        case 'info':
            return <Falcon icon="circle-info" sourceConfig={{ family: 'classic', style: 'solid' }}/>;
        case 'success':
            return <Falcon icon="circle-check" sourceConfig={{ family: 'classic', style: 'solid' }}/>;
        case 'danger':
            return <Falcon icon="circle-xmark" sourceConfig={{ family: 'classic', style: 'solid' }}/>;
        case 'warning':
        default:
            return <Falcon icon="circle-info" sourceConfig={{ family: 'classic', style: 'solid' }}/>;
    }
};
