import { createContext, useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import { Box } from '@pocketlaw/tetris';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useApprovalWorkflow } from 'app/domains/approval';
import { useCompanyFeatures } from 'app/domains/companies';
import { ReviewProgress } from 'app/domains/review';
import { DocumentDrawerHeader } from 'shared/domains/documents';
import { Dropdown } from './Dropdown';
import { SettingsList } from './SettingsList';
import { getComponent } from './utils';
import { DrawerContainer, ListContainer } from './styled';
import { DocumentSettingsKeys, } from './types';
export const SettingsDrawerContext = createContext(null);
export function SettingsDrawer(props) {
    const { onClose } = props;
    const { pathname } = useLocation();
    const history = useHistory();
    const { url } = useRouteMatch();
    const { isFeatureAvailable } = useCompanyFeatures();
    const { approvalWorkflowAvailable } = useApprovalWorkflow();
    const isContractReviewAvailable = isFeatureAvailable('review-agent:basic');
    const controlledSettingKey = DocumentSettingsKeys.filter(settingKey => approvalWorkflowAvailable ? true : settingKey !== 'approval').find(settingKey => pathname.includes(settingKey));
    const [activeSetting, setActiveSetting] = useState(controlledSettingKey || null);
    useEffect(() => {
        if (controlledSettingKey && controlledSettingKey !== activeSetting) {
            setActiveSetting(controlledSettingKey);
        }
    }, [pathname, controlledSettingKey, activeSetting]);
    const handleClose = () => {
        history.replace(url);
        onClose();
    };
    const handleGoBack = () => {
        history.replace(url);
        setActiveSetting(null);
    };
    const Component = getComponent(activeSetting);
    if (Component) {
        return <Component onGoBack={handleGoBack} onClose={handleClose}/>;
    }
    const value = {
        onSelectSetting: setActiveSetting,
    };
    const title = t({
        comment: 'Document settings drawer - title',
        message: 'Document',
    });
    return (<SettingsDrawerContext.Provider value={value}>
      <DrawerContainer>
        <DocumentDrawerHeader type="root" title={title} onClose={handleClose} actions={<Dropdown />}/>
        {isContractReviewAvailable && (<Box ml="xl" mr="xl">
            <ReviewProgress previewVersion/>
          </Box>)}
        <ListContainer>
          <SettingsList />
        </ListContainer>
      </DrawerContainer>
    </SettingsDrawerContext.Provider>);
}
