import { t } from '@lingui/macro';
import { Falcon } from '@pocketlaw/tetris/macro';
export const getPropsForType = (type) => {
    const types = {
        folder: {
            icon: <Falcon icon="folder"/>,
            appearance: 'dark',
            shape: 'square',
            solid: false,
            tooltip: t({ message: 'Folder', comment: 'Folder tooltip in type avatar' }),
        },
        'folder:locked': {
            icon: <Falcon icon="lock"/>,
            appearance: 'dark',
            shape: 'square',
            solid: false,
            tooltip: t({ message: 'No access', comment: 'Folder tooltip in type avatar' }),
        },
        file: {
            icon: <Falcon icon="file"/>,
            appearance: 'light',
            shape: 'circle',
            solid: false,
            tooltip: t({ message: 'File', comment: 'File tooltip in type avatar' }),
        },
        'file:locked': {
            icon: <Falcon icon="lock"/>,
            appearance: 'light',
            shape: 'circle',
            solid: false,
            tooltip: t({ message: 'No access', comment: 'File tooltip in type avatar' }),
        },
        'file:composed': {
            icon: <Falcon icon="file"/>,
            appearance: 'light',
            shape: 'circle',
            solid: false,
            tooltip: t({ message: 'Composed document', comment: 'File tooltip in type avatar' }),
        },
        'file:freetext': {
            icon: <Falcon icon="file"/>,
            appearance: 'light',
            shape: 'circle',
            solid: false,
            tooltip: t({ message: 'Freetext document', comment: 'File tooltip in type avatar' }),
        },
        'file:uploaded': {
            icon: <Falcon icon="file"/>,
            appearance: 'light',
            shape: 'circle',
            solid: false,
            tooltip: t({ message: 'Uploaded document', comment: 'File tooltip in type avatar' }),
        },
        draft: {
            icon: <Falcon icon="copy"/>,
            appearance: 'warning',
            shape: 'circle',
            solid: false,
            tooltip: t({ message: 'Ongoing template', comment: 'File tooltip in type avatar' }),
        },
    };
    return types[type];
};
