import { themeGet, scrollbar, breakpoints, pxToRem } from '@pocketlaw/tetris';
import styled from 'styled-components';
const MARGIN = themeGet('spacing.md');
const SCROLLBAR_WIDTH = pxToRem(6);
export const Container = styled.div `
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
export const ChatContainer = styled.div `
  --chat-padding: ${themeGet('spacing.2xl')};

  position: relative;
  max-height: 100%;
  overflow: hidden auto;
  padding-top: var(--chat-padding);
  padding-left: var(--chat-padding);
  padding-right: calc(var(--chat-padding) - ${MARGIN} - ${SCROLLBAR_WIDTH});
  margin-right: ${MARGIN};
  ${scrollbar({ trackMargin: 'md' })};

  ${breakpoints.xl} {
    --chat-padding: ${themeGet('spacing.3xl')};
  }

  ${breakpoints.xxl} {
    --chat-padding: ${themeGet('spacing.4xl')};
  }
`;
export const InputContainer = styled.div `
  position: sticky;
  bottom: 0;
`;
