var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useCallback } from 'react';
import { t } from '@lingui/macro';
import { Table, TableHead, TableBody, TableHeadCell, TableHeaderText, TableRow, } from '@pocketlaw/tetris';
import FileSystemTableRow from '../FileSystemTableRow';
import { Container } from './styled';
function FileSystemTable(props) {
    const { sortId, sortOrder, onDuplicate, onRename, onMove, onQuickMove, onDelete, entries, onSort, } = props;
    const handleQuickMove = useCallback((quickMoveData) => {
        var _a, _b;
        const { type, sourceId, targetId } = quickMoveData;
        const sourceName = (_a = entries.find(({ id }) => id === sourceId)) === null || _a === void 0 ? void 0 : _a.name;
        const targetName = (_b = entries.find(({ id }) => id === targetId)) === null || _b === void 0 ? void 0 : _b.name;
        onQuickMove({ type, sourceId, targetId, sourceName, targetName });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [entries]);
    return (<Container>
      <Table sortId={sortId} sortOrder={sortOrder} onSort={(id, order) => {
            if (id) {
                onSort(id, order);
            }
        }}>
        <colgroup>
          <col width="auto"/>
          <col width={64}/>
          <col width={32}/>
        </colgroup>
        <TableHead>
          <TableRow>
            <TableHeadCell id="filesystemtable-name" maxWidth={400}>
              <TableHeaderText sortId="name">
                {t({
            message: 'Title',
            comment: 'Files: table column head for the entry title',
            id: 'filesystem.table.name',
        })}
              </TableHeaderText>
            </TableHeadCell>
            <TableHeadCell id="filesystemtable-updatedAt">
              <TableHeaderText sortId="updatedAt">
                {t({
            message: 'Updated',
            comment: 'Files: table column head for the entry updated date',
            id: 'filesystem.table.updatedAt',
        })}
              </TableHeaderText>
            </TableHeadCell>
            <TableHeadCell id="filesystemtable-contextmenu"/>
          </TableRow>
        </TableHead>
        <TableBody>
          {entries.map((_a) => {
            var { id, kind } = _a, entry = __rest(_a, ["id", "kind"]);
            return (<FileSystemTableRow {...entry} key={id} id={id} type={kind} onDuplicate={onDuplicate} onRename={onRename} onMove={onMove} onDelete={onDelete} onQuickMove={handleQuickMove}/>);
        })}
        </TableBody>
      </Table>
    </Container>);
}
export default FileSystemTable;
