import { t } from '@lingui/macro';
import { Box, Chip, TableBodyCell, TableCellEmpty, Typography } from '@pocketlaw/tetris';
import { useFileBrowserContext } from 'app/domains/filesystem';
import { UserAvatarWithLabel } from 'app/domains/users';
import { EntryKind } from 'shared/domains/apollo/generated/types';
import { localizedFormatDate } from 'shared/domains/common-utils';
import { TypeAvatarWithLabel } from '../../TypeAvatarWithLabel';
import { StyledButton, StyledTableRow } from './styled';
export function Row(props) {
    const { entry, multiselect } = props;
    const { id, kind, name, updatedAt, owner, disabled: entryDisabled } = entry;
    const { selfId, selected, onSelect } = useFileBrowserContext();
    const handleSelect = () => onSelect(entry);
    const disabled = entryDisabled || selfId === id;
    const checked = selected === null || selected === void 0 ? void 0 : selected.some(selectedEntry => selectedEntry.id === id);
    const creatorName = (owner === null || owner === void 0 ? void 0 : owner.name) || '';
    const localizedUpdatedAt = localizedFormatDate(updatedAt, {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
    });
    const updatedAtElement = (<Typography $fontSize="small" $appearance="300">
      {localizedUpdatedAt}
    </Typography>);
    if (kind === EntryKind.Folder) {
        return (<StyledTableRow $hoverCursor onClick={handleSelect}>
        <TableBodyCell headers="cell:name">
          <StyledButton type="button" onClick={handleSelect}>
            <TypeAvatarWithLabel title={name} type="folder" truncate/>
          </StyledButton>
        </TableBodyCell>
        <TableBodyCell headers="cell:updated">{updatedAtElement}</TableBodyCell>
        <TableBodyCell headers="cell:owner">
          <TableCellEmpty />
        </TableBodyCell>
      </StyledTableRow>);
    }
    return (<StyledTableRow $hoverCursor $selected={checked} $disabled={disabled} onClick={handleSelect}>
      <TableBodyCell headers="cell:name">
        <Box alignItems="center" gap="md">
          <TypeAvatarWithLabel multiselect={multiselect} title={name} type="file" truncate selected={checked} onSelect={handleSelect}/>
          {selfId === id && (<Chip size="small" appearance="primary" label={t({ comment: 'Current document label', message: 'Current document' })}/>)}
        </Box>
      </TableBodyCell>
      <TableBodyCell headers="cell:updated">{updatedAtElement}</TableBodyCell>
      <TableBodyCell headers="cell:creator">
        <UserAvatarWithLabel title={creatorName} size="sm"/>
      </TableBodyCell>
    </StyledTableRow>);
}
