import { t } from '@lingui/macro';
import { SidebarMenuItem } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useGtm } from 'shared/domains/tracking';
export function UserGuidingButton() {
    const { push } = useGtm();
    const handleClick = () => {
        const id = Number(process.env.USER_GUIDING_RESOURCE_CENTER_ID);
        if (process.env.NODE_ENV === 'production') {
            window.userGuiding.launchResourceCenter(id, { sidebarOpen: true });
            push({ event: 'open_user_guiding_resource_center' });
        }
    };
    return (<SidebarMenuItem icon={<Falcon icon="lightbulb"/>} label={t({ comment: 'User guiding knowledge hub menu label', message: 'Knowledge hub' })} onClick={handleClick}/>);
}
