import { t } from '@lingui/macro';
import { Box, LoaderOverlay } from '@pocketlaw/tetris';
import { useDocumentApproversWithStatus } from 'app/domains/approval/hooks/useDocumentApproversWithStatus';
import { ApprovalType } from 'shared/domains/apollo/generated/types';
import { ApprovalApproved } from './ApprovalApproved';
import { ApprovalPending } from './ApprovalPending';
import { RequestAll } from './RequestAll';
import { RequestOneOf } from './RequestOneOf';
import { RequestVoluntary } from './RequestVoluntary';
import { useApprovalWorkflow } from '../../ApprovalWorkflowProvider';
export function Approvers() {
    const { approvalPending, approvalType, approvalApproved } = useApprovalWorkflow();
    const { approvers, loading } = useDocumentApproversWithStatus();
    if (loading) {
        return (<Box height="100%">
        <LoaderOverlay label={t({
                comment: 'Approval sidebar - Loading approvers label',
                message: 'Loading approvers...',
            })}/>
      </Box>);
    }
    if (approvalApproved) {
        return <ApprovalApproved approvers={approvers}/>;
    }
    if (approvalPending) {
        return <ApprovalPending approvers={approvers}/>;
    }
    if (!approvalType) {
        return <RequestVoluntary />;
    }
    if (approvalType === ApprovalType.All) {
        return <RequestAll approvers={approvers}/>;
    }
    return <RequestOneOf approvers={approvers}/>;
}
