import { TabContent, TabNav, unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const TabsContainer = styled.div `
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
export const StyledTabNav = styled(TabNav) `
  padding: 0 ${unit('xl')};
`;
export const StyledTabContent = styled(TabContent) `
  flex: 1 1 auto;
  margin-top: 0;
  overflow: hidden auto;

  &:focus-visible {
    box-shadow: none;
  }
`;
