import { Avatar, Radio, TableBodyCell, TableRow, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { EntryKind } from 'shared/domains/apollo/generated/types';
import { CurrentDocumentBadge } from './CurrentDocumentBadge';
import { DocumentAiDisabledBadge } from './DocumentAiDisabledBadge';
import { KindIcon } from './KindIcon';
import { StatusBadge } from './StatusBadge';
import { EntryName, Spacer, TableCellContainer } from './styled';
export function Row(props) {
    const { entry, disabled, documentAiDisabledReason, isSelf, onSelect, selected, status } = props;
    const appearance = entry.kind === 'WORKSPACE' || entry.kind === EntryKind.Folder ? 'success' : 'primary';
    return (<TableRow $selected={selected}>
      <TableBodyCell headers="name">
        <TableCellContainer>
          <Avatar size="md" appearance={disabled ? 'secondary' : appearance}>
            <KindIcon kind={entry.kind} documentType={entry.type} mimeType={entry.mimeType}/>
          </Avatar>
          <Spacer>
            <EntryName disabled={disabled} onClick={() => onSelect(entry)}>
              <Typography $appearance="300">{entry.name}</Typography>
            </EntryName>
            <StatusBadge status={status}/>
            {isSelf && <CurrentDocumentBadge />}
            {!isSelf && documentAiDisabledReason !== undefined && (<DocumentAiDisabledBadge reason={documentAiDisabledReason}/>)}
          </Spacer>
        </TableCellContainer>
      </TableBodyCell>
      <TableBodyCell headers="radio">
        <TableCellContainer>
          {entry.kind === EntryKind.Document && (<Radio checked={selected} disabled={disabled} onChange={() => onSelect(entry)}/>)}
          {entry.kind === EntryKind.Folder && <Falcon icon="chevron-right" $size={12}/>}
        </TableCellContainer>
      </TableBodyCell>
    </TableRow>);
}
