import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const IconContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxToRem(24)};
  height: ${pxToRem(24)};

  [data-icon='falcon'] {
    width: ${pxToRem(12)};
    height: ${pxToRem(12)};
    color: ${themeGet('colors.gray.500')};
    transition: color 175ms ease-out;
  }
`;
export const Container = styled.button `
  outline: solid ${pxToRem(2)} transparent;
  outline-offset: ${pxToRem(-1)};
  width: 100%;
  border-radius: ${themeGet('borderRadius.md')};
  border: solid ${pxToRem(1)} ${themeGet('colors.border.100')};
  transition: all 175ms ease-out;

  &:hover:not(:disabled) {
    cursor: pointer;
    border-color: ${themeGet('colors.border.200')};

    ${IconContainer} {
      [data-icon='falcon'] {
        color: ${themeGet('colors.gray.900')};
      }
    }
  }

  &:focus-visible:not(:disabled) {
    outline-color: ${themeGet('colors.focus.primary')};
  }
`;
export const ExpandContainer = styled.div `
  border-top: solid ${pxToRem(1)} ${themeGet('colors.border.100')};
`;
export const MessageContainer = styled.div `
  line-height: 130%;
  width: fit-content;
  min-width: 100%;
  text-align: left;
  padding: ${themeGet('spacing.md')} ${themeGet('spacing.lg')};
  border-radius: ${themeGet('borderRadius.md')};
  background-color: ${themeGet('colors.gray.50')};
`;
