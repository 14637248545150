import { t } from '@lingui/macro';
import { ChatMessageRole } from 'shared/domains/apollo/generated/types';
import { ChatInput, Chat as ChatComponent } from 'shared/domains/common-ui';
import { ChatWrapper } from 'shared/domains/common-ui/components/Chat';
import { AssistantMessage } from './AssistantMessage';
import { ResolvedAlert } from './ResolvedAlert';
import { UserMessage } from './UserMessage';
import { useResetFuzzySearchOnUnmount } from './useResetFuzzySearchOnUnmount';
import { normalizeChatMessages } from './utils';
import { useAssistant } from '../../AssistantProvider';
import { NoCreditsAlert } from '../Dashboard/NoCreditsAlert';
import { useInputElements } from '../useInputElements';
import { Container } from './styled';
export function Chat(props) {
    const { chat } = props;
    const { id, messages: chatMessages, resolved, resolutionStatus } = chat;
    const { elements } = useInputElements();
    const { addComment, cannotSubmitUserMessage, isStreaming, cancelStreaming, isCancellingStreaming, deletingChat, hasCredits, } = useAssistant();
    useResetFuzzySearchOnUnmount();
    const handleSendMessage = (message) => {
        addComment(id, { message, type: null });
    };
    const sendDisabled = cannotSubmitUserMessage || resolved;
    const messages = normalizeChatMessages(chatMessages);
    const send = {
        disabled: sendDisabled,
        onSend: handleSendMessage,
    };
    const cancel = {
        render: isStreaming,
        disabled: isCancellingStreaming,
        onCancel: cancelStreaming,
    };
    const getInputComponent = () => {
        if (resolved) {
            return <ResolvedAlert chatId={id} status={resolutionStatus}/>;
        }
        if (!hasCredits) {
            return <NoCreditsAlert />;
        }
        return (<ChatInput showPlaiDisclaimerText placeholder={t({
                comment: 'Assistant chat - Conversation textarea placeholder',
                message: 'Ask a question...',
            })} loading={deletingChat} send={send} cancel={cancel} prependElements={elements}/>);
    };
    return (<Container>
      <ChatWrapper chat={<ChatComponent messages={messages} renderMessage={message => message.role === ChatMessageRole.Assistant ? (<AssistantMessage message={message} status={message.status}/>) : (<UserMessage message={message} type={message.type}/>)}/>} input={getInputComponent()}/>
    </Container>);
}
