import { t } from '@lingui/macro';
import { Box, Chip } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Signatory } from '../Signatory';
import { IconContainer } from './styled';
export function ChangedDataTable(props) {
    const { from, to } = props;
    return (<Box flexDirection="column" gap="sm">
      <Signatory {...from} endElement={<Chip size="small" appearance="danger" label={t({ comment: 'Old label', message: 'Old' })}/>}/>
      <IconContainer>
        <Falcon icon="arrow-down" $size={14}/>
      </IconContainer>
      <Signatory {...to} endElement={<Chip size="small" appearance="success" label={t({ comment: 'New label', message: 'New' })}/>}/>
    </Box>);
}
