import { msg } from '@lingui/macro';
export function makeTableCellConfig(name, options) {
    const config = {
        action: {
            id: 'action',
            label: msg ` `,
            disabled: true,
            sortable: false,
            active: false,
        },
        name: {
            id: 'name',
            label: msg({ message: 'Title', comment: 'Search table column header' }),
            colSpan: 1,
            disabled: false,
            sortable: true,
            active: false,
        },
        entityType: {
            id: 'entityType',
            label: msg({ message: 'Type', comment: 'Search table column header' }),
            disabled: false,
            sortable: false,
            active: false,
        },
        categoryId: {
            id: 'categoryId',
            label: msg({ message: 'Template category', comment: 'Search table column header' }),
            disabled: false,
            sortable: false,
            active: false,
        },
        createdAt: {
            id: 'createdAt',
            label: msg({ message: 'Created at', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        updatedAt: {
            id: 'updatedAt',
            label: msg({ message: 'Updated at', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        createdByUser: {
            id: 'createdByUser',
            label: msg({ message: 'Created by', comment: 'Search table column header' }),
            sortable: false,
            active: false,
        },
        metadataDocumentDocumentType: {
            id: 'metadataDocumentDocumentType',
            label: msg({ message: 'Document type', comment: 'Search table column header' }),
            sortable: false,
            active: false,
        },
        metadataDocumentMaterialAgreement: {
            id: 'metadataDocumentMaterialAgreement',
            label: msg({ message: 'Material agreement', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentTerminationDate: {
            id: 'metadataDocumentTerminationDate',
            label: msg({ message: 'Termination date', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentNoticeDate: {
            id: 'metadataDocumentNoticeDate',
            label: msg({ message: 'Notice date', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentDateSigned: {
            id: 'metadataDocumentDateSigned',
            label: msg({ message: 'Date signed', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentActive: {
            id: 'metadataDocumentActive',
            label: msg({ message: 'Active', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentEsigningStatus: {
            id: 'metadataDocumentEsigningStatus',
            label: msg({ message: 'eSigning status', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentIsSharedExternally: {
            id: 'metadataDocumentIsSharedExternally',
            label: msg({ message: 'Shared externally', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentAutomaticRenewal: {
            id: 'metadataDocumentAutomaticRenewal',
            label: msg({ message: 'Automatic renewal', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentContractOwner: {
            id: 'metadataDocumentContractOwner',
            label: msg({ message: 'Contract owner', comment: 'Search table column header' }),
            sortable: false,
            active: false,
        },
        metadataDocumentCountry: {
            id: 'metadataDocumentCountry',
            label: msg({ message: 'Country', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentCurrency: {
            id: 'metadataDocumentCurrency',
            label: msg({ message: 'Currency', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentDeviateFromStandard: {
            id: 'metadataDocumentDeviateFromStandard',
            label: msg({ message: 'Deviate from standard', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentGoverningLaw: {
            id: 'metadataDocumentGoverningLaw',
            label: msg({ message: 'Governing law', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentNotes: {
            id: 'metadataDocumentNotes',
            label: msg({ message: 'Notes', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentNoticePeriodMonths: {
            id: 'metadataDocumentNoticePeriodMonths',
            label: msg({ message: 'Notice period months', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentOriginalTermMonths: {
            id: 'metadataDocumentOriginalTermMonths',
            label: msg({ message: 'Original term months', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentRenewalTermMonths: {
            id: 'metadataDocumentRenewalTermMonths',
            label: msg({ message: 'Renewal term months', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentTotalContractCost: {
            id: 'metadataDocumentTotalContractCost',
            label: msg({ message: 'Total contract cost', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentTotalContractValue: {
            id: 'metadataDocumentTotalContractValue',
            label: msg({ message: 'Total contract value', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentStartDate: {
            id: 'metadataDocumentStartDate',
            label: msg({ message: 'Start date', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentProcessingOfPersonalData: {
            id: 'metadataDocumentProcessingOfPersonalData',
            label: msg({ message: 'Processing of personal data', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentOutsourcing: {
            id: 'metadataDocumentOutsourcing',
            label: msg({ message: 'Outsourcing', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentDocumentCategory: {
            id: 'metadataDocumentDocumentCategory',
            label: msg({ message: 'Document category', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentNoticePeriodDays: {
            id: 'metadataDocumentNoticePeriodDays',
            label: msg({ message: 'Notice period days', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentStatus: {
            id: 'metadataDocumentStatus',
            label: msg({ message: 'Document Status', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentType: {
            id: 'metadataDocumentType',
            label: msg({ message: 'Type', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentDiscount: {
            id: 'metadataDocumentDiscount',
            label: msg({ message: 'Discount', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentFeeAdjustmentClause: {
            id: 'metadataDocumentFeeAdjustmentClause',
            label: msg({ message: 'Fee Adjustment Clause', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentIndemnity: {
            id: 'metadataDocumentIndemnity',
            label: msg({ message: 'Indemnity', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentLiabilityCap: {
            id: 'metadataDocumentLiabilityCap',
            label: msg({ message: 'Liability Cap', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentLiabilityCapScope: {
            id: 'metadataDocumentLiabilityCapScope',
            label: msg({ message: 'Liability Cap Scope', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentLimitationOfLiability: {
            id: 'metadataDocumentLimitationOfLiability',
            label: msg({ message: 'Limitation Of Liability', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentPaymentTermsDaysFromInvoiceDate: {
            id: 'metadataDocumentPaymentTermsDaysFromInvoiceDate',
            label: msg({
                message: 'Payment Terms Days From Invoice Date',
                comment: 'Search table column header',
            }),
            sortable: true,
            active: false,
        },
        metadataDocumentPostTerminationObligations: {
            id: 'metadataDocumentPostTerminationObligations',
            label: msg({
                message: 'Post Termination Obligations',
                comment: 'Search table column header',
            }),
            sortable: true,
            active: false,
        },
        metadataDocumentProbationaryPeriod: {
            id: 'metadataDocumentProbationaryPeriod',
            label: msg({ message: 'Probationary Period', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentProbationaryPeriodExpirationDate: {
            id: 'metadataDocumentProbationaryPeriodExpirationDate',
            label: msg({
                message: 'Probationary Period Expiration Date',
                comment: 'Search table column header',
            }),
            sortable: true,
            active: false,
        },
        metadataDocumentTerminationForCause: {
            id: 'metadataDocumentTerminationForCause',
            label: msg({ message: 'Termination For Cause', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentTerminationForConvenience: {
            id: 'metadataDocumentTerminationForConvenience',
            label: msg({ message: 'Termination For Convenience', comment: 'Search table column header' }),
            sortable: true,
            active: false,
        },
        metadataDocumentParties: {
            id: 'metadataDocumentParties',
            label: msg({ message: 'Parties', comment: 'Search table column header' }),
            sortable: false,
            active: false,
        },
    };
    return Object.assign(Object.assign({}, config[name]), options);
}
