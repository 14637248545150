var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Menu, Button, Alert } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useTeams } from 'app/domains/teams/hooks/useTeams';
import { useGetCompanyUsers, UserAndTeamSelect } from 'app/domains/users';
export function SelectApprovers(props) {
    const { approvers, teamApprovers, buttonAppearance, onAddApprovers, onAddTeamApprovers } = props;
    const [search, setSearch] = useState('');
    const [open, setOpen] = useState(false);
    const selectedApproverUserIds = approvers.map(approver => approver.userId);
    const selectedApproverTeamIds = teamApprovers.map(teamApprover => teamApprover.teamId);
    const { companyUsers, error: errorCompanyUsers } = useGetCompanyUsers({
        search,
        sort: { id: 'name', order: 'asc' },
    });
    const selectableUsers = companyUsers
        .map(companyUser => ({
        id: companyUser.userId,
        name: companyUser.name,
        email: companyUser.email,
    }))
        .filter(user => !selectedApproverUserIds.includes(user.id));
    const { teams, error: errorTeams } = useTeams({
        search,
        sort: { id: 'name', order: 'asc' },
    });
    const selectableTeams = teams
        .map(team => ({
        id: team.id,
        name: team.name,
    }))
        .filter(team => !selectedApproverTeamIds.includes(team.id));
    const error = errorCompanyUsers || errorTeams;
    const handleSearch = (searchString) => {
        setSearch(searchString);
    };
    const handleSubmit = (data) => __awaiter(this, void 0, void 0, function* () {
        const newApprovers = companyUsers
            .filter(companyUser => data.selectedUserIds.includes(companyUser.userId))
            .map(companyUser => {
            var _a, _b;
            return ({
                name: (_a = companyUser.name) !== null && _a !== void 0 ? _a : '',
                email: (_b = companyUser.email) !== null && _b !== void 0 ? _b : '',
                userId: companyUser.userId,
            });
        });
        onAddApprovers(newApprovers);
        const newTeamApprovers = teams
            .filter(team => data.selectedTeamIds.includes(team.id))
            .map(team => {
            var _a;
            return ({
                name: team.name,
                teamId: team.id,
                members: (_a = team.members) === null || _a === void 0 ? void 0 : _a.map(member => {
                    var _a, _b, _c, _d;
                    return ({
                        name: (_b = (_a = member.user) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '',
                        userId: (_d = (_c = member.user) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : '',
                    });
                }),
            });
        });
        onAddTeamApprovers(newTeamApprovers);
        setOpen(false);
    });
    return (<Menu target={<Button type="button" size="small" appearance={buttonAppearance} onClick={() => setOpen(!open)}>
          <Falcon icon="plus"/>
          <Trans comment="Manage template approvers - select approvers field - select approvers button label">
            Add approvers
          </Trans>
        </Button>} onClose={() => setOpen(false)} open={open}>
      {error ? (<Alert appearance="danger" message={t({
                message: 'Something went wrong, please reload the page and try again',
                comment: 'Manage template approvers - select approvers field - failed to load data to display',
            })}/>) : (<UserAndTeamSelect teams={selectableTeams} users={selectableUsers} search={search} onSearch={handleSearch} onSubmit={handleSubmit} submitButtonLabel={t({
                message: 'Add to approvers',
                comment: 'Manage template approvers - select approvers field - confirm button text',
            })}/>)}
    </Menu>);
}
