import { Fragment } from 'react';
import { Trans } from '@lingui/macro';
import { Button, Loader } from '@pocketlaw/tetris';
import { useFormikContext } from 'formik';
export function Actions(props) {
    const { onClose } = props;
    const { isSubmitting } = useFormikContext();
    return (<Fragment>
      <Button type="button" appearance="secondary" disabled={isSubmitting} onClick={onClose}>
        <Trans comment="Label for cancelling edit">Cancel</Trans>
      </Button>
      <Button type="submit" disabled={isSubmitting}>
        {isSubmitting ? <Loader /> : <Trans comment="Label for saving edit">Save</Trans>}
      </Button>
    </Fragment>);
}
