import { useState } from 'react';
import { Box } from '@pocketlaw/tetris';
import { NoApprovers } from './NoApprovers';
import { WithApprovers } from './WithApprovers';
import { useVoluntaryApprovers } from '../../VoluntaryApproversProvider';
import { Content } from '../Content';
import { RequestFooter } from '../RequestFooter';
import { SendForVoluntaryApprovalModal } from '../SendForVoluntaryApprovalModal';
export function RequestVoluntary() {
    const { selectedUsers, selectedApprovers, selectedTeamApprovers, selectedApprovalType } = useVoluntaryApprovers();
    const [renderDialog, setRenderDialog] = useState(false);
    const noApprovers = selectedApprovers.length === 0 && selectedTeamApprovers.length === 0;
    const disableSubmit = noApprovers || !selectedApprovalType;
    const hideDialog = () => setRenderDialog(false);
    const showDialog = () => setRenderDialog(true);
    return (<Box flexDirection="column" height="100%">
      <Content>{noApprovers ? <NoApprovers /> : <WithApprovers />}</Content>
      <RequestFooter approvalType="voluntary" disabled={disableSubmit} onSubmit={showDialog}/>
      {renderDialog && !disableSubmit && (<SendForVoluntaryApprovalModal approvalType={selectedApprovalType} selectedApprovers={selectedUsers} onClose={hideDialog}/>)}
    </Box>);
}
