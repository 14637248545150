import { t, Trans } from '@lingui/macro';
import { ActivityEntry, ActivityEntryText } from 'shared/domains/common-ui';
import { getIcon } from './utils';
export function SignRequestSignatoryRemoved(props) {
    const { actor, timestamp, removedUserName } = props;
    const fallbackName = t({
        message: 'Unknown',
        comment: 'Fallback name when user does not exist',
    });
    const userName = (actor === null || actor === void 0 ? void 0 : actor.name) || fallbackName;
    return (<ActivityEntry appearance="info" icon={getIcon('info')} timestamp={timestamp} text={<ActivityEntryText>
          <Trans comment="Esigning request - Signatory removed as signer from the sign request">
            <b>{userName}</b> removed <b>{removedUserName}</b> from the sign request
          </Trans>
        </ActivityEntryText>}/>);
}
