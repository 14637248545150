import { createContext, useState } from 'react';
import { useEsigningProviderMetadata } from 'app/domains/esigning/hooks/useEsigningProviderMetadata';
export const AttachmentsContext = createContext(null);
export { useAttachments } from '../useAttachments';
export function Provider(props) {
    const { children, attachments, render, renderProps, emptyStateDescription, disableAdd = false, hideAdd = false, showAddingAttachmentLoader = false, onAddAttachments, } = props;
    const { properties: providerProperties } = useEsigningProviderMetadata();
    const { maxNumberOfAttachments } = providerProperties;
    const [uploadingAttachment, setUploadingAttachment] = useState(false);
    const [error, setError] = useState();
    const [fileSizeTooBigWarning, setFileSizeTooBigWarning] = useState(false);
    const exceedsMaxNumberOfAttachments = maxNumberOfAttachments !== null && attachments.length > maxNumberOfAttachments;
    const setAttachmentsError = (newError) => setError(newError);
    const value = {
        error,
        hideAdd: hideAdd || !onAddAttachments,
        disableAdd,
        attachments,
        uploadingAttachment,
        maxNumberOfAttachments,
        exceedsMaxNumberOfAttachments,
        fileSizeTooBigWarning,
        emptyStateDescription,
        showAddingAttachmentLoader,
        setFileSizeTooBigWarning,
        setAttachmentsError,
        setUploadingAttachment,
        addAttachments: onAddAttachments,
        render: render,
        renderProps,
    };
    return <AttachmentsContext.Provider value={value}>{children}</AttachmentsContext.Provider>;
}
