import { createContext, useEffect, useState } from 'react';
import { ErrorMessage } from '../ErrorMessage';
import { LoadingIndicator } from '../LoadingIndicator';
import { useFileBrowser } from '../useFileBrowser';
const PAGE_SIZE = 50;
export const FileBrowserContext = createContext({});
/**
 * Filesystem browser provider. It provides the data and methods needed to display a file browser.
 * For additional customization, you can use the `useFileBrowser` hook and build custom file browsers.
 */
export function FileBrowserProvider(props) {
    const { customLoadingState = false, disabled: disabledAll = false, limit = PAGE_SIZE, selected, controlledLoading = false, children, } = props;
    const [offset, setOffset] = useState(0);
    const { entries, loading, error, onSelect, breadcrumbs, selfId, count, location, folderPermission, fileBrowserSearch, selectFolder, } = useFileBrowser(Object.assign(Object.assign({}, props), { limit,
        offset }));
    useEffect(() => {
        setOffset(0);
    }, [location]);
    const selectedEntryGuard = (entry) => {
        if (!entry) {
            return [];
        }
        return Array.isArray(entry) ? entry : [entry];
    };
    const selectedEntries = selectedEntryGuard(selected);
    const isLoading = controlledLoading || loading;
    const value = {
        breadcrumbs,
        entries,
        onSelect,
        selfId,
        loading: isLoading,
        disabledAll,
        selected: selectedEntries,
        location,
        limit,
        offset,
        setOffset,
        count,
        folderPermission,
        fileBrowserSearch,
        selectFolder,
    };
    if (error) {
        return <ErrorMessage />;
    }
    if (isLoading && !customLoadingState) {
        return <LoadingIndicator />;
    }
    return <FileBrowserContext.Provider value={value}>{children}</FileBrowserContext.Provider>;
}
