import { scrollbar, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${themeGet('spacing.2xl')};
  margin-right: ${themeGet('spacing.sm')};
  padding: ${themeGet('spacing.2xl')};
  overflow: hidden auto;
  ${scrollbar({ trackMargin: 'sm' })};
`;
