import { addMonths, endOfDay, endOfMonth, endOfWeek, endOfYear, isValid } from 'date-fns';
import { TERMS_AGGREGATION_MISSING_VALUE_NAME } from 'app/domains/insights/constants';
import { SearchAggregationDateInterval, } from 'shared/domains/apollo/generated/types';
import { getSelectedField, getSelectedInput } from './getSelectedField';
function exhaustiveGuard(_value) {
    throw new Error(`Encountered unknown field in Insights filter generation: ${JSON.stringify(_value)}`);
}
/**
 * Convert a bucket and aggregation to a search filter
 * Works with both terms and date histogram aggregations
 *
 * @param bucket
 * @param aggregation
 * @returns
 */
export function bucketAggregationToFilter(bucket, aggregation) {
    const { name } = bucket || {};
    const field = getSelectedField(aggregation);
    const isNullFilter = name === TERMS_AGGREGATION_MISSING_VALUE_NAME;
    if (!bucket || !aggregation) {
        return {};
    }
    if (isNullFilter) {
        return { [field]: { isNull: true } };
    }
    const getFilter = (value) => {
        if (!field || !value) {
            return {};
        }
        switch (field) {
            case 'entityId':
            case 'marketCode':
            case 'metadataDocumentAutomaticRenewal':
            case 'metadataDocumentActive':
            case 'metadataDocumentDeviateFromStandard':
            case 'isPlGenerated':
            case 'metadataDocumentDiscount':
            case 'metadataDocumentFeeAdjustmentClause':
            case 'metadataDocumentIndemnity':
            case 'metadataDocumentIsSharedExternally':
            case 'metadataDocumentLiabilityCap':
            case 'metadataDocumentLimitationOfLiability':
            case 'metadataDocumentMaterialAgreement':
            case 'metadataDocumentOutsourcing':
            case 'metadataDocumentPostTerminationObligations':
            case 'metadataDocumentProbationaryPeriod':
            case 'metadataDocumentProcessingOfPersonalData':
            case 'metadataDocumentTerminationForCause':
            case 'metadataDocumentTerminationForConvenience':
            case 'metadataTaskIsPublic':
                return { [field]: { eq: value === 'true' } };
            case 'metadataEntryPath':
            case 'marketLocaleCode':
            case 'metadataTemplateComposerId':
            case 'metadataTemplateMarketLocaleCode':
            case 'metadataUserEmail':
            case 'metadataUserRole':
            case 'name':
            case 'metadataDocumentTotalContractValue':
            case 'metadataDocumentTotalContractCost':
            case 'metadataDocumentRenewalTermMonths':
            case 'metadataDocumentPaymentTermsDaysFromInvoiceDate':
            case 'metadataDocumentOriginalTermMonths':
            case 'metadataDocumentNoticePeriodMonths':
            case 'metadataDocumentNoticePeriodDays':
                return { [field]: { eq: value } };
            case 'metadataDocumentParties':
                return { [field]: { hasSome: [value] } };
            /**
             * For date histogram we also need to look in to the interval.
             * And create a range based on the date and the interval.
             * For example if 2020-01-01 is the bucket and the interval is month, then the range would be:
             * { gte: '2020-01-01', lte: '2020-02-01' }
             *
             * use date-fns endof{interval} to set end of range
             */
            case 'metadataDocumentTerminationDate':
            case 'metadataDocumentStartDate':
            case 'metadataDocumentProbationaryPeriodExpirationDate':
            case 'metadataDocumentNoticeDate':
            case 'metadataDocumentDateSigned':
            case 'metadataTaskDueDate':
            case 'createdAt':
            // eslint-disable-next-line no-fallthrough
            case 'updatedAt': {
                const date = value;
                if (!isValid(new Date(date))) {
                    return {};
                }
                const { interval } = getSelectedInput(aggregation);
                const intervalFunctions = {
                    [SearchAggregationDateInterval.Day]: () => endOfDay(new Date(date)),
                    [SearchAggregationDateInterval.Month]: () => endOfMonth(new Date(date)),
                    [SearchAggregationDateInterval.Week]: () => endOfWeek(new Date(date)),
                    [SearchAggregationDateInterval.Year]: () => endOfYear(new Date(date)),
                    [SearchAggregationDateInterval.Quarter]: () => endOfMonth(addMonths(new Date(date), 3)),
                };
                const endOfInterval = intervalFunctions[interval]();
                return { [field]: { gte: date, lte: endOfInterval.toISOString() } };
            }
            case 'metadataDocumentLiabilityCapScope':
            case 'metadataDocumentGoverningLaw':
            case 'metadataDocumentEsigningStatus':
            case 'metadataDocumentDocumentType':
            case 'entityType':
            case 'createdByUserId':
            case 'metadataDocumentContractOwner':
            case 'categoryId':
            case 'metadataDocumentCurrency':
            case 'metadataDocumentDocumentCategory':
            case 'metadataDocumentStatus':
            case 'metadataDocumentType':
            case 'metadataTaskStatus':
            case 'metadataDocumentCountry':
                return { [field]: { in: [value] } };
            default:
                throw exhaustiveGuard(field);
        }
    };
    return getFilter(name);
}
