import { useState } from 'react';
import { FileBrowserProvider, ROOT } from 'app/domains/filesystem';
import { EntryKind } from 'shared/domains/apollo/generated/types';
import { FolderDialog } from './FolderDialog';
import { PickerProvider } from '../PickerProvider';
export function PickFolderDialog(props) {
    const { initialFolderId = ROOT, onSubmit } = props;
    const [selectedFolder, setSelectedFolder] = useState(undefined);
    const selectable = (entry) => entry.kind === EntryKind.Folder;
    return (<FileBrowserProvider customLoadingState showSignRequestStatus selectable={selectable} parentId={initialFolderId} onSelect={setSelectedFolder} selected={selectedFolder}>
      <PickerProvider initialEntryId={initialFolderId} onSubmit={onSubmit}>
        <FolderDialog {...props}/>
      </PickerProvider>
    </FileBrowserProvider>);
}
