var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { GetApprovalActivitiesDocument } from './query.gql';
import { useApprovalWorkflow } from '../../../ApprovalWorkflowProvider';
export const useApprovalActivity = () => {
    var _a;
    const { documentId } = useApprovalWorkflow();
    const _b = useQuery(GetApprovalActivitiesDocument, {
        variables: { documentId },
        fetchPolicy: 'cache-and-network',
    }), { data } = _b, rest = __rest(_b, ["data"]);
    const activities = (_a = data === null || data === void 0 ? void 0 : data.approvalActivities) !== null && _a !== void 0 ? _a : [];
    return Object.assign(Object.assign({}, rest), { activities });
};
