import { Trans } from '@lingui/macro';
import { ActivityEntry, ActivityEntryText } from 'shared/domains/common-ui';
import { getIcon } from './utils';
export function SignRequestSigned(props) {
    const { timestamp } = props;
    return (<ActivityEntry appearance="success" icon={getIcon('success')} timestamp={timestamp} text={<ActivityEntryText>
          <Trans comment="Esigning activity - The eSigning request is signed by all parties">
            The eSigning request is signed by all parties
          </Trans>
        </ActivityEntryText>}/>);
}
