import { t } from '@lingui/macro';
import { Alert, Box, EmptyState, LoaderOverlay } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { ActivityLog } from 'shared/domains/common-ui';
import { ApprovalRequestApproved } from './ApprovalRequestApproved';
import { ApprovalRequestApproverApproved } from './ApprovalRequestApproverApproved';
import { ApprovalRequestApproverRejected } from './ApprovalRequestApproverRejected';
import { ApprovalRequestCancelled } from './ApprovalRequestCancelled';
import { ApprovalRequestCreated } from './ApprovalRequestCreated';
import { useApprovalActivity } from './useApprovalActivity';
import { Container } from './styled';
export function Activity() {
    const { activities, loading, error } = useApprovalActivity();
    if (loading) {
        return (<Box height="100%" p="2xl">
        <LoaderOverlay label={t({
                comment: 'Approval sidebar - Loading approval activity label',
                message: 'Loading approval activity...',
            })}/>
      </Box>);
    }
    if (error) {
        return (<Box p="2xl">
        <Alert appearance="danger" title={t({
                comment: 'Approval sidebar - Approval activity error - Title',
                message: 'Could not load approval activities',
            })} message={t({
                comment: 'Approval sidebar - Approval activity error - Message',
                message: 'Something went wrong while loading approval activities, please try again',
            })}/>
      </Box>);
    }
    if (activities.length === 0) {
        return (<EmptyState withAvatar icon={<Falcon icon="eye"/>} title={t({
                comment: 'Approval sidebar - Approval activity empty state - Title',
                message: 'No activities',
            })} description={t({
                comment: 'Approval sidebar - Approval activity empty state - Description',
                message: 'There are no activities to show',
            })}/>);
    }
    const getActivityItem = (activity) => {
        // eslint-disable-next-line no-underscore-dangle
        switch (activity.__typename) {
            case 'ApprovalRequestCreatedEsigningActivity':
                return (<ApprovalRequestCreated key={activity.published} user={activity.actor.user} timestamp={activity.published} message={activity.object.message} approvers={activity.object.approvers} approvalType={activity.object.approvalType}/>);
            case 'ApprovalRequestCancelledEsigningActivity':
                // eslint-disable-next-line no-underscore-dangle
                if (activity.actor.__typename === 'UserActivityActor') {
                    return (<ApprovalRequestCancelled key={activity.published} timestamp={activity.published} user={activity.actor.user} message={activity.object.message}/>);
                }
                return <ApprovalRequestCancelled key={activity.published} timestamp={activity.published}/>;
            case 'ApprovalRequestApprovedEsigningActivity':
                return <ApprovalRequestApproved key={activity.published} timestamp={activity.published}/>;
            case 'ApprovalRequestApproverApprovedEsigningActivity':
                return (<ApprovalRequestApproverApproved key={activity.published} user={activity.actor.user} timestamp={activity.published} message={activity.object.message}/>);
            case 'ApprovalRequestApproverRejectedEsigningActivity':
                return (<ApprovalRequestApproverRejected key={activity.published} user={activity.actor.user} timestamp={activity.published} message={activity.object.message}/>);
            default:
                return null;
        }
    };
    return (<Container>
      <ActivityLog>{activities.map(getActivityItem)}</ActivityLog>
    </Container>);
}
