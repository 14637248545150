import { Box, Chip, ChipAction, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useFileBrowserContext } from 'app/domains/filesystem';
export function SelectedEntries() {
    const { selected, onSelect, selectFolder } = useFileBrowserContext();
    const handleDeselect = (entry) => {
        onSelect(entry);
    };
    const handleNavigate = (entry) => {
        if (entry.parentId) {
            selectFolder(entry.parentId);
        }
    };
    if (!selected.length) {
        return null;
    }
    return (<Box flexDirection="column" px="2xl" gap="md" mt="md">
      <Typography $fontWeight="bold" $fontSize="small">
        {selected.length} selected files
      </Typography>
      <Box gap="md" style={{ flexWrap: 'wrap' }}>
        {selected.map(entry => (<Chip key={entry.id} label={entry.name} size="small" appearance="secondary" onClick={() => handleNavigate(entry)} action={<ChipAction type="button" onClick={() => handleDeselect(entry)}>
                <Falcon icon="remove"/>
              </ChipAction>}/>))}
      </Box>
    </Box>);
}
