import { t } from '@lingui/macro';
import { Chip } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useApprovalWorkflow } from 'app/domains/approval';
import { ListButton } from '../ListButton';
export function ApprovalButton() {
    const { approvalWorkflowAvailable, approvalNotRequested, approvalPending, approvalApproved } = useApprovalWorkflow();
    if (!approvalWorkflowAvailable) {
        return null;
    }
    const getChip = () => {
        if (approvalNotRequested) {
            return (<Chip size="small" appearance="info" label={t({
                    comment: 'Document sidebar - Approval button chip - Required',
                    message: 'Required',
                })}/>);
        }
        if (approvalPending) {
            return (<Chip size="small" appearance="info" label={t({
                    comment: 'Document sidebar - Approval button chip - Pending',
                    message: 'Pending',
                })}/>);
        }
        if (approvalApproved) {
            return (<Chip size="small" appearance="success" label={t({
                    comment: 'Document sidebar - Approval button chip - Approved',
                    message: 'Approved',
                })}/>);
        }
        return null;
    };
    return <ListButton settingsKey="approval" icon={<Falcon icon="eye"/>} endElement={getChip()}/>;
}
