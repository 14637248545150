import { Fragment, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Alert, Button } from '@pocketlaw/tetris';
import { useApprovalWorkflow } from 'app/domains/approval';
import { useLastApprovalRequest } from 'app/domains/approval/hooks/useLastApprovalRequest';
import { useEsigning } from 'app/domains/esigning/components/EsigningProvider';
import { HandleApprovalRequestDialog } from './HandleApprovalRequestDialog';
export function ApproverAlert() {
    const { documentId } = useEsigning();
    const { currentUser } = useApprovalWorkflow();
    const { requester } = useLastApprovalRequest(documentId);
    const [dialogType, setDialogType] = useState(null);
    const handleCloseDialog = () => setDialogType(null);
    const showRejectDialog = () => setDialogType('reject');
    const showApproveDialog = () => setDialogType('approve');
    const alertTitle = t({
        comment: 'Approval request banner - approval requested by user',
        message: `Approval requested by ${requester === null || requester === void 0 ? void 0 : requester.name}`,
    });
    const hasApprovedMessage = t({
        comment: 'Approval request banner - approver has approved the document"',
        message: 'You have approved this document.',
    });
    const canApproveMessage = t({
        comment: 'Approval request banner - approver has not approved the document"',
        message: 'By approving they will be able to send the document for e-signing.',
    });
    const alertMessage = currentUser.hasApproved ? hasApprovedMessage : canApproveMessage;
    const status = currentUser.hasApproved ? 'success' : 'info';
    return (<Fragment>
      {dialogType !== null && (<HandleApprovalRequestDialog type={dialogType} onClose={handleCloseDialog}/>)}
      <Alert appearance={status} title={alertTitle} message={alertMessage}>
        {!currentUser.hasApproved && (<Fragment>
            <Button appearance="primary" size="small" onClick={showApproveDialog}>
              <Trans comment="">Approve</Trans>
            </Button>
            <Button appearance="secondary" size="small" onClick={showRejectDialog}>
              <Trans comment="">Reject</Trans>
            </Button>
          </Fragment>)}
      </Alert>
    </Fragment>);
}
